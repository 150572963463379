$favourites-subheader-height: 48px;

.favourites-subheader {
  border-top: 2px solid #f6f6f6;
  display: flex;
  flex-direction: row;
  height: $favourites-subheader-height;
  justify-content: space-between;
  line-height: 43px;

  @include ln-respond-to("sm") {
    border-top: 0;
    justify-content: flex-start;
    margin-top: 11px;
  }

  .nav-item {
    border-bottom: $ln-status-bar-width solid $ln-color-white;
    width: 100%;

    @include ln-font("body-1");

    @include ln-respond-to("sm") {
      border-bottom: 0;
      margin-right: $ln-space-x4;
      width: auto;
    }

    &--active {
      border-bottom-color: $ln-color-orange-dark;
      font-weight: $ln-font-weight-bold;
    }
  }
}
