$logo-width: 7em;
$logo-mobile-width: 4.8em;
$spacing: $ln-space;

.header-minimized-transition {
  transition: transform 0.3s ease, opacity 0.2s ease;
}

.header-minimized {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;
  background-color: $ln-color-white;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  transform: translateY(-100%);
  opacity: 0;

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }

  &__container {
    @extend .ln-o-container;
    @extend .ln-o-container--soft;
    display: flex;
    flex: 1;
    align-items: center;
  }

  .top-right-links {
    margin: 0;
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }
  }

  .book-delivery,
  .book-delivery-new-global-header,
  .book-delivery--minimized {
    margin: 0;
  }

  .mini-trolley {
    margin: 0;
  }
}

.header-background {
  z-index: 2;
  background-color: $ln-color-white;
  width: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
  border-bottom: 1px solid #d8d8d8;

  &.hide {
    margin-top: calc(-#{$header-height});

    @include ln-respond-to("sm") {
      margin-top: calc(-#{$header-height-sm});
    }

    @include ln-respond-to("md") {
      margin-top: calc(-#{$header-height-md});
    }
  }
}

.header-background-minimal {
  background-color: $ln-color-white;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  padding-top: $ln-space-x2;

  &-fixed-subheading {
    padding-top: 46px;
  }

  a {
    color: $ln-color-black;
    font-family: $ln-font-family-brand;
    font-weight: $ln-font-weight-regular;
    text-decoration: none;
  }

  @include ln-respond-to("sm") {
    display: block;
  }

  &.header--tablet {
    @include ln-respond-to("max-md") {
      padding-top: $ln-space-half;
    }

    @include ln-respond-to("max-sm") {
      padding-top: $ln-space;
    }
  }

  &.searchbar-minimized {
    flex-basis: 1;
  }

  .header-nav-mobile {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-nav-mobile-left {
    align-items: center;
    display: flex;
    flex: 1;
    margin-bottom: $ln-space;
  }

  @include ln-respond-to("max-sm") {
    .logo-image {
      display: flex;
    }
  }

  .header-nav-tablet {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .logo-search-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    @include ln-respond-to("min-md") {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }

    span:hover {
      text-decoration: underline;
    }

    span.multi-search {
      span {
        display: inline-block;
        width: max-content;
      }
    }

    .logo-image {
      display: flex;
      flex: 1;
    }
  }

  .multi-search {
    font-size: 16px;
    line-height: 24px;
    color: #f06c00;
    font-weight: 700;
    font-family: $ln-font-family-base;
    align-self: center;
    padding-bottom: 1px;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

    &.non-mobile-version {
      position: absolute;
      top: 48%;
      transform: translateY(-48%);
      right: 52px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .multi-search__legacy {
    font-size: 14px;
    line-height: 24px;
    color: #f06c00;
    text-decoration: underline;
    text-decoration-color: #f06c00;
    font-weight: 700;
    font-family: "Arial";

    &:hover {
      text-decoration: underline;
    }
  }

  &--tablet > .multi-search {
    @include ln-respond-to("max-md") {
      padding-bottom: 3px;
    }
  }

  .logo {
    display: flex;
    height: 19px;
    width: 101px;

    @include ln-respond-to("sm") {
      height: 24px;
      width: 124px;
    }
  }

  .mobile-nav-burger {
    margin: 0 $spacing 0 0;
  }

  .burger-menu-toggle {
    background: $ln-color-white;
    margin: 0 -3px 4px;
    color: $ln-color-dark;
    @media (min-width: 480px) {
      padding-right: $ln-space-x2;
    }
  }

  .top-header {
    display: flex;
    justify-content: space-between;

    @include ln-respond-to("md") {
      margin-bottom: $ln-space-half;
    }
  }

  .top-right-links {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: -6px;
    width: max-content;

    @include ln-respond-to("max-md") {
      margin-top: 3px;
    }

    &--login {
      color: #f06c00;

      @include ln-respond-to("max-sm") {
        font-size: 14px;
        line-height: 24px;
        text-decoration: underline;
        text-decoration-color: #f06c00;
        font-family: "Arial";
      }
    }

    &--myaccount {
      color: #f06c00;

      @include ln-respond-to("max-sm") {
        font-size: 14px;
        line-height: 24px;
        text-decoration: underline;
        text-decoration-color: #f06c00;
        font-family: "Arial";
      }
    }

    &--logout {
      padding-left: 1.25em;
      min-width: 70px;
    }

    &--logout-new-global-header {
      width: max-content;
      color: #f06c00;
      padding-left: 1.25em;
      min-width: 70px;
    }

    > a:hover,
    > a:focus {
      text-decoration: underline;
    }

    &--minimized {
      margin: 0;
      gap: 0.5rem;

      a {
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 0.25rem 0.5rem;
      }

      svg {
        margin-right: 0.25rem;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  .account-link {
    min-width: 102px;

    &__icon {
      margin-right: 0.25em;
      vertical-align: top;
      width: 20px;
    }
  }

  .logout-link {
    &__icon {
      margin-right: 0.25em;
      vertical-align: top;
      width: 20px;
    }
  }

  .top-links {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    margin: 0;
    padding-bottom: 3px;

    a {
      font-size: 14px;
      font-family: $ln-font-family-base;
    }

    a:hover,
    a:focus {
      text-decoration: underline;
    }

    li {
      color: $ln-color-black;
      list-style-type: none;
      margin-right: 1.3em;

      @include ln-respond-to("sm") {
        border-top: none;
        display: inline-block;
      }
    }
  }

  .header-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &__container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
  }

  &--legacy {
    .top-links a.ds-c-link {
      font-size: 1rem !important;
      font-family: $ln-font-family-brand !important;
      font-weight: $ln-font-weight-medium !important;
    }

    .top-header {
      @include ln-respond-to("md") {
        margin-bottom: 1em !important;
      }
    }
  }
}

#search-bar-input {
  &:focus {
    outline: 2px solid #84b3e1 !important;
  }
}

.mini-trolley--minimized {
  position: absolute;
  top: 45px !important;
  right: 0 !important;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:before {
    right: 15px !important;
  }
}
