@mixin selectable-pill {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  color: $dark-grey-hex;
  background-color: #f2f2f2;
  border-radius: 24px;
  padding: 0px 17.64px;
  font-family: Arial;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  &--selected {
    color: #ffffff !important;
    background-color: #7f0442 !important;
    border-color: #7f0442;
  }

  &--selected--light {
    color: #ffffff !important;
    background-color: #f06c00 !important;
    border-color: #f06c00;
  }

  &:hover {
    background-color: #d8d8d8;
    color: $dark-grey-hex;
  }
}

.browse-header-pill {
  @include selectable-pill;
}

.browse-pill-list {
  margin-left: 0;
  @include pill-list-container;

  li {
    flex-shrink: 0;
  }

  &__accordion {
    width: 100%;
  }

  &__scroll {
    overflow-x: scroll;
    flex-wrap: nowrap;
    justify-content: center;
    scroll-behavior: smooth;
    margin: var(--ds-space-spacing-x2) 0;
  }

  &__disable-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0px;
      height: 0px;
    }
  }
}

.browse-pill-scroll-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  ul {
    margin-top: $ln-space-x2 !important;
    margin-left: 0 !important;
    justify-content: unset;
  }
}

.browse-pill-accordion-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  ul {
    @media (max-width: $tabletBreakPoint) {
      margin-top: $ln-space-x2 !important;
      margin-left: 0 !important;
    }
  }
}

@mixin browse-pill-button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background: rgb(255, 255, 255);
}

.browse-pill-button-left {
  @include browse-pill-button;

  left: 0;
  padding-right: 12px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 67%, rgba(254, 255, 255, 0) 100%);
}

.browse-pill-button-right {
  @include browse-pill-button;
  right: 0;
  padding-left: 12px;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 67%, rgba(254, 255, 255, 0) 100%);
}

.browse-pill-show-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--ds-mode-default-base);
  gap: 1rem;
}
