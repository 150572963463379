.recipes-view-download-container {
  @include ln-respond-to($mobile-break-at) {
    min-height: min(850px, calc(100vh - 2 * $ln-space-x2));
  }

  .recipes-view-download-print {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: $ln-space-x4;
    padding: $ln-space-x4;
    min-height: 700px;

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $ln-space-x2;
      max-width: 450px;
      @include ln-font("body-2");
      line-height: 26px;
    }

    &__button-group {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: $ln-space-x2;

      &__button {
        display: flex;
        gap: $ln-space;
        padding: 0 24px;
      }
    }
  }
}
