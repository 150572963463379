.filtersDrawer {
  overscroll-behavior: contain;
}

.filtersDrawer .ds-c-drawer__header--label {
  font-size: 1.5rem;
}

.filtersDrawer {
  &__applied-filters {
    padding: 1rem 0;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 1rem;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }

    &-title {
      font-weight: 700;
      color: #404040;
    }

    &-clear {
      color: #404040;
      text-decoration: none;
      font-weight: 700;
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }

  &__filter-tag {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 9px 10px 9px 16px;
    border-radius: 100px;
    border: 1px solid #737373;

    span {
      font-family: Arial;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
    }

    &-remove {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      padding: 0;

      svg {
        width: 20px;
        height: 20px;
        color: #404040;
      }

      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }
}

.filtersDrawer__skeleton {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.filtersDrawer__skeleton--categories {
  display: grid;
  gap: 0.5rem;
}

.filtersDrawer__skeleton--checkbox {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 32px 1fr;
}

.filtersDrawer--categories {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.filtersDrawer--link {
  color: #404040;
  text-decoration: none;
  padding: 0.75rem 1rem;
  margin-bottom: 0.25rem;
  width: 100%;
  transition: color ease-in-out 0.125s;
}

.filtersDrawer--link.filtersDrawer--link-all {
  display: grid;
  grid-template-columns: 24px 1fr;
  place-items: center start;
  font-weight: 700;
}

.filtersDrawer--link.filtersDrawer--link-all > svg {
  height: 16px;
  width: 16px;
}

.filtersDrawer--link.filtersDrawer--link-active {
  font-weight: 700;
}

.filtersDrawer--accordion .ds-c-accordion-item__summary {
  padding-left: 0;
}

.filtersDrawer--accordion .ds-c-accordion-item__content {
  padding-top: 0;
  padding-bottom: var(--ds-size-spacing-md, 8px);
}

.filtersDrawer--accordion,
.filtersDrawer--section {
  border-bottom: 1px solid #d8d8d8;
}

.filtersDrawer--accordion summary .ds-c-accordion-item__label--text,
.filtersDrawer--accordion summary .ds-c-accordion-item__icon {
  font-family: "Arial";
  font-size: 1rem;
  font-weight: 700;
  color: #404040;
}

.filtersDrawer--accordion summary:hover,
.filtersDrawer--accordion summary:active,
.filtersDrawer--accordion summary:focus {
  background-color: transparent !important;
}

.filtersDrawer--checkbox-spotlight {
  @apply ds-mt-1;
}
