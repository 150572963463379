.fav-no-data {
  padding-top: 30px;
  margin: 0 auto;
  max-width: 570px;
  text-align: center;
  width: 290px;

  &__inner {
    margin: 0 auto;
    width: 290px;
  }

  &__image {
    margin: 43px 0 33px 0;
  }
}

.magnolia {
  &__seo {
    margin-top: 32px;
  }
}
