.pr__logos {
  margin: 0 auto;

  &-info {
    max-width: 700px;
  }

  &__container {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    & .sainsburys-logo {
      width: 102px;
      height: 20px;
    }

    & .nectar-logo {
      margin-left: 1rem;
      width: 48px;
      height: 41px;
    }

    & .select-logo {
      margin-left: 1rem;
      width: 200px;
      height: 15px;
    }

    @include ln-respond-to("sm") {
      & .select-logo {
        margin-top: -0.5rem;
      }
    }

    @media (max-width: 320px) {
      flex-direction: row;
    }

    @media (max-width: 240px) {
      flex-direction: column;
    }
  }
}

.pr-confirm-changes {
  &__content {
    .select-logo {
      max-width: 193px;
      min-width: 121px;
      width: calc(100% - 100px);

      .logo-image {
        max-height: 23px;
      }
    }
  }

  &__logos {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-height: 24px;

    @media (max-width: 240px) {
      align-items: start;
      flex-direction: column;
    }

    & .select-logo {
      width: 130px;
    }
  }

  /* specific overrides for luna default styles */
  .ln-c-button {
    .ln-o-flag {
      justify-content: center;

      .ln-o-flag__body {
        flex-grow: 0;
        white-space: nowrap;
      }
    }
  }
}

.pr-mobile-and-postcode {
  @include ln-respond-to("sm") {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0;
  }

  &__input {
    margin-bottom: 1rem;

    &-mobile {
      margin-right: 0;
      @include ln-respond-to("sm") {
        margin-right: 40px;
        width: 237px;
      }
    }

    &-postcode {
      @include ln-respond-to("sm") {
        width: 204px;
      }
    }
  }
}

.pr-cpm__page {
  width: 343px;
  @include ln-respond-to("sm") {
    width: 720px;
  }
}

.pr-cpm {
  .pr-cpm-option {
    align-items: center;
    border: 1px solid $ln-color-grey;
    display: flex;
    margin: 2px;
    min-width: 200px;
    padding-left: 0;

    &--active {
      border: 3px solid $ln-color-orange;
      margin: 0;
    }

    input:focus {
      & ~ .ln-c-form-option__label {
        box-shadow: none;
      }
    }

    .ln-c-form-option__label {
      height: 80px;
      padding-bottom: 13px;
      padding-left: 75px;
      padding-top: 13px;
      width: 100%;

      &:before {
        left: 26px;
      }

      @include ln-respond-to("sm") {
        height: 100px;
      }
    }

    img,
    svg {
      max-height: 50px;
      min-width: 50px;
      width: 100%;
    }

    .ln-c-form-group {
      margin: 0;
    }

    .pr-cpm-option-wrapper {
      align-items: center;
      border-left: 1px solid $ln-color-grey-light;
      display: flex;
      height: 100%;
      padding: 1rem;
    }

    fieldset {
      width: 100%;
    }
  }

  .ln-o-bare-list__item {
    display: inline-block;
  }

  .pr-cpm-options-text {
    margin-bottom: $ln-space-x4;
  }

  .pr-cpm-options-disclaimer {
    margin: $ln-space-x4 0 0 0;
  }

  &__fieldset {
    legend {
      margin-bottom: calc(#{$ln-space} * 3.5);
    }
  }

  &-text {
    font-size: 18px;
  }
}

.header.pr-header {
  height: 50px;
  position: relative;

  @include ln-respond-to("sm") {
    height: 80px;
  }

  .pr-my-account-link {
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    &__text {
      display: none;

      @include ln-respond-to("sm") {
        display: inline-block;
      }
    }

    &__icon {
      text-decoration: none;
      margin-right: 0.25em;
      vertical-align: top;
      width: 20px;
    }
  }

  .pr-back-link {
    color: $ln-color-grey-dark;
    font-family: Arial, sans-serif;
    left: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .pr-link-text-wrap {
      display: none;
      vertical-align: middle;

      @include ln-respond-to("sm") {
        display: inline;
      }
    }
  }

  .pr-js-logo {
    left: 10%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 182px;

    @include ln-respond-to("max-sm") {
      max-width: 140px;
      left: 15%;
    }

    @include ln-respond-to("max-xs") {
      max-width: 140px;
      width: 96px;
      left: 20%;
    }

    svg {
      display: block;
    }
  }
}

.pr-margin-top {
  margin-top: 50px;

  @include ln-respond-to("sm") {
    margin-top: 80px;
  }
}

.read-only-email {
  &__label {
    font-weight: 700;
    font-family: MaryAnn, "Trebuchet MS", sans-serif;
    font-size: 1.125rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0 1.5rem 0;
    @include ln-respond-to("sm") {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__action {
    width: auto;
    @include ln-respond-to("max-sm") {
      width: 112px;
    }
  }
}
