$missed-meal-deal-background: #fbe6e6;
$missed-meal-deal-colour: #d50000;
$missed-meal-deal-darker-background: rgba(236, 138, 138, 0.5);
$missed-meal-deal-darker-colour: #8d0000;
$missed-nectar-multibuy-darker-background: rgba(198, 154, 253, 0.4);

.trolley-item {
  &__mobile-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-top: 16px;

    &:not(:first-child) {
      border-top: solid 1px $ln-color-grey-v-light;
    }
  }

  &__mobile-item {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    margin-left: 5px;
    width: 226px;
    min-width: 170px;
  }

  &__container {
    padding: 16px 0;
    min-height: 132px;
    &:nth-last-child(1) {
      padding-bottom: 0;
    }
    &:not(:first-child) {
      border-top: solid 1px $ln-color-grey-v-light;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__tile {
    justify-content: normal;
    align-self: normal;

    &-subs {
      margin-top: auto;
    }
  }

  &__tile-checkout {
    justify-content: normal;
    align-self: center;
  }
  &__no-alternative {
    width: 140px;
    text-align: center;
  }
  &__price {
    width: 124px;

    &-wrapper {
      display: flex;

      @include ln-respond-to("max-sm") {
        flex-direction: column;
      }
    }
  }

  &__long-price {
    font-size: 12px;
  }

  &__product-link,
  &__product-title {
    font-weight: 400;
    font-size: 16px;
    font-family: Arial;
    line-height: 24px;
    margin-bottom: 0;
    text-align: left;
    color: $dark-grey-hex;
    background: transparent;

    &:hover {
      color: $ln-color-text-alpha-active;
      transition: color 0.5s ease-out;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
    margin-left: 5px;
    width: 226px;
    min-height: 204px;
    min-width: 170px;

    @include ln-respond-to("xs") {
      width: 100%;
    }

    @include ln-respond-to("sm") {
      flex-direction: row;
      align-items: center;
      min-height: 116px;
      margin-left: 0;

      &:not(:first-child) {
        border-top: solid 1px $ln-color-grey-v-light;
      }
    }

    .ln-c-form-option {
      transform: translateX(-15px);
    }
  }

  &__unmodifiable_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 116px;
    margin-left: 24px;
    width: 100%;
  }

  &__unmodifiable_image {
    align-self: flex-start;
    cursor: initial;
    line-height: 0;
  }

  &__unmodifiable-sticker {
    bottom: 0;
    background-color: $ln-color-black;
    text-align: center;
    color: $ln-color-white;
    font-weight: 500;
    font-size: 14px;
    line-height: initial;
    padding: 4px;
    border-radius: 2px;
    pointer-events: none;
    &:hover,
    &:focus,
    &:active {
      cursor: default;
    }
  }

  &__unmodifiable-image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0 0 90px;
    margin-right: 8px;
  }

  &__product {
    width: 100%;

    @include ln-respond-to("xs") {
      width: 226px;
    }

    &-link {
      cursor: pointer;
      text-decoration: none;
    }

    &-link:hover {
      text-decoration: underline;
    }
  }

  &__image {
    position: relative;
    min-width: 60px;
    width: 90px;
    height: 90px;
    margin-right: 8px;
    align-self: flex-start;
    cursor: pointer;

    &-no-link {
      cursor: initial;
      line-height: 0;
    }

    &-link {
      background: transparent;
      line-height: 0;
    }

    @include ln-respond-to("xs") {
      min-width: 90px;
    }

    @include ln-respond-to("sm") {
      margin: 0;
    }
  }

  &__promotions {
    display: flex;
    margin-bottom: 5px;
    align-items: flex-end;
  }

  &__unit-price {
    display: grid;
    font-weight: 400;

    @include ln-respond-to("max-sm") {
      line-height: 1.5rem;
    }
  }

  &__sub-price-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__original-price {
    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include ln-respond-to("max-sm") {
        flex-direction: row;
        column-gap: $ln-space;
        align-items: baseline;
      }
    }
  }

  &__nectar-price {
    &-measure {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include ln-respond-to("max-sm") {
        flex-direction: row;
        column-gap: $ln-space;
        align-items: center;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: end;

      @include ln-respond-to("max-sm") {
        align-items: flex-start;
      }
    }

    &-before {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      line-height: 1;
      font-size: 14px;
      font-weight: normal;
      color: #4c4c4c;
      column-gap: calc(#{$ln-space} * 0.5);
      white-space: nowrap;

      @include ln-respond-to("max-sm") {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }

      b {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &-after {
      color: #8223fa;
    }

    &-banner {
      align-self: flex-start;
      max-width: 200px;
      padding: 0 10px;
      height: 24px;
      background-color: #8223fa;
      border-radius: 2px;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 24px;
      color: white;
      text-align: center;
      font-weight: bold;
      font-family: $ln-font-family-base;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__offer {
    &-container {
      display: flex;
      flex-direction: column;

      @include ln-respond-to("sm") {
        flex-direction: row;
        align-items: center;
      }
    }

    &-before {
      text-decoration: line-through;
      line-height: 1;
      font-size: 16px;

      @include ln-respond-to("sm") {
        padding-right: calc(#{$ln-space} * 0.5);
      }
    }

    &-after {
      color: #d50000;
      font-size: 1.125rem;
    }

    &-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include ln-respond-to("max-sm") {
        align-items: flex-end;
        flex-direction: row;
        column-gap: $ln-space;
      }
    }

    &-banner {
      width: 50px;
      height: 24px;
      background-color: #d50000;
      border-radius: 2px;
      margin-bottom: 8px;

      font-size: 14px;
      line-height: 24px;
      color: white;
      text-align: center;
      font-weight: bold;
      font-family: $ln-font-family-base;
    }
  }

  &__dietary-warning {
    display: flex;
    justify-content: flex-start;
    align-self: normal;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: justify;
  }

  &__dietary-warning div:nth-child(1) {
    padding-bottom: 10px;
  }

  &__catchweight-dropdown {
    margin-bottom: calc(#{$ln-space} * 2);
    width: 170px;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    width: 170px;
    height: 48px;
  }
  &__offer-expired {
    @include ln-respond-to("xs") {
      width: 226px;
    }

    &--nectar {
      color: #6c18cb;
    }
  }

  &__nectar-offer {
    &-points {
      margin-top: -4px;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 14px;
      color: #8223fa;
    }

    &-banner {
      width: 106px;
      height: 24px;
      background-color: #f3e9ff;
      border-radius: 2px;
      margin-bottom: 8px;

      font-size: 14px;
      line-height: 24px;
      color: #8223fa;
      text-align: center;
      font-weight: bold;
      font-family: $ln-font-family-base;
    }
  }

  &__occasions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0px;

    &-servings {
      font-size: 14px;
      font-weight: bold;
      color: #404040;
    }

    &__availability {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #2e7d32;
      gap: 8px;

      &-expiring {
        color: #1669ba;
      }
    }

    &__banner {
      width: 100%;
      background-color: #3f384c;
      font-size: 14px;
      line-height: 24px;
      color: white;
      text-align: center;
      font-weight: bold;
      font-family: $ln-font-family-base;
    }
  }

  &__quantity {
    flex-direction: row;
  }

  &__uom {
    &__radio {
      margin-bottom: 6px;
      width: 168px;
    }

    /* start: override due to unable to use listType inline */
    &__radio ul {
      display: flex;
      margin-top: -10px;
    }

    &__radio li {
      &:not(:last-child) {
        padding-right: 32px;
      }
    }

    &__radio label {
      padding: 0.5rem 0;
    }

    &__radio label:after {
      left: 5px !important;
    }
  }

  &__substitution-icon {
    margin-left: -3px;
  }

  &__alternate-added {
    background: none;
    width: 146px;
    cursor: text;
  }

  &__error {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: $ln-color-red;
  }
}

a.missed-multibuy,
button.missed-multibuy {
  @include ln-font("caption");

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $ln-space-half $ln-space $ln-space-half $ln-space-x2;
  font-size: 14px;
  text-decoration: none;
  border-radius: 2px;

  color: $missed-meal-deal-colour;
  background-color: $missed-meal-deal-background;
  transition-property: background-color, color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;

  &__arrow {
    margin-left: auto;
  }

  &:hover,
  &:active,
  &:focus {
    color: $missed-meal-deal-darker-colour;
    text-decoration: underline;
    background-color: $missed-meal-deal-darker-background;
  }

  &--nectar {
    color: $nectar-colour;
    background-color: $nectar-bonus-color-background;

    &:hover,
    &:active,
    &:focus {
      color: $nectar-colour-darker;
      text-decoration: underline;
      background-color: $missed-nectar-multibuy-darker-background;
    }
  }
}
