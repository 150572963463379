.fm {
  padding: 0px 5px 20px 5px;
  display: flex;
  align-items: center;
  line-height: 1.6;

  &__container {
    display: flex;

    &-title {
      margin-right: 8px;
      padding: 3px 15px 3px 0;
      font-size: 18px;
      font-weight: bold;
      font-family: MaryAnn, "Trebuchet MS", sans-serif;
    }
    &--link {
      display: flex;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
      input:focus {
        & ~ .ln-c-form-option__label {
          box-shadow: none;
        }
      }
      &-underline {
        text-decoration: underline;
        cursor: pointer;
      }

      &-terms {
        text-decoration: none;
        cursor: pointer;
        border-left: 2px solid #e1e1e1;
        padding-left: 10px;
        margin-left: 2px;
      }

      fieldset {
        margin: 0px;
      }
    }
  }

  @include ln-respond-to("max-xs") {
    margin-top: 20px;
    width: 100%;

    &__container {
      &-title {
        width: 55%;
        padding: 3px 5px 3px 0;
        font-size: 18px;
      }
      &--link {
        width: 45%;
      }
    }
  }
}
