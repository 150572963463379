.pd {
  &__additional {
    &__container {
      display: flex;
      flex-wrap: wrap;
      max-width: 300px;
      margin-top: 24px;

      @include ln-respond-to("max-sm") {
        margin: 24px auto 8px;
      }
    }

    &__image {
      width: 50px;
      height: 50px;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      object-fit: contain;
      border: 1px #8e8e8e solid;

      &__active {
        border: 1px solid $ln-color-orange;
      }

      &:last-child {
        margin-right: 0px;
      }

      &__play {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #8e8e8e;
        height: 50px;
        padding: 14px;
      }
    }
  }

  &__image {
    height: 300px;
    max-width: 300px;
    display: block;
    margin: 24px auto 0px;
    cursor: pointer;
    object-fit: contain;

    &__nocursor {
      cursor: default;
    }

    @include ln-respond-to("min-xs") {
      margin: 26px auto 0px;
    }
  }
}
