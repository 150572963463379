.product-details-page {
  padding-top: 16px;

  .alert-banner {
    $bottom-margin: 24px;

    padding: 16px;
    font-size: 14px;
    text-align: center;
    color: $dark-grey-hex;
    background-color: $banner-blue-hex;
    @include wider-margin;
    margin-bottom: $bottom-margin;

    @include ln-respond-to("min-md") {
      margin: 0px 0px $bottom-margin 0px;
    }

    @include ln-respond-to("xs") {
      padding: 19px 16px;
    }

    &:first-child {
      margin-top: 8px;
    }

    .expiry-date {
      font-weight: 700;
    }
  }
}
