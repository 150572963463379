.pageHeader {
  display: flex;
  align-items: center;
  flex-direction: column;
  display: inline-flex;
  width: 100%;
  text-align: center;

  &__title {
    color: $ln-color-grey-dark;
    font-size: 40px;
    margin: 40px 0px 25px 0px;
    @include ln-respond-to("max-sm") {
      font-size: 32px;
      margin: 24px 24px 12px 24px;
    }
  }

  &__paragraph {
    max-width: 760px;
  }

  &__paragraph,
  &__paragraph * {
    font-size: 16px;
    @include ln-respond-to("max-sm") {
      font-size: 14px;
    }
  }
}
