.favourite-error {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 32px;
    background: #ffffff;
  }

  &__heart {
    margin-top: 24px;
    margin-bottom: 0;
    flex: none;
    flex-grow: 0;
    order: 0;
  }

  &__header {
    margin-bottom: 16px;
    flex: none;
    flex-grow: 0;
    order: 1;
  }

  &__text {
    text-align: center;
    margin-bottom: 16px;
    flex: none;
    flex-grow: 0;
    order: 2;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  &__btns {
    order: 3;
    margin: 0 0 16px 0;
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include ln-respond-to("sm") {
      flex-wrap: nowrap;
      margin: 0px;
      width: 100%;
    }

    @include ln-respond-to("md") {
      flex-wrap: nowrap;
      margin: 0px;
      width: 100%;
    }

    &__item {
      flex: 0 0 auto;
      width: 280px;

      @include ln-respond-to("sm") {
        width: auto;
        min-width: 200px;
      }

      @include ln-respond-to("md") {
        width: auto;
        min-width: 200px;
      }
    }

    &__item:not(:last-child) {
      margin: 0 0 16px 0;

      @include ln-respond-to("sm") {
        margin: 0 16px 0 0;
      }

      @include ln-respond-to("md") {
        margin: 0 16px 0 0;
      }
    }
  }

  &__btn {
    margin-bottom: 24px;
    flex: none;
    flex-grow: 0;
    order: 3;
  }
}
