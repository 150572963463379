.book-slot-grid {
  &__slot {
    align-items: center;
    background: $ln-color-white;
    border: solid 2px $ln-color-white;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    height: 54px;
    margin: 0 2%;
    margin-bottom: 5px;
    padding: 16px 8px;
    position: relative;
    width: 96%;
    justify-content: space-between;
    color: $ln-color-dark;

    @include ln-respond-to("sm") {
      justify-content: center;
      text-align: center;
    }

    @include ln-respond-to("md") {
      padding: 16px 4px;
    }

    @include ln-respond-to("lg") {
      padding: 16px 8px;
    }

    &:disabled {
      opacity: 1;
    }

    &:hover {
      border-color: $ln-color-orange;
    }

    @media (hover: hover) {
      &:hover &-unavailable {
        text-decoration: underline;
      }
    }

    &-booked {
      grid-column: 2;
      font-size: 14px;
    }

    &-unavailable {
      grid-column: 2;
      cursor: pointer;
    }

    &-price,
    &-locked,
    &-fully-booked {
      grid-column: 2;
    }

    &-hour {
      font-weight: bold;
      margin-right: 12px;
      min-width: 70px;
    }

    &-times {
      display: flex;
      position: relative;
    }

    &-cell {
      align-items: center;
      display: grid;
      grid-template-columns: repeat(2, minmax(40px, auto));

      @include ln-respond-to("sm") {
        grid-template-columns: 1fr auto 1fr;
        justify-content: center;
        width: 100%;
      }
    }

    &-pending {
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &-placeholder {
      height: 12px;
      width: 24px;
      background-color: $ln-color-black;
      animation: pulse 1.5s infinite ease-in-out;

      @keyframes pulse {
        0% {
          opacity: 0.2;
        }

        25% {
          opacity: 0.35;
        }

        100% {
          opacity: 0.2;
        }
      }
    }

    &-warning {
      grid-column: 1;
      grid-row: 1;
      justify-self: start;
      height: 24px;

      @include ln-respond-to("sm") {
        grid-column: 3;
        justify-self: end;
      }
    }

    &-van {
      grid-column: 1;
      grid-row: 1;
      color: $ln-color-green;
      justify-self: start;
      height: 24px;
    }

    .van-booked {
      grid-column: 1;
      color: $ln-color-white;
      justify-self: start;
      height: 24px;
    }

    &-full,
    &-locked {
      background: transparent;
      border: solid 1px #d7d7d7;
      color: #4c4c4c;

      &:hover,
      &:focus {
        border: solid 1px #d7d7d7;
      }
    }

    &-existing-order-label {
      grid-column: 2;
      font-size: 14px;
      margin-bottom: 0;
    }

    &-existing-order-amend-link {
      grid-column: 2;
      font-size: 14px;
      margin-bottom: 0;
      text-decoration: underline;
    }

    &-existing-order-content {
      grid-column: 2;
      text-align: right;

      a {
        color: $ln-color-white;
        text-decoration: underline;
      }

      @include ln-respond-to("sm") {
        text-align: center;
      }
    }

    &--booked {
      grid-column: 2;
      background-color: $ln-color-orange;
      border-color: $ln-color-orange;
      color: #fff;
      font-weight: 800;
    }

    &--with-order {
      background-color: $ln-color-plum;
      border-color: $ln-color-plum;
      color: #fff;
      font-weight: 800;

      &:hover,
      &:focus {
        border-color: $ln-color-plum;
      }

      @include ln-respond-to("sm") {
        padding: 16px 4px;
      }

      @include ln-respond-to("lg") {
        padding: 16px 8px;
      }
    }

    &--not-selectable {
      pointer-events: none;
    }
  }

  &__hour {
    height: 54px;
    margin: 5px 0;
    padding: 16px 0;
  }
}
