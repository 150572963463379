.page-pill-header {
  display: flex;
  flex-direction: column;
  row-gap: $ln-space-x3;

  margin-top: 7px;

  @include ln-respond-to("sm") {
    margin-top: 32px;
  }

  @include ln-respond-to("md") {
    margin-top: 0px;
  }

  & > &__title,
  & > &__description,
  & > &__pills-list {
    margin: 0;
    padding: 0;
  }
}
