.basicModal {
  &-favourites-confirm {
    text-align: center;
    width: 300px;
    height: fit-content;
  }

  &__cancel-button {
    display: block;
    margin-top: 8px;
  }

  .ln-c-modal__body {
    text-align: center;
    width: 300px;
  }
}

.gridModal {
  &__title {
    width: calc(100% - $ln-space-x6);
    text-align: left;
  }

  button.ln-c-button {
    top: 1rem;
  }

  & > div {
    display: flex;
    justify-content: center;

    & > div {
      width: 100%;
      background-color: #f2f2f2;
      text-align: center;

      @include ln-respond-to("md") {
        min-width: 550px;
        max-width: fit-content;
      }
    }
  }
}

.basketMultiErrorModal {
  &__title {
    margin-bottom: 30px;
  }

  &__unorderedList {
    list-style: none;
    margin: 0px;
  }

  .ln-c-modal__body {
    text-align: center;
    width: 288px;

    @include ln-respond-to("sm") {
      width: 380px;
    }
  }
}

.basketMultiErrorMessage {
  &__productName {
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 8px;
    padding: 0 6px;
  }

  &__description,
  &__secondary-button {
    margin-bottom: 8px;
    padding: 0 6px;
  }

  &__primary-button {
    margin-top: 16px;
  }

  &__divider {
    border-bottom: 1px solid #9b9b9b;
    padding: 1px;
    margin-top: 16px;
  }
}

.modal-update-quantity {
  max-width: 310px;
  height: fit-content;

  .modal-update-quantity__body {
    max-width: 310px;
    min-height: 200px;
    text-align: center;
  }

  &__title {
    display: inline-block;
    font-family: $ln-font-family-brand;
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 25px;
  }

  &__input {
    float: left;
    width: 52%;
  }

  &__submit {
    margin-left: 4%;
    width: 44%;
  }

  &__loading-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 152px;
  }

  &__error {
    margin-bottom: 10px;
    padding: 0;
    position: relative;
    text-align: left;

    &:before {
      left: 0;
      position: absolute;
      top: 0;
    }

    &-text {
      display: inline-block;
      padding-left: 30px;
    }
  }
}

.favourites-checkbox {
  text-align: left;
}

.modal-favourites {
  .ln-c-modal__body {
    text-align: center;
    width: 300px;
  }

  &__cancel-button {
    display: block;
    margin-top: 8px;
  }

  &__error-code {
    @include ln-font("caption");
  }
}

.modal-discard-changes {
  z-index: $overlay-z-index;

  .ln-c-modal__body {
    width: 100%;
    max-width: 510px;
  }

  &__title {
    font-size: 32px;
    max-width: 255px;

    @include ln-respond-to("xs") {
      max-width: 408px;
    }
  }

  &__description {
    display: block;
    text-align: left;
  }

  &__subtitle {
    display: block;
    text-align: left;
    font-family: $ln-font-family-brand;
    font-weight: $ln-font-weight-bold;
    font-size: 18px;
  }

  &__cta-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include ln-respond-to("xs") {
      flex-direction: row;
      margin-right: -$ln-space-x2;
    }

    .ln-c-button {
      margin-top: $ln-space-x2;
      width: 100%;

      @include ln-respond-to("xs") {
        width: 14em;
        margin-right: $ln-space-x2;
      }
    }
  }

  &__delete-icon {
    margin-bottom: 4px;
  }

  &__discard-button-text {
    display: inline-block;
    margin-top: 4px;
  }

  &__primary-button {
    @extend %primary-button;
  }

  &__secondary-button {
    @extend %secondary-button;
  }
}

%primary-button {
  order: 1;

  @include ln-respond-to("xs") {
    order: 2;
  }
}

%secondary-button {
  order: 2;

  @include ln-respond-to("xs") {
    order: 1;
  }
}
