.cnc-reserve-modal {
  padding: 0;
  justify-content: flex-end;
  z-index: 6;

  &__title {
    font-family: Arial, serif;
    font-size: 18px;
    font-weight: 700;
    margin: 16px 0;
    white-space: pre;
    width: 235px;
  }

  .ln-c-modal {
    &__body-wrapper {
      max-width: unset;
      width: 100%;
    }
    &__body {
      margin: 0;
      max-width: unset;
      padding: 0;
      @include ln-respond-to("max-sm") {
        padding: $ln-space-x2;
      }
      text-align: center;
      width: 100%;
    }

    &__scroll-area {
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
      @include ln-respond-to("sm") {
        padding-bottom: $ln-space-x2;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__price {
    font-size: 18px;
    line-height: 26px;
    margin: $ln-space;
  }

  &__error {
    color: #d83e34;
  }

  &__primary-button,
  &__secondary-button {
    width: 288px;
    margin-top: $ln-space-x2;

    @include ln-respond-to("sm") {
      width: 448px;
    }
    @include ln-respond-to("max-sm") {
      width: 100%;
    }
  }

  &__message {
    max-width: 606px;
    margin: $ln-space;
  }
}
