.mealdealbuilder {
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "bundlebox"
    "tileList";

  @include ln-respond-to("min-md") {
    grid-template-areas: "tileList bundlebox";
    grid-template-columns: 2fr 1fr;
  }

  &__header {
    background-color: $ln-color-white;
    margin-bottom: 2rem;
    margin-left: 1.5rem;
  }

  &__pillscontainer {
    background-color: $ln-color-white;
    padding: 1rem;
    margin-bottom: 2rem;
    margin-left: 1.5rem;

    .pills {
      margin: 0;
    }
  }

  &__placeholder {
    background-color: #d8d8d8;
    padding: 15px;
  }

  &__tileList {
    grid-area: tileList;
  }
}
