.product-controls {
  background-color: #f2f2f2;
  @include ln-respond-to("lg") {
    padding-bottom: 10px;
  }

  &__wrapper {
    align-items: flex-start;
    justify-content: flex-end;

    &--left {
      justify-content: flex-end;
    }
  }

  &__sort-dropdown-findability {
    width: 100%;

    @include ln-respond-to("lg") {
      max-width: 200px;
    }
  }

  &__sort-dropdown {
    width: 100%;

    @include ln-respond-to("md") {
      max-width: 200px;
    }
  }

  .ln-c-form-group {
    margin-bottom: 0;
  }

  select.ln-c-select {
    background-color: #f2f2f2;
    padding-top: 0;
    vertical-align: middle;
    line-height: 48px;
  }

  button.ln-c-select {
    padding-top: 0;
    vertical-align: middle;
    line-height: 48px;
  }
}

.page-size-options {
  display: none;
  margin-left: 25px;
  margin-top: 36px;

  .ln-o-grid & {
    width: auto;
  }

  @include ln-respond-to("md") {
    display: inline-block;
  }
}
