.cnc {
  margin-top: -2rem;

  @include ln-respond-to("sm") {
    margin-top: 42px;
  }

  @include ln-respond-to("md") {
    margin-top: 20px;
  }

  &__subtitle {
    font-size: 1.2rem;
    font-weight: 800;
    font-family: MaryAnn, "Trebuchet MS", sans-serif;

    @include ln-respond-to("max-sm") {
      font-size: 18px;
    }

    &-prefix {
      @include ln-respond-to("max-sm") {
        font-size: 18px;
      }
    }

    &-prefix-hidden {
      visibility: hidden;
    }
  }

  &__title-container {
    padding-bottom: calc(#{$ln-space} * 2);
    padding-top: 2rem;
  }

  &__controls {
    display: flex;
    justify-content: space-between;

    .change-store {
      color: $dark-grey-hex;
      text-decoration: underline;
      font-weight: 800;
      font-size: 1.1rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 2px;

      @include ln-respond-to("max-xs") {
        min-height: 0;
      }

      .ln-c-button__icon {
        margin-right: 6px;
      }

      &:not(:disabled):not(.is-disabled):hover {
        background-color: transparent;
        color: $ln-color-black;
      }
      &:not(:disabled):not(.is-disabled):focus {
        background-color: transparent;
        color: $ln-color-black;
      }
    }
  }

  &__grid-container {
    @include ln-respond-to("max-sm") {
      padding-bottom: 0;
    }
  }
}

.store-list-modal {
  z-index: $overlay-z-index;

  &__header {
    position: sticky;
    top: 0;
    z-index: $menu-z-index;
    background: #f6f6f6;
    overflow: hidden;
  }

  &__heading {
    text-align: center;
    margin-top: 22px;
    margin-bottom: 12px;
    font-size: 24px;
  }

  &__close-icon {
    color: $ln-color-black;
    position: absolute;
    right: 0;
    top: 15px;
    display: flex;

    &:not(:disabled):not(.is-disabled):hover {
      background-color: transparent;
      color: $ln-color-black;
    }
    &:not(:disabled):not(.is-disabled):focus {
      background-color: transparent;
      color: $ln-color-black;
    }
    .ln-c-button__icon,
    .ln-c-icon.ln-c-button__icon {
      padding: 12px;
      height: 48px;
      width: 48px;
    }
  }

  .ln-c-modal__body {
    background: #f6f6f6;
    padding-bottom: 0;
    padding-top: 0;
    max-width: 100%;
    overflow-x: hidden;
  }

  .ln-c-button-group {
    margin-bottom: 0;
  }

  .view-slots-button {
    background: $ln-color-white;
    padding-bottom: 1rem;
    position: sticky;
    bottom: 0;
    margin: 1rem -1rem -1rem -1rem;

    button {
      min-width: 280px;
      margin-left: 50%;
      -webkit-transform: translate(-50%, 0%);
      transform: translate(-50%, 0%);
    }
  }
}

.cnc-next-delivery {
  &__banner {
    background-color: $ln-color-white;
    padding: $ln-space-x2;
    margin: $ln-space-x3 0.25%;
    margin-bottom: 0;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include ln-respond-to("sm") {
      flex-direction: row;
      margin-bottom: $ln-space-x3;
    }
  }

  &__reservation-notification {
    background-color: #e4f2ff;
  }

  &__header {
    font-size: 18px;
    margin-bottom: 12px;
  }

  &__icon-text {
    .ln-c-icon {
      position: relative;
      bottom: 2px;
      height: 24px;
      width: 24px;
      margin-right: $ln-space;
    }

    &--info-icon {
      .ln-c-icon {
        height: 22px;
        width: 22px;
        left: 1px;
      }
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    margin-top: $ln-space-x2;

    .ln-c-button {
      width: 100%;
    }

    @include ln-respond-to("sm") {
      margin-top: 0;

      .ln-c-button {
        width: auto;
      }
    }
  }
}
