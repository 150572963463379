.recipe-carousel {
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: 1em;
  padding-bottom: 1em;

  .recipe-tile,
  .recipe-tile-add {
    display: inline-block;
    white-space: normal;
    vertical-align: bottom;
    margin-right: 1em;
    width: 344px;
    height: 381px;

    .recipe-pills {
      position: absolute;
      bottom: 1em;
    }
  }

  .recipe-tile-add {
    background-color: $ln-color-white;
    top: 0;

    .ln-c-icon {
      color: $ln-color-orange;
      width: 74px;
      height: 77px;
    }
  }
}

@media (max-width: 920px) {
  .recipe-carousel {
    .recipe-tile,
    .recipe-tile-add {
      min-width: 35%;
      max-height: 22em;
    }
  }
}

@media (max-width: 580px) {
  .recipe-carousel {
    .recipe-tile,
    .recipe-tile-add {
      min-width: 65%;
    }
  }
}
