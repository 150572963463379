.recipe-tile {
  position: relative;
  display: flex;
  flex-direction: column;

  &__title-block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-grow: 1;
    min-height: 180px;
  }

  &__count {
    font-size: 1.125rem;
    margin: $ln-space-x2 auto 0;
  }

  .recipe-buttons {
    margin-top: 1em;
  }

  &__title {
    background: none;
    text-align: left;
    color: $ln-color-black;
    min-height: 48px;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }

  .bv_sub_container .bv_text {
    top: -4px !important;
    position: relative !important;
  }

  &__icons-container {
    position: absolute;
    top: $ln-space;
    right: $ln-space;
    display: flex;
    gap: $ln-space;

    .favourite-button,
    .delete-button,
    .shuffle-button {
      z-index: 2;
      display: grid;
      place-items: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background-color: $ln-color-white;
      color: $ln-color-plum;
    }
    .favourite-button {
      width: 48px;
      height: 48px;
    }

    .favourite-button-icon {
      width: 24px;
      height: 24px;
    }
  }
}
