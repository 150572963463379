.add-payment-card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-payment-card__iframe {
  border: 0;

  &-background {
    width: 85%;
    background-color: #fff;
  }

  &-wrapper {
    display: flex;
    max-width: 538px;
    margin: 0 auto;
    width: 100%;
  }

  &-loading {
    height: 680px;
  }
}
