.branded-page {
  &__magnolia {
    &.rmv-btm-padding {
      padding-bottom: 0;
    }
    &.clickable {
      cursor: pointer;
    }
  }
}
