/*
  Styling guidance:
  Styling wise, the component is divided into three parts: the product image, the CTA and the roundel.
  This stylesheet deals with the positioning and sizing of these three parts across device widths.
  In general, we observe below rules:
    1. When on Mobile and Desktop, apply the vertical layout which:
          A. stacks the image on top of the CTA
          B. displays the "landscape" image
          C. positions the roundel absolutely. visually it lays upon the image.
    2. When on Tablet, apply the horizontal layout which:
          A. sits the image and the CTA beside each other, with the image on the left
          B. displays the "portrait" image
          C. positions the roundel statically. visually it sits above the CTA.
  Besides from above, we aim to make Bannerx consistent with the e-commerce spots, namely espots.
  As the espots' style varies based on where it is placed ( those placed amongst the product tiles are referred to as IN-GRID, and those at the bottom of the page are referred to as BELOW-GRID or TRIO when they appear in trio)
  , we apply different breakpoints to BannerX according to its placement.
 */

// respond-to-and-class ensures that the intended styles is passed also to the supplier-facing Previewer. The Previewer has no concepts of Channels such as Mobile, Tablet,and Desktop, and knows only Sizes such as small, medium and large. see the Previewer at src/views/BannerPreview/bannerx.tsx
@mixin respond-to-and-class($name, $previewName) {
  @include ln-respond-to($name) {
    &:not(&--preview) {
      @content;
    }
  }
  .citrus-preview-bp-#{$previewName} & {
    @content;
  }
}

@mixin enable-size-in-flex {
  min-height: 50%;
  flex-grow: 2;
}

// disable-size-in-flex effectively makes the image invisible
@mixin disable-size-in-flex {
  min-height: 0;
  flex-grow: 0;
}

@mixin portrait-img-min-width {
  .CitrusBannerSolo-DoubleImage--portrait {
    min-width: 50%;
  }
}

@mixin statically-positioned-roundel {
  .CitrusBannerSolo-roundel {
    position: static;
    margin: 24px auto 0 auto;
  }
}

@mixin absolutely-positioned-roundel {
  .CitrusBannerSolo-roundel {
    position: absolute;

    // Consistent with Magnolia ads
    top: 24px;
    left: 10%;

    // fix scrollbars without giving parent overflow: hidden
    bottom: 50%;
    right: 0;

    background-repeat: no-repeat;
    background-position: top left;

    width: 85px;
    height: 85px;
    border-radius: 50%;
    visibility: hidden;

    // Text inside
    span {
      font-size: 22px;
      color: white;
      font-weight: 700;
      line-height: 85px;
    }

    &--bundle span {
      font-size: 22px;
      color: white;
      font-weight: 700;
      line-height: 85px;
    }

    &--offer {
      background-color: #d61f26;
      visibility: visible;
    }

    &--bundle {
      background-color: #f06c00;
      visibility: visible;
    }

    &--new {
      background-color: #1976d2;
      visibility: visible;

      span {
        font-size: 40px;
      }
    }

    &--nectar {
      visibility: visible;
      height: 85px; // set nectar logo height to match the other roundels
    }
  }
}
// Medium size is a bit different and cannot
// Be reused between presentation and preview
@mixin medium-size() {
  flex-direction: row;

  .CitrusBannerSolo-cta {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .CitrusBannerSolo-copy--body {
      font-size: 18px;
    }
  }
  .CitrusBannerSolo-image {
    min-width: 50%;
    // because of Citrus image size requirements works
    // but normally we'd prefer "contain"
    background-size: cover;
  }

  .CitrusBannerSolo-DoubleImage--portrait {
    @include enable-size-in-flex();
  }

  .CitrusBannerSolo-DoubleImage--landscape {
    @include disable-size-in-flex();
  }

  .CitrusBannerSolo-button {
    width: auto;
    max-width: 200px;
  }
}

.CitrusBannerSolo {
  height: 100%;
  // Mobile first

  font-family: $ln-font-family-brand;
  font-weight: $ln-font-weight-medium;

  width: 100%;
  display: flex;
  flex-direction: column;

  // allow some children to be absolutely positioned
  position: relative;

  // look clickable
  cursor: pointer;

  min-height: 379px;

  &-image,
  &-DoubleImage--portrait,
  &-DoubleImage--landscape {
    @include enable-size-in-flex();

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-DoubleImage--portrait {
    @include disable-size-in-flex();
  }

  &-cta {
    width: 100%;
    text-align: center;
    padding: 0 24px 16px 24px;

    .CitrusBannerSolo-copy {
      margin-bottom: 16px;
      span {
        display: block;
      }
    }

    .CitrusBannerSolo-copy--title,
    .CitrusBannerSolo-copy--body {
      font-size: 26px;
      font-weight: $ln-font-weight-extra-bold;
    }

    .CitrusBannerSolo-copy--title {
      line-height: 32px;
    }

    .CitrusBannerSolo-copy--body {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 16px;
      line-height: $ln-space-x3;
      font-weight: $ln-font-weight-bold;
    }

    .CitrusBannerSolo-copy--footer {
      font-weight: $ln-font-weight-regular;
    }

    @include absolutely-positioned-roundel;
    .CitrusBannerSolo-button {
      max-width: 140px;
      margin: 0 auto;
      white-space: nowrap;
      & a {
        margin: 0 8px;
      }
    }
  }

  // Overrides for higher breakpoints

  // Above 479px (xs)
  @include respond-to-and-class("xs", "small") {
    .CitrusBannerSolo-DoubleImage--portrait {
      @include disable-size-in-flex();
    }

    .CitrusBannerSolo-DoubleImage--landscape {
      @include enable-size-in-flex();
    }
  }

  // trio banners' tablet view
  &.part-of-trio:not(&--preview) {
    @media (min-width: 629px) and (max-width: 720px) {
      @include medium-size();
      align-items: stretch;
      height: 232px;
      min-height: auto;

      .CitrusBannerSolo-image {
        width: 100%;
        margin-right: 24px;
      }

      .CitrusBannerSolo-DoubleImage--portrait {
        min-width: 286.5px;
        width: calc(94.5% - 12px);
        height: 232px;
      }

      .CitrusBannerSolo-cta {
        height: 232px;
        padding: 0;
        @include absolutely-positioned-roundel;

        &-button.part-of-trio {
          padding: 0;
        }
      }
    }
  }

  // in-grid banners' tablet view
  &.in-grid:not(&--preview) {
    @media (min-width: 659px) and (max-width: 959px) {
      @include medium-size();
      @include statically-positioned-roundel;
      @include portrait-img-min-width;
    }
  }

  .citrus-preview-bp-medium & {
    @include medium-size();
    @include statically-positioned-roundel;
    @include portrait-img-min-width;
  }

  // All the changes above 960px
  @include respond-to-and-class("md", "large") {
    .CitrusBannerSolo-image {
      background-size: contain;
    }
  }
}

.CitrusBannerTrio {
  display: flex;
  height: 100%;
}
