@mixin link {
  & {
    text-decoration: none;
    font-weight: inherit;
    display: block;
    color: inherit;
  }
  &:hover {
    color: inherit;
  }
}

.recipes_browse_dropdown_view_container div:first-child {
  border-top: 1px solid #d3d3d3;
  width: 100%;
  background-color: white;
  padding: 0;
}

.recipes_browse_container {
  border-top: 1px solid #d3d3d3;

  &.minimized {
    top: 0px;
    position: fixed;
    background: white !important;
    width: 100%;
    transition: 0.2s ease;
    border-bottom: 1px solid $ln-color-grey;

    @include ln-respond-to("md") {
      top: calc(#{$header-height-minimized});
    }
  }
}

#recipes_browse__dropdown_view,
#recipe_browse {
  border: none;
  box-shadow: none;
  position: static;
}

.recipes_browse {
  isolation: isolate;
  display: flex;
  flex-direction: column;

  &__items_list {
    list-style-type: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.5;
    color: $ln-color-grey-dark;
    margin: 0;
    padding-block: 16px;
    display: flex;
    justify-content: flex-start;
    flex: 1 1 fit-content;
    gap: 24px;

    @media (min-width: $tabletBreakPoint) {
      font-size: 14px;
      gap: 16px;
    }
    @media (min-width: $desktopBreakPoint) {
      gap: 24px;
      font-size: 15px;
    }

    &__item a {
      @include link;

      &:hover {
        text-decoration: none;
      }
    }

    &__item button {
      background: none;
      font-size: inherit;
      color: inherit;
    }
  }
}

.recipes_browse_dropdown_view_container {
  .recipes_browse__dropdown_view {
    max-height: 220px;
    @media (min-width: $tabletBreakPoint) {
      max-height: 210px;
    }
    @media (min-width: $desktopBreakPoint) {
      max-height: 220px;
    }

    &__list {
      width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 32px 24px;
      max-height: inherit;
      display: grid;
      grid-auto-flow: column;
      gap: 4px;
      grid-template-rows: repeat(5, 1fr);
      grid-template-columns: repeat(4, 1fr);
      border: none;

      @media (min-width: $tabletBreakPoint) {
        padding-block: 24px;
      }

      & li {
        padding: 2px 0;
        font-size: 0.9rem;

        & div.analytics {
          border: none;
          display: inline;
        }

        & a {
          @include link;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
