$occasions-header-width: 90rem;

.occasions-menu {
  margin-top: var(--ds-size-space-px); // fix to stop border-top disappearing occasionally on collapse
  border-top: var(--ds-size-space-px) solid var(--ds-color-monochrome-light);
  padding: 0 var(--ds-size-space-6);

  &.minimized {
    position: fixed;
    background: white;
    width: 100%;
    z-index: 1;
    transition: 0.2s ease;
    border-bottom: 1px solid $ln-color-grey;

    top: 0px;

    @include ln-respond-to("md") {
      top: $header-height-minimized;
    }
  }

  & .parent-links {
    display: flex;
    list-style: none;
    margin: 0 auto;
    max-width: $occasions-header-width;
  }

  & .parent-link {
    &__button {
      background: transparent;
      padding: var(--ds-size-space-4) var(--ds-size-space-6) var(--ds-size-space-5) 0;
      margin-bottom: var(--ds-size-space-1); // to avoid losing the border-bottom of focus

      &:hover > span,
      &:focus > span {
        color: var(--ds-color-content-sainsburys-orange);
      }

      & .active {
        color: var(--ds-color-content-sainsburys-orange);
      }
    }

    & .child-links-container {
      padding: 0 var(--ds-size-space-6);

      &.active {
        position: fixed;
        list-style: none;
        left: 0;
        margin: 0;
        width: 100vw;
        background-color: white;
        box-shadow: 0px var(--ds-size-space-1) var(--ds-size-space-2) 0px rgba(0, 0, 0, 0.2);
        border-top: var(--ds-size-space-px) solid var(--ds-color-monochrome-light);
      }

      & .child-links {
        &.active {
          max-width: $occasions-header-width;
          margin: 0 auto;
          display: grid;
          grid-template-rows: repeat(4, auto);
          grid-template-columns: repeat(6, auto);
          grid-auto-flow: column;
          column-gap: var(--ds-size-space-2);
        }

        &.inactive {
          display: none;
        }
      }

      & .child-link {
        margin: 0;
        padding: var(--ds-size-space-3) 0;
        list-style: none;

        &__button {
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }

          &.active {
            color: var(--ds-color-content-sainsburys-orange);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
