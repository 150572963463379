.NoZonalProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;

  &__no-split {
    display: inline-block;
  }
}
