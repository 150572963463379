/* use sans font for the filter button */
.ds-c-button.filters-toolbar--open {
  @apply ds-font-body ds-text-left;
  font-size: 1rem;
}

/* set the correct border on these elements */
.ds-c-button.filters-toolbar--open,
.ds-c-checkbox.filters-toolbar--offers {
  @apply ds-bg-white
    ds-border-sm ds-border-solid ds-border-monochrome-light;
}

.ds-c-checkbox.filters-toolbar--offers .ds-c-checkbox--label {
  @apply ds-font-bold;
}

/* don't allow text wrapping, everything should be on one line */
.ds-c-button.filters-toolbar--open,
.ds-c-checkbox.filters-toolbar--offers,
.ds-c-button.filters-toolbar--clear {
  @apply ds-w-full ds-whitespace-nowrap;
}

/* Hide these elements when the screen is small */
.ds-c-checkbox.filters-toolbar--offers,
.ds-c-button.filters-toolbar--clear {
  @apply ds-hidden md:ds-flex;
}

/* ensure correct height over favourites feature tour */
.sticky-wrapper.filter-toolbar--wrapper {
  @apply ds-py-4;
  z-index: 2;
}

.filter-toolbar--grid {
  @apply ds-grid ds-grid-cols-2
    ds-gap-2
    md:ds-flex md:ds-justify-between;
}

.filter-toolbar--grid-lhs {
  @apply ds-flex ds-gap-2;
}

/* sorting dropdown */
.filter-toolbar--sorting {
  @apply ds-flex ds-gap-4;
  justify-content: end;
}

.filter-toolbar--sorting .filter-toolbar--sorting-label {
  @apply ds-hidden sm:ds-block
    ds-whitespace-nowrap;

  font-size: 1rem;
  height: fit-content;
  margin: auto 0;
}

.filter-toolbar--sorting .filter-toolbar--sorting-select {
  @apply ds-bg-white ds-max-w-52;
}

.sticky-wrapper--brand {
  background-color: unset;
}
