@use "sass:map";
$ln-webpack: true;

@import "~@jsluna/style/scss/settings";
@import "~@jsluna/style/scss/tools";
@import "~@jsluna/style/scss/generic";
@import "~@jsluna/style/scss/elements";
@import "~@jsluna/style/scss/objects";
@import "~@jsluna/style/scss/components";
@import "~@jsluna/style/scss/utilities";
@import "~@jsluna/alert/scss/main";
@import "~@jsluna/labs/src/tooltip/scss/main";
@import "~@sainsburys-tech/design-tokens/dist/sainsburys/scss/tokens.scss";

$use-ln-fonts-small: true;

$ln-container-sizes: map-merge(
  $ln-container-sizes,
  (
    xs: 570,
  )
);

$gol-breakpoints: map.deep-merge(
  $ln-breakpoints,
  (
    mobile-sm: 320px,
    mobile-md: 375px,
    mobile-lg: 425px,
  )
);

// Extract content colors from the design system tokens
$ds-color-content: map-get($ds-sainsburys, "color", "content");

// Loop through and generate classes
@each $name, $color in $ds-color-content {
  .bg-#{$name} {
    background-color: $color;
  }
}

// Extract palette colors from the design system tokens
$ds-color-palette: map-get($ds-sainsburys, "color", "palette");

// Loop through and generate classes
@each $name, $color in $ds-color-palette {
  .bg-#{$name} {
    background-color: $color;
  }
}

// Extract monochrome colors from the design system tokens
$ds-monochrome-color-content: map-get($ds-sainsburys, "color", "monochrome");

// Loop through and generate classes
@each $name, $color in $ds-monochrome-color-content {
  .theme-#{$name} {
    color: $color;
  }
}

$bgImageUrl: var(--bgImageUrl, null); // Default value

.card-with-background {
  // Check if $bgImageUrl is set before applying the background
  @if $bgImageUrl {
    background-image: $bgImageUrl;
    background-size: cover;
  }
}
