.ds-c-modal {
  overflow-y: hidden;
  max-height: calc(100vh - 32px);

  @include ln-respond-to("lg") {
    max-height: calc(80vh - 32px);
  }
}

.amend-order-modal {
  margin-bottom: 16px;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 210px);
  flex-shrink: 1;
  min-height: 0;

  @include ln-respond-to("lg") {
    max-height: calc(80vh - 32px);
  }

  @include ln-respond-to("ss") {
    &__container > dialog {
      width: 85vw;
    }
  }

  @include ln-respond-to("md") {
    &__container > dialog {
      width: 50%;
    }
  }

  @include ln-respond-to("lg") {
    &__container > dialog {
      width: 40%;
    }
  }

  @include ln-respond-to("xxl") {
    &__container > dialog {
      width: 30%;
    }
  }

  &__list {
    list-style: disc;
  }

  &__item {
    margin-left: 10px;
    padding: var(--ds-size-space-1) var(--ds-size-space-4);
  }

  &__subtitle {
    margin: 16px 0;
  }

  &__icon {
    margin-right: 16px;
    min-width: 24px;
    height: 24px;
    color: var(--ds-color-content-sainsburys-orange);
  }
}
