.delivery-slots-feedback {
  &__container {
    padding-bottom: 32px;
  }

  &__buttons {
    display: flex;
    gap: 8px;
  }

  &__thanks {
    background-color: rgb(33, 130, 52);
    display: inline-block;
    height: 48px;
    line-height: 32px;
    color: white;
    padding: 8px 16px;

    &__caption {
      font-weight: bold;
      display: block;
    }
  }
}
