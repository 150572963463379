.nav {
  // temp fixes for the christmas promo link wrapping
  &__menu-link-wrapper {
    @media (max-width: 840px) {
      padding-left: 0.75rem !important;
    }
    @media (max-width: 735px) {
      padding-left: 0.35rem !important;
    }
  }

  &__menu-link {
    @include ln-font-size($ln-font-button);

    align-items: center;
    background-color: transparent;
    border-bottom: $ln-status-bar-width solid transparent;
    color: #404040 !important;
    display: flex;
    font-family: $ln-font-family-brand !important;
    font-weight: $ln-font-weight-bold !important;
    padding-bottom: $ln-space-half;
    text-decoration: none;

    @include ln-respond-to("sm") {
      font-size: 1rem;
    }

    @include ln-respond-to("lg") {
      @include ln-font-size($ln-font-button);
    }

    &--selected,
    &:hover,
    &:focus {
      border-bottom-color: $ln-color-orange-dark;
      color: $ln-color-black;
    }

    a {
      text-decoration: none;

      &--selected,
      &:hover,
      &:focus {
        color: $ln-color-black;
      }
    }
  }

  &__menu-wrapper {
    background: $ln-color-white;
    margin-top: $ln-space;

    @include ln-respond-to("md") {
      line-height: 2rem;
    }

    .offer {
      a {
        color: #d10000;
      }
    }
  }

  &__menu-chevron {
    height: 1rem;
    margin-left: $ln-space-half;
    width: 1rem;
  }
}

.header--legacy {
  .nav__menu-link {
    color: $ln-color-black !important;
    font-family: $ln-font-family-brand !important;
    font-weight: $ln-font-weight-medium !important;
    padding-bottom: $ln-space !important;
  }
}
