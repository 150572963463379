.changes-to-trolley {
  .ln-c-form-option__label {
    padding-left: calc(24px + 1rem);

    &::before {
      left: 0;
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      flex-direction: row;
    }
  }
}
