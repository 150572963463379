.article {
  &--title-sm {
    @media (max-width: 720px) {
      font-size: 32px;
    }
  }
  &--truncate-title {
    @include ln-respond-to("lg") {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }
  }
  &__banner {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    width: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 291px;
    }
    @media (max-width: 720px) {
      display: none;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__detail {
    padding-bottom: 16px;
    & h1 {
      font-size: 2rem;
      line-height: 40px;
      margin-bottom: 16px;
    }
  }

  &__howto {
    &-block {
      > img {
        width: 100%;
        margin-bottom: 20px;
        @include ln-respond-to("md") {
          width: 65%;
        }
      }
    }
  }

  &--padding-default {
    padding-top: 211px;
    @media (max-width: 720px) {
      padding-top: 18px;
    }
  }

  &--how-to-content-image {
    max-width: 772px;
  }
}

.nutritional-information {
  p {
    & > a {
      margin: 0 3px;
    }
  }
  .referential-table {
    img {
      width: 100%;
      @include ln-respond-to("md") {
        max-width: 60%;
      }
    }
  }

  .reference-intake {
    th,
    td {
      text-align: left;
      padding: 2px 4px;
    }
  }
}
