.openable-button {
  .openable-button__button {
    font-family: Arial;
    font-weight: normal;
    color: #242424;
    border-color: #8e8e8e;
    border-radius: 2px;
  }

  .openable-button__panel {
    position: absolute;
    flex-direction: column;
    background-color: $ln-color-white;
    border: solid 1px $ln-color-grey;
    margin-top: $ln-space;
    width: 350px;
    display: flex;
    z-index: 10;

    visibility: hidden;
  }

  &--open {
    .openable-button__button {
      border-color: $ln-color-orange;
      border-width: 2px;
      background-color: rgba(229, 80, 0, 0.08);
    }

    .openable-button__panel {
      visibility: visible;
      overflow: hidden;
    }

    .openable-button__panel-content {
      overflow-y: auto;
      flex-shrink: 1;
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      .product-filter__group {
        .ln-o-inline-list__item {
          padding-left: 0;
        }
      }
    }

    .openable-button__panel-content--done {
      flex-shrink: 0;
    }

    .openable-button__done-button {
      flex: 0;
      color: $ln-color-orange;
      float: right;
    }

    .ln-o-inline-list__item {
      .filter-item-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .ln-c-form-option--checkbox {
          flex-grow: 1;
        }

        .filter-group-count-label {
          height: 100%;
          width: 50px;
          text-align: left;
        }
      }

      .ln-c-form-option__label {
        background-color: transparent !important;
        // Disable highlight on checkbox
        &:hover {
          background-color: transparent !important;
        }
      }

      // Manually set highlight on parent div
      &:hover {
        background-color: $ln-color-background-lowlight;
      }
    }
  }
}

@mixin product-filter__list--one-column {
  .ln-o-inline-list__item {
    width: 100%;
  }
}

@mixin product-filter__list--two-column {
  .ln-o-inline-list__item {
    width: 50%;
  }
}

@mixin product-filter__list--three-column {
  .ln-o-inline-list__item {
    max-width: 33%;
  }
}

@mixin product-filter__button {
  font-family: Arial;
  font-weight: normal;
  color: #242424;
  border-color: #8e8e8e;
  &:hover {
    color: #242424 !important;
    border-width: 2px;
  }
  &:focus {
    color: #242424 !important;
    border-width: 2px;
  }
}

.product-filter {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 16px;
  background: #f2f2f2;
  &__unwrapped-offer {
    max-height: 48px;
    border: 1px solid #8e8e8e;
    border-radius: 2px;
    > div.product-filter__group {
      padding: 0;
    }
  }
  &__wrapper {
    visibility: hidden;

    &--show {
      visibility: visible;
    }
  }

  &__group {
    width: 100%;
    padding: $ln-space;
  }
  &__group-title {
    padding: $ln-space;
    @include ln-font($ln-font-h6);
  }

  &__row {
    &--items {
      display: inline-flex;
      position: relative;
      width: auto;
      flex-wrap: wrap;
      white-space: pre-wrap;
    }
  }
  &__clear {
    max-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cancel__icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .openable-button__panel {
    @include product-filter__list--one-column;
  }

  .ln-o-inline-list {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .ln-o-inline-list__item {
    padding: 0;
  }

  .ln-c-form-option__label {
    width: 100%;
  }

  .ln-c-form-group {
    width: 100%;
  }

  .ln-c-label {
    width: 100%;
  }

  .ln-c-button {
    &:disabled,
    &.is-disabled {
      background: #f2f2f2;
    }
    &:not(.is-disabled),
    &:not(:disabled) {
      &:hover {
        color: #242424;
        border-width: 2px;
      }
      &:focus {
        color: #242424;
        border-width: 2px;
      }
    }
  }

  &--multi-column {
    .openable-button__panel {
      @include ln-respond-to("max-lg") {
        width: 350px;
        @include product-filter__list--one-column;
      }

      @include ln-respond-to("lg") {
        width: 700px;
        @include product-filter__list--two-column;
      }
    }
  }

  &__select {
    background-color: #f2f2f2;
  }
}

.product-filter-panel {
  background: $ln-color-white;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  &-findability {
    background: rgba(0, 0, 0, 0.7);
    left: 0;
    position: fixed;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
  }

  &__layout {
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
    &-findability {
      background-color: #fff;
      flex-direction: column;
      display: flex;
      height: 100%;
      max-width: 450px;
      position: relative;
    }
  }

  &--hidden {
    display: none;
  }

  &__header {
    color: $ln-color-grey-dark;
    display: block;
    font-family: $ln-font-family-brand;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 800;
    letter-spacing: normal;
    line-height: 1.25;
    padding: 20px;
    text-align: center;
    width: 100%;
    border-bottom: 5px solid $ln-color-grey-v-light;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;

    @include ln-respond-to("xs") {
      right: 20px;
    }
  }

  &__content {
    flex-grow: 2;
    overflow-y: scroll;
  }

  &__section {
    border-bottom: 5px solid $ln-color-grey-v-light;
    display: inline-block;
    padding: 20px 0;
    width: 100%;

    &-title {
      font-family: $ln-font-family-brand;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 15px;
      padding: 0 10px;
    }

    .item-hidden {
      display: none;
    }
  }

  .ln-c-form-option__label {
    padding-left: calc(#{$icon-size} + #{$ln-space-x4});
    width: 100%;
  }

  .ln-c-form-option {
    width: 100%;

    @include ln-respond-to("xs") {
      float: left;
      width: 50%;
    }
  }

  &__more {
    display: inline-block;
    font-family: $ln-font-family-brand;
    font-size: 18px;
    font-weight: 800;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    &-findability {
      display: block;
      font-family: $ln-font-family-brand;
      font-size: 18px;
      font-weight: 800;
      padding: 20px 0 20px 20px;
      text-align: left;
    }
  }

  &__footer {
    background-color: $ln-color-grey-v-light;
    display: block;
    padding: 25px 0 20px;
    text-align: center;
  }
}
