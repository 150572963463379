.progress {
  &__wrapper {
    position: relative;
    width: 100%;
    height: $ln-space;
    margin-top: $ln-space;
  }

  &__bar {
    width: 100%;
    height: 100%;
    position: absolute;
    background: $ln-color-orange;
    opacity: 20%;
  }

  &__percentage {
    height: 100%;
    opacity: 100%;
    position: absolute;
    background: $ln-color-orange;
  }
}

.qualified {
  background-color: #eaf2eb;
  border: 1px solid $ln-color-success;
  color: $ln-color-success;

  svg {
    margin-right: calc(#{$ln-space} * 2);
    float: left;
  }

  &__text {
    display: table-cell;
  }
}
