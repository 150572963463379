.book-delivery {
  margin: 0 0 0 1.25rem;

  &--info {
    margin-top: -2px;
  }

  & a {
    align-items: center;
    display: flex;
    font-family: MaryAnn, "Trebuchet MS", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
  }

  & a:hover {
    color: $ln-color-black;
    text-decoration: underline;
    cursor: pointer;
  }

  &__button {
    padding: 0 10px;
  }

  &__time,
  &__date {
    display: block;
    line-height: 1.2em;
  }

  &__icon {
    margin-right: 5px;
    width: 24px;

    &--eco {
      fill: $ln-color-green;
    }
  }

  &__slot {
    width: 124px;
  }

  &--mobile {
    border-bottom: $ln-color-grey-light 1px solid;
    margin-left: 0px !important;
    margin-bottom: 8px;
    max-width: 100%;
    padding: 1.25em;

    & a {
      height: auto;
    }

    & .book-delivery__slot {
      float: none;
      text-align: center;
      width: auto;
    }

    & .book-delivery__datetime {
      display: inline;
      margin-right: 4px;
    }
  }

  &--tablet .book-delivery__button {
    min-height: $ln-space-x4;
  }
}

.book-delivery-new-global-header {
  margin: 0 0 0 1.25rem;
  width: max-content;

  & a {
    color: #f06c00;
    align-items: center;
    display: flex;
    font-family: MaryAnn, "Trebuchet MS", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
  }

  & a:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &__datetime {
    display: block;
    line-height: 1.2em;
  }

  &--mobile {
    border-bottom: $ln-color-grey-light 1px solid;
    margin: 0;
    max-width: 100%;
    padding: 1.25em;
  }

  &--tablet,
  &--mobile {
    & a {
      display: inline;
      height: auto;
    }

    & .book-delivery__slot {
      float: none;
      text-align: center;
      width: auto;
    }

    & .book-delivery__datetime {
      display: inline;
      margin-right: 4px;
      white-space: nowrap;
    }
  }
}

.header > .book-delivery--mobile {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 8px;

  > button {
    min-height: $ln-space-x4;
    font-size: 16px;
    width: max-content;
  }
}
