.ds-c-modal:has(div.grouped-meal-deals-modal) {
  max-width: 500px;
  min-height: 413px;
}

.grouped-meal-deals-modal {
  display: flex;
  flex-direction: column;
  padding: 8px;

  .grouped-meal-deal-promotion__link {
    color: var(--ds-color-palette-red);
    font-weight: bold;

    span {
      margin: 12px 0;

      @include ln-respond-to("sm") {
        justify-content: normal;
      }

      display: flex;
      justify-content: space-between;
    }

    svg {
      color: #000000;

      @include ln-respond-to("sm") {
        margin-left: 8px;
      }
    }

    &--purple {
      color: $nectar-colour;

      &:hover {
        background-color: $nectar-link-background-color;
      }

      &:visited {
        color: $nectar-colour-darker;
      }
    }
  }
}
