.checkout-interstitial {
  &-grid {
    max-width: 744px;
    margin-bottom: 0;

    @include ln-respond-to("max-xs") {
      margin-bottom: 0.5rem;
    }

    .pt {
      margin-left: 0;
      margin-right: 0;

      &__icons__fav {
        padding: 10px 18px;
        z-index: inherit;
      }
    }
  }

  &-grid-BYG-AB-test {
    margin-bottom: 0;

    @include ln-respond-to("max-xs") {
      margin-bottom: 0.5rem;
    }

    @include ln-respond-to("lg") {
      max-width: 744px;
    }

    @include ln-respond-to("xl") {
      max-width: 944px;
    }

    .pt {
      margin-left: 0;
      margin-right: 0;

      &__icons__fav {
        padding: 10px 18px;
        z-index: inherit;
      }
    }
  }

  &__cta-button {
    display: flex;
    justify-content: center;

    @include ln-respond-to("sm") {
      margin-bottom: calc(#{$ln-space} * 3);
    }
  }
}
