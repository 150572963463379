.checkout-header {
  display: flex;
  justify-content: center;
  padding: $ln-space-x2;
  border-bottom: 1px solid #d8d8d8;
  @include ln-respond-to("max-sm") {
    padding: 18px $ln-space-x2 0;
  }
  @include ln-respond-to("min-md") {
    flex-wrap: wrap;
    padding: $ln-space-x2 $ln-space-x3;
  }

  .sainsburys-logo {
    width: 122.5px;
    @include ln-respond-to("min-sm") {
      margin-top: 15px;
    }
  }

  &__content {
    width: 100vw;
    padding-left: $ln-space-x3;
    padding-right: $ln-space-x3;
    display: flex;
    justify-content: space-between;
    @include ln-respond-to("max-sm") {
      flex-wrap: wrap;
      padding-left: $ln-space-x2;
      padding-right: $ln-space-x2;
    }
  }

  &__item {
    @include ln-respond-to("min-sm") {
      min-width: 164px;
    }

    a {
      display: inline-block;
    }
  }

  &__search {
    width: 100%;
    height: 48px;
    max-width: 880px;
    @include ln-respond-to("max-sm") {
      margin-top: $ln-space;
      margin-bottom: $ln-space;
    }
  }

  .search-bar {
    margin: 0;

    div {
      border-radius: 2px;
      height: 48px;
    }

    input {
      background-color: unset;
    }
  }

  .secure-checkout-label {
    display: flex;
    margin: auto;
    font-size: 18px;
    font-family: $ln-font-family-brand;
    font-weight: $ln-font-weight-bold;
    min-width: 88px;

    .label-icon {
      margin-right: 0.25rem;
    }
  }

  &__mini-trolley {
    display: flex;
    justify-content: flex-end;
  }

  .header-trolley {
    margin: 0;

    &__button {
      @include ln-respond-to("max-sm") {
        padding-right: 0;
        min-width: unset;
      }
    }
  }

  &__skipto-links {
    a {
      font-family: $ln-font-family-brand;
      font-weight: $ln-font-weight-medium;
      text-decoration: none;
    }
  }
}
