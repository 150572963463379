.plp-loading-wrapper {
  height: 25vh;
  position: relative;

  .plp-loading-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
