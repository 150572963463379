.ReviewsTabError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;

  @include ln-respond-to("min-sm") {
    margin: 20px;
  }

  &__header {
    margin: 0px;
    font-size: 20px;

    @include ln-respond-to("min-sm") {
      font-size: 28px;
    }
  }

  &__description {
    margin: 15px 0px 40px 0px;
    font-size: 14px;

    @include ln-respond-to("min-sm") {
      font-size: 16px;
    }
  }
}
