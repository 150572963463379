.mealdealbundlebox {
  grid-area: bundlebox;
  @include ln-respond-to("max-md") {
    margin: 0;
  }

  &__intro {
    margin: 0 1em 1em;
  }

  &__incompleteSection {
    background-color: $ln-color-white;
    padding: 1em;
    margin-bottom: 1em;
  }

  &__incomplete {
    display: flex;
    flex-wrap: wrap;
    align-items: middle;
    justify-content: space-evenly;

    @include ln-respond-to("min-md") {
      display: block;
    }
  }

  &__complete-wrapper {
    background-color: white;
    padding-top: $ln-space-x2;

    .ln-c-accordion {
      margin-top: 0 !important;
    }
  }

  &__complete-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: middle;
    justify-content: space-evenly;

    @include ln-respond-to("min-md") {
      display: block;
    }
  }

  &__alert {
    margin: 0 $ln-space-x2;
  }

  &__complete {
    svg {
      top: 16px;
    }
    h4 {
      padding: 0;
    }
  }

  &-price--bold {
    color: #f06c00;
  }

  &-body {
    padding: 10px 30px;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    margin: 10px;

    @include ln-respond-to("min-md") {
      grid-template-columns: 100px 1fr;
      margin: 0 0 10px 0;
    }

    &-name {
      display: block;
      text-align: center;
      font-size: 80%;
      padding-left: 0;

      @include ln-respond-to("min-md") {
        display: flex;
        align-items: center;
        font-weight: 600;
        padding-left: 10px;
      }
    }

    &-label {
      margin: 0;
      padding: 0;
      display: none;

      @include ln-respond-to("min-md") {
        display: block;
      }
    }

    &-thumbnail {
      place-self: center;
      text-align: center;
      width: 70px;
      height: 70px;
      display: inline-block;
      margin: 10px 15px;
    }
  }
}
