.offer-pills {
  background-color: #ffffff;
  padding: 0px 0px 25px 0px;
  margin-bottom: 24px;

  .secondary-item {
    margin-top: 16px !important;
  }
}

.secondary-offer-pills {
  flex-wrap: wrap !important;
}

.top-offer-pills {
  overflow: hidden;
}

.offer-pill-list {
  margin: 0px;
  width: 100%;
  list-style-type: none;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  &__swiper {
    justify-content: flex-start;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    flex: 0 0 auto;
    scroll-snap-align: center;
  }

  &__item:not(:last-child) {
    margin: 0 16px 0 0;
  }
}

.offer-pill {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  height: 40px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  color: $dark-grey-hex;
  background-color: #f2f2f2;
  border-radius: 24px;
  padding: 0px 17.64px;
  font-family: Arial;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  &__selected {
    color: #ffffff !important;
    background-color: #7f0442 !important;
    border-color: #7f0442;
  }

  &:hover {
    background-color: #d8d8d8;
    color: $dark-grey-hex;
  }
}
