.mealDealSpinner {
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.mealDealSpinner__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: calc(100vh);

  @include ln-respond-to("sm") {
    min-height: calc(100vh);
  }

  @include ln-respond-to("md") {
    min-height: calc(100vh);
  }
}
