.book-slot-grid-sticky-header {
  display: flex;
  position: fixed;
  background-color: $ln-color-grey-v-light;
  height: 80px;
  z-index: 4;
  padding-left: 131px;
  flex-grow: 1;
  max-width: 1440px;
  width: 100%;

  &__container {
    background-color: $ln-color-grey-v-light;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 40px;
    max-width: 100% !important;

    @include ln-respond-to("md") {
      top: calc(#{$header-height-minimized} + 40px);
    }

    &--full-width {
      background-color: $ln-color-grey-v-light;
      width: 100%;
    }
  }
}

.book-slot-grid-sticky-header-nomin {
  top: 0px !important;
}
