.argos {
  &--center-align-text {
    h1,
    h2,
    h3,
    h4 {
      width: 100%;
      text-align: center;
      @include ln-respond-to("max-md") {
        padding: 0 1rem;
      }
    }
  }

  &--center-align-child {
    display: grid;
    place-items: center;
  }

  &__product-card {
    height: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    @include ln-respond-to("lg") {
      padding-bottom: 30px !important;
    }
    a {
      font-size: 1rem;
      overflow: hidden;
      text-decoration: none;
      margin-bottom: 5px;
      line-height: normal;
    }
  }
  &__product-section {
    @include ln-respond-to("lg") {
      margin-left: $ln-space-x4;
      margin-right: $ln-space-x4;
    }
  }
  &--product-cta {
    @include ln-respond-to("max-md") {
      width: 100%;
    }
  }
  &--product-card-margin {
    @include ln-respond-to("max-md") {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }
  }
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
