$button-size: 40px;
$icon-size: 1em;

.resting-search {
  display: flex;
  max-height: 340px;
  overflow-y: auto;

  @include ln-respond-to("max-sm") {
    display: block;
    max-height: 60vh;
    overflow-y: scroll;
  }
}

.trending-search {
  &__container {
    @include ln-respond-to("min-sm") {
      min-width: 350px;
      &__isolated {
        width: 100%;
      }
    }

    @include ln-respond-to("max-sm") {
      max-height: 300px;
      overflow-y: scroll;
    }
  }

  &__magnolia {
    padding-top: 40px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #f6f6f6;
    width: 100%;

    &__loading {
      display: flex;
      justify-content: space-around;

      @include ln-respond-to("max-sm") {
        flex-direction: column;
        align-items: center;
      }
    }

    @include ln-respond-to("max-sm") {
      padding-top: 16px;
    }

    @media (min-width: 720px) and (max-width: 1200px) {
      overflow-x: scroll;
    }

    &__content {
      padding-bottom: 0px;
      @media (min-width: 720px) and (max-width: 1200px) {
        width: 900px;
      }
    }
  }
  &__list {
    overflow-y: scroll;
    margin-left: 0px;
    margin-bottom: 0px;
    background-color: white;

    @include ln-respond-to("min-sm") {
      height: calc(100% - 37px);
    }

    @include ln-respond-to("max-sm") {
      margin: 0px 10px;
      overflow-y: hidden;
    }

    &__item {
      border-bottom: 1px solid #f2f2f2;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__header {
    font-size: 18px;
    margin-bottom: 6px;
    margin-top: 6px;
    margin-left: 16px;

    @include ln-respond-to("max-sm") {
      margin-left: 25px;
    }
  }
}

.search-bar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-bar-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.search-bar {
  position: relative;
  margin-bottom: $ln-space;

  @include ln-respond-to("max-sm") {
    width: 100%;
  }

  @include ln-respond-to("min-md") {
    margin-top: $ln-space-half;
  }

  @include ln-respond-to("sm") {
    margin-top: $ln-space-half;
    margin-bottom: $ln-space;
  }

  // Styles for the old search bar implementation
  &.legacy-search {
    display: flex;

    > div {
      width: 100%;
      border: 1px solid $ln-color-grey-dark;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      input {
        border: 0px;
      }
    }

    .search-bar__field-container {
      display: flex;
      flex: 1;
      flex-direction: row;
    }

    .search-bar__field-label {
      flex: 1;
      display: flex;
    }

    &.result-hidden {
      ul {
        display: none;
      }
    }

    input {
      padding: 5px 10px;
      box-sizing: border-box;
      height: 45px !important;
      border: 0px;
      width: 100%;
      max-width: 100%;
      display: flex;
      flex: 1;
    }

    button {
      position: absolute;
      top: 2px;
      height: 88%;
      background: #fff;
      z-index: 1;
      right: 15px !important;

      @include ln-respond-to("sm") {
        right: 3px;
      }
    }

    input {
      height: $button-size;
      min-height: $button-size;

      &:placeholder {
        color: $ln-color-grey-dark;
      }
    }
  }

  // Styles for the new Fable SearchInput implementation
  &.fable-search {
    &__container {
      width: 100%;
      position: relative;
    }

    input {
      width: 100%;

      :global(.ln-c-search-input__field) {
        height: 45px !important;
        padding: 5px 10px;
        border: 1px solid $ln-color-grey-dark;
        box-sizing: border-box;

        &::placeholder {
          color: $ln-color-grey-dark;
        }
      }

      :global(.ln-c-search-input__button) {
        position: absolute;
        top: 2px;
        height: 42px;
        background: #fff;
        z-index: 1;
        right: 15px !important;

        @include ln-respond-to("sm") {
          right: 3px;
        }
      }
    }
  }

  @include ln-respond-to("md") {
    @media (min-width: 960px) and (max-width: 1085px) {
      width: auto;
      flex: 1;
    }
  }
}

.header:not(.header--legacy) {
  .search-bar {
    display: flex;
    flex: 1;

    &__field-group {
      margin-bottom: 0;
    }
  }

  .search-bar {
    button {
      top: 0;
      padding: 12px;
      background: #f06c00;
      z-index: 1;
      right: 0 !important;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;

      > .ln-c-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
