.delivery-pass {
  &__cta-button-container {
    display: flex;
    justify-content: center;
    @include ln-respond-to("lg") {
      justify-content: normal;
    }
  }

  &__cta-button {
    width: 100%;

    @include ln-respond-to("lg") {
      width: 324px;
    }
  }

  &__checkout-header {
    display: flex;
    justify-content: center;
    padding: $ln-space-x2;
    border-bottom: 1px solid #d8d8d8;

    @include ln-respond-to("max-sm") {
      padding: $ln-space-x2;
    }

    @include ln-respond-to("min-md") {
      flex-wrap: wrap;
      padding: $ln-space-x2 $ln-space-x3;
    }

    &__content {
      width: 100vw;
      padding-left: $ln-space-x3;
      padding-right: $ln-space-x3;

      @include ln-respond-to("max-sm") {
        flex-wrap: wrap;
        padding-left: $ln-space-x2;
        padding-right: $ln-space-x2;
      }
    }

    &__item {
      padding-bottom: $ln-space-x2;
      border-bottom: solid 1px $ln-color-grey-v-light;
    }

    &__logo {
      width: 200px;
      @include ln-respond-to("max-sm") {
        width: 150px;
      }
    }
  }

  &__iframe {
    height: 460px;

    &-heading {
      @include ln-respond-to("min-lg") {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-wrapper {
      display: flex;
    }

    &-loading {
      height: 460px;
    }
  }
}

.ds-c-checkbox-group__label {
  font-size: 26px;
}
