.nectarMagnoliaWrapper {
  &__banner {
    @include full-width-on-mobile;
    @include ln-respond-to("max-ss") {
      width: unset;
      margin-bottom: 15px;
    }

    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 40px;

    & img {
      object-fit: contain;
    }
  }

  &__FAQs {
    text-align: left;
    margin-top: 40px;

    & h4,
    p {
      font-size: unset;
    }
  }
}
