.browse-ingrid-ad {
  padding-bottom: 0 !important;
  height: 100%;
}

.browse-citrus-above-grid {
  .ln-o-section {
    padding: 0 !important;
  }
}

.browse-header-skeleton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.browse-footer {
  h3 {
    @extend .ln-u-display-2;
  }
}
