$header-height: 141px;
$header-height-sm: 179px;
$header-height-md: 222px;
$header-height-minimized: 60.6px;
$header-height-minimized-md: 74.6px;

$occasionsHeader-height: 67px;

$deliveryPassCheckoutHeader-height: 81px;
$deliveryPassCheckoutHeader-height-sm: 135px;

$productControl-height: 116px;

$tabletBreakPoint: 719px;
$desktopBreakPoint: 804px;

$banner-blue-hex: #e4f1ff;
$dark-grey-hex: #404040;
$lighter-orange-hex: #fef5f0;
