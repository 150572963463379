.similar-products-drawer {
  @include ln-respond-to("min-xs") {
    width: 400px !important;
  }
  .ds-c-drawer__header {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 1rem;
  }

  .pt-grid-item {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 10px;

    &:last-child {
      border-bottom: none;
    }
  }
}
