.NoSlotBookedCard {
  grid-area: card;
  grid-template-columns: repeat(5, 1fr);

  grid-template-areas:
    "ynp-logo ynp-logo title title title"
    "ynp-logo ynp-logo title title title"
    "paragraph paragraph paragraph paragraph paragraph"
    "button button button button button";

  @include ln-respond-to("xs") {
    grid-template-areas:
      "ynp-logo title title title ."
      "ynp-logo title title title ."
      "paragraph paragraph paragraph paragraph paragraph"
      "button button button button button";
  }

  @include ln-respond-to("ss") {
    grid-template-areas:
      "ynp-logo title title title title"
      "ynp-logo title title title title"
      "paragraph paragraph paragraph paragraph paragraph"
      "button button button button button";
  }

  @include ln-respond-to("md") {
    grid-template-areas:
      ". . title title title"
      "ynp-logo ynp-logo title title title"
      "ynp-logo ynp-logo paragraph paragraph paragraph"
      "ynp-logo ynp-logo paragraph paragraph paragraph"
      ". . button button button";
  }

  &--ynp-logo-container {
    grid-area: ynp-logo;
    display: flex;
    align-items: center;

    @include ln-respond-to("sm") {
      justify-content: left;
    }
  }

  &--ynp-logo {
    @include ln-respond-to("sm") {
      max-width: 120px;
    }

    @include ln-respond-to("md") {
      align-self: center;
      justify-self: center;
      max-width: 160px;
    }

    @include ln-respond-to("lg") {
      max-width: 200px;
    }
  }

  &--title-container {
    grid-area: title;
    display: flex;
    align-items: center;
  }

  &--title {
    font-family: MaryAnn, "Trebuchet MS", sans-serif;
    color: var(--ds-color-palette-orange);
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin: 0px;

    @include ln-respond-to("xs") {
      font-size: 26px;
      line-height: 32px;

      justify-content: flex-start;
      align-items: center;
    }

    @include ln-respond-to("md") {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &--paragraph {
    color: var(--ds-color-palette-black);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    grid-area: paragraph;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;

    margin: 0px;

    @include ln-respond-to("xs") {
      font-size: 16px;
    }

    @include ln-respond-to("sm") {
      justify-content: flex-start;
    }
  }

  &--book-slot-btn {
    grid-area: button;
    height: 48px;
  }
}
