.CYSMHeading {
  font-size: 26px;

  @include ln-respond-to("min-sm") {
    font-size: 32px;
  }
}

.CYSMParagraph {
  height: 75px;
}
