.checkout-error {
  &__cta-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include ln-respond-to("xs") {
      flex-direction: row;
      justify-content: center;
      margin-right: -$ln-space-x2;
    }

    .ln-c-button {
      width: 100%;
      margin-bottom: $ln-space-x2;

      @include ln-respond-to("xs") {
        width: 218px;
        margin-right: $ln-space-x2;
      }
    }
  }
}
