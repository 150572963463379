.header--legacy .header-trolley__button {
  min-width: 120px;
  padding-left: 16px;
  padding-right: 16px;

  &__icon-wrapper {
    margin-right: 10px;
  }
}

.header-trolley {
  background-color: $ln-color-white;
  border-radius: 2px;
  display: inline-block;
  float: right;
  font-family: $ln-font-family-brand;
  margin: 0 -10px 0 1.25rem !important;
  position: relative;
  text-decoration: none;
  z-index: 5;

  @include ln-respond-to("md") {
    align-items: center;
    background-color: $ln-color-orange;
    display: flex;
    height: 48px;
    margin: 0;
  }

  @include ln-respond-to("max-md") {
    margin-left: 1.5rem !important;
  }

  &--minimized {
    margin-left: 0 !important;
  }

  &__button {
    align-items: center;
    background-color: white;
    border-radius: 2px;
    color: $ln-color-black;
    cursor: pointer;
    display: flex;
    font-family: $ln-font-family-brand;
    font-size: 1.2em;
    padding-left: 7px;
    padding-right: 10px;
    position: relative;
    text-decoration: none;
    z-index: 1;

    @include ln-respond-to("md") {
      &:active,
      &:hover,
      &:focus {
        background-color: $ln-color-orange-dark;
      }

      background-color: $ln-color-orange;
      height: 48px;
    }
  }

  .pt-button {
    &__quantity {
      width: 48px;
    }

    &__quantity--small {
      font-size: 18px;
    }
  }

  &__loading {
    margin: 0px 40px;
  }

  &__icon {
    color: $ln-color-orange;
    font-size: 18px;

    @include ln-respond-to("md") {
      color: $ln-color-white;
    }

    &-wrapper {
      fill: $ln-color-black;
      float: left;
      margin-right: 10px;
      margin-top: -3px;
      position: relative;
      width: 26px;

      @include ln-respond-to("md") {
        fill: $ln-color-white;
        margin-top: 0;
      }
    }
  }

  &__count {
    background: $ln-color-white;
    border: 1px solid $ln-color-orange;
    border-radius: 15px;
    color: #f06c00;
    font-family: $ln-font-family-base;
    font-size: 11px;
    font-style: normal;
    font-weight: bold;
    height: 15px;
    line-height: 1.45;
    min-width: 23px;
    padding: 0 2px;
    position: absolute;
    right: -9px;
    text-align: center;
    top: 0;

    @include ln-respond-to("md") {
      border: 0;
    }
  }

  &__total {
    color: $ln-color-orange;
    float: right;
    font-size: 18px;
    font-weight: 800;
    padding: 0;

    @include ln-respond-to("md") {
      color: $ln-color-white;
    }
  }

  &__mask {
    display: inline-block;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.mini-trolley {
  height: 724px;
  padding-top: 30px;
  position: absolute;
  right: 0;
  top: 40px;
  width: 424px;
  z-index: 1;

  &__cloud {
    background-color: $ln-color-white;
    box-shadow: 0 3px 14px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 70vh;
    max-height: 694px;
    min-height: 350px;
    width: 424px;
  }

  &__arrow-up {
    position: absolute;
    right: 40px;
    top: 6px;
  }

  &__header {
    border-bottom: 1px solid $ln-color-grey-light;
    color: $ln-color-black;
    display: inline-block;
    float: left;
    font-size: 20px;
    font-stretch: normal;
    font-weight: 800;
    padding: 12px 16px 4px;
    width: 100%;
  }

  &__item-number {
    font-weight: 500;
  }

  &__content {
    background-color: $ln-color-grey-v-light;
    display: inline-block;
    flex-grow: 1;
    float: left;
    overflow: scroll;
    padding: 15px 10px;
    width: 100%;

    &--flex {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &__full-trolley-link {
    float: right;
    font-size: 18px;
    margin-right: -6px;
    margin-top: -9px;
  }

  &__empty-button {
    width: 30%;

    &-wrapper {
      display: inline-block;
      margin: 15px 0;
      text-align: center;
      width: 100%;
      button:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
      }
    }
  }

  &__footer {
    border-top: 1px solid $ln-color-grey-light;
    display: inline-block;
    padding: 12px 16px;
    position: relative;
    width: 100%;
  }

  &__savings {
    font-family: $ln-font-family-base;
    font-size: 15px;
    color: $ln-color-red;
  }

  &__total {
    bottom: 5px;
    color: $ln-color-black;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 1.2px;
    position: absolute;
  }

  &__cta-button {
    float: right;
    height: 48px;
    width: 224px;
  }

  &__close-button {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  &__panel {
    text-align: center;
    width: 100%;
    margin: auto;

    &-image {
      margin-bottom: 25px;
      width: 135px;
    }

    &-title {
      display: inline-block;
      font-size: 24px;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 15px;
      max-width: 290px;
      width: 100%;
    }

    &-subtitle {
      display: inline-block;
      font-family: $ln-font-family-base;
      margin-bottom: 30px;
      width: 100%;
    }

    &-button-left {
      margin-right: 5px;
      width: 160px;
    }

    &-button-center {
      min-width: 224px;
    }

    &-button-right {
      margin-left: 5px;
      width: 160px;
    }

    &-loading-container {
      margin: auto;
    }

    &-loading {
      width: 32px;
    }
  }

  .minimum-spend {
    background: $ln-color-info;
    color: $ln-color-white;
    font-family: $ln-font-family-base;
    font-weight: $ln-font-weight-bold;
    padding: $ln-space;
    cursor: default;
  }

  .promotion-message__link {
    $nectar-colour: #8223fa;
    color: $nectar-colour;
  }
}

.top-right-links--login {
  white-space: nowrap;
}
