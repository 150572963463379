.header {
  .megaNavDrawer {
    overscroll-behavior: contain;

    .ds-c-drawer__content,
    &__menu {
      background-color: $ds-color-alias-body-background-light;
    }

    a,
    button,
    span {
      font-family: var(--ds-font-family-base);
      color: #404040;
      justify-content: space-between;
    }

    &.ds-c-drawer,
    .ds-c-drawer__content {
      margin: 0;
      padding: 0;
    }

    &__link {
      padding: 0;
      text-decoration: none;
      font-size: 1rem;
    }

    &__groceriesHome.megaNavDrawer__item.megaNavDrawer__groceryItem {
      font-family: var(--ds-typography-brand-font-family) !important;
      font-size: 1.1rem;
      color: #404040;
      font-weight: bold;
    }

    &__list,
    &__list ul {
      list-style-type: none;
      margin: 0;
      padding-left: var(--ds-size-space-6);
      background-color: $ds-color-alias-component-background-light;
    }

    &__childItem,
    &__item {
      text-decoration: none;
      padding: 6px 35px 6px 0;
      font-size: 1rem;
      color: #404040 !important;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      min-height: 48px;
      box-sizing: border-box;

      a {
        font-weight: 400;
        width: 100%;
        display: flex;
        align-items: center;
        &:last-child {
          border-bottom: none;
        }
      }
    }

    &__childItem {
      justify-content: space-between;

      &--parent {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        color: #404040;
        font-size: 1rem;
        font-weight: 400;
        padding: 0;

        &.megaNavDrawer__groceryItem {
          justify-content: flex-start;

          .megaNavDrawer__childItem-icon {
            margin-left: auto;
            width: 1rem;
            min-width: 1rem;
            color: #404040;
          }
        }
      }

      &-icon {
        width: 1rem;
        min-width: 1rem;
        color: #404040;
      }
    }

    &__seasonal-container {
      background-color: $ds-color-alias-component-background-light;
      @apply ds-px-6 ds-py-4;
    }
  }

  .myAccountSection {
    padding-top: 10px;
    color: #404040;
    font-weight: bold;
    cursor: default;

    &,
    a,
    button,
    span {
      font-family: var(--ds-typography-brand-font-family) !important;
    }
  }
}

.headerSection {
  background-color: $ds-color-alias-body-background-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px 10px 24px;
}

#headerSection__close {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#headerSection__back {
  justify-content: start;
  padding-left: 0px;
}

#headerSection__back,
#headerSection__close,
#headerSection__item {
  font-family: var(--ds-typography-brand-font-family) !important;
  font-size: 1.1rem;
  color: #404040;
  font-weight: bold;
}

// reset the button highlights
.megaNavDrawer__childItem--parent:active,
.megaNavDrawer__childItem--parent:hover,
.megaNavDrawer__childItem--parent:focus,
#headerSection__close:active,
#headerSection__close:focus,
#headerSection__back:active,
#headerSection__back:hover,
#headerSection__back:focus {
  background-color: unset !important;
  color: unset !important;
}
