@mixin counterTotal {
  display: block;
  font-family: MaryAnn, "Trebuchet MS", sans-serif;
  font-size: 30px;
  line-height: 32px;
  font-weight: 800;
}

.SlotBookedCard {
  grid-area: card;
  grid-template-columns: repeat(10, 1fr);

  grid-template-areas:
    "ynp-logo ynp-logo ynp-logo ynp-logo title title title title title ."
    "gallery gallery gallery gallery gallery gallery gallery gallery gallery gallery"
    "gallery gallery gallery gallery gallery gallery gallery gallery gallery gallery"
    "button button button button button button button button button button";

  @include ln-respond-to("ss") {
    grid-template-areas:
      "ynp-logo ynp-logo title title title title title title title title"
      "ynp-logo ynp-logo title title title title title title title title"
      "gallery gallery gallery gallery gallery gallery gallery gallery gallery gallery"
      "button button button button button button button button button button";
  }

  @include ln-respond-to("md") {
    grid-template-areas:
      "ynp-logo ynp-logo ynp-logo title title title title title title title"
      "gallery gallery gallery gallery gallery gallery gallery gallery gallery gallery"
      "gallery gallery gallery gallery gallery gallery gallery gallery gallery gallery"
      "button button button button button button button button button button";
  }

  &--ynp-logo-container {
    grid-area: ynp-logo;

    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 125px;

    @include ln-respond-to("xs") {
      max-width: 120px;
    }

    @include ln-respond-to("md") {
      align-self: center;
      justify-self: center;
      max-width: 160px;
    }

    @include ln-respond-to("lg") {
      max-width: 200px;
    }
  }

  &--title {
    grid-area: title;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;

    @include ln-respond-to("xs") {
      justify-content: flex-start;
      align-items: center;
    }
  }

  &--title-text {
    font-family: MaryAnn, "Trebuchet MS", sans-serif;
    color: var(--ds-color-palette-orange);
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    margin: 0px;

    @include ln-respond-to("xs") {
      font-size: 26px;
      line-height: 32px;
    }

    @include ln-respond-to("lg") {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &--gallery {
    grid-area: gallery;

    display: grid;

    gap: 5px;
    margin: 0px;

    grid-template-columns: repeat(4, 1fr);

    @include ln-respond-to("ss") {
      grid-template-columns: repeat(8, 1fr);
    }

    @include ln-respond-to("md") {
      grid-template-columns: repeat(6, 1fr);
    }

    &__partially-opted-in {
      @include ln-respond-to("max-ss") {
        grid-template-areas: "product-1 product-2 product-3 remaining-products-count";
      }
    }

    &__not-opted-in {
      @include ln-respond-to("max-ss") {
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas:
          "product-1 product-1 product-2 product-3"
          "product-1 product-1 product-4 remaining-products-count";

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(4) {
            grid-area: product-4;
          }
        }
      }
    }

    @include ln-respond-to("max-ss") {
      &[data-total-products="5"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+2";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }

      &[data-total-products="6"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+3";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }

      &[data-total-products="7"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+4";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }

      &[data-total-products="8"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+5";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }

      &[data-total-products="9"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+6";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }

      &[data-total-products="10"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+7";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 5) {
            display: none;
          }
        }
      }
    }

    @include ln-respond-to("ss") {
      &[data-total-products="8"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+1";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 8) {
            display: none;
          }
        }
      }

      &[data-total-products="9"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+2";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 8) {
            display: none;
          }
        }
      }

      &[data-total-products="10"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+3";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 8) {
            display: none;
          }
        }
      }
    }

    @include ln-respond-to("md") {
      &[data-total-products="7"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+2";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 6) {
            display: none;
          }
        }
      }

      &[data-total-products="8"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+3";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 6) {
            display: none;
          }
        }
      }

      &[data-total-products="9"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+4";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 6) {
            display: none;
          }
        }
      }

      &[data-total-products="10"] {
        & .SlotBookedCard--gallery-tile-counter {
          display: flex;
          &::before {
            @include counterTotal;
            content: "+5";
          }
        }

        & .SlotBookedCard--gallery-tile-product {
          &:nth-child(n + 6) {
            display: none;
          }
        }
      }
    }

    &-tile {
      max-width: 99px;
      max-height: 99px;
    }

    &-tile-product {
      &:nth-child(1) {
        grid-area: product-1;

        @include ln-respond-to("max-ss") {
          max-width: unset;
          max-height: unset;
        }
      }

      &:nth-child(2) {
        grid-area: product-2;
      }

      &:nth-child(3) {
        grid-area: product-3;
      }

      @include ln-respond-to("ss") {
        &:nth-child(1) {
          grid-area: unset;
        }

        &:nth-child(2) {
          grid-area: unset;
        }

        &:nth-child(3) {
          grid-area: unset;
        }
      }
    }

    &-tile-counter {
      background: var(--ds-color-content-nectar-purple);
      color: var(--ds-color-palette-white);
      padding: 5px;

      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      font-size: 18px;
      border-radius: 2px;

      grid-area: remaining-products-count;

      @include ln-respond-to("ss") {
        grid-area: unset;
      }
    }

    &-tile-total {
      display: block;
      line-height: 15px;
    }

    &-tile-img {
      max-height: 100%;
    }
  }

  &--book-slot-btn {
    grid-area: button;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;

    @include ln-respond-to("xs") {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include ln-respond-to("md") {
      max-width: 270px;
      justify-self: center;
    }
  }
}
