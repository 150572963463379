.sticky-wrapper-nomin {
  top: 0px !important;
}

.sticky-wrapper,
.sticky-wrapper-top {
  position: sticky;
  background-color: #f2f2f2;
  transition: top;
  transition-duration: 250ms;
  left: 0px;
  z-index: 2;

  @include ln-respond-to("sm") {
    top: -9px;
  }
  @include ln-respond-to("md") {
    top: calc(#{$header-height-minimized});
  }

  @include ln-respond-to("max-xs") {
    padding-right: 1rem;
    margin-right: -1rem;
  }

  &.sticky-wrapper-hover:hover,
  &--on {
    top: -20px;

    @include ln-respond-to("sm") {
      top: -9px;
    }

    @include ln-respond-to("md") {
      top: -20px;
    }
    & + .SRF .content {
      top: calc(#{$productControl-height} + #{$ln-space-x3});

      &.hiding-header {
        top: calc(#{$productControl-height} + #{$ln-space-x3});
      }
    }

    &:not(.sticky-wrapper--top-padded) {
      top: 0;
    }

    &.sticky-wrapper--occasions {
      @include ln-respond-to("sm") {
        top: calc(#{$occasionsHeader-height} - 9px);
      }

      @include ln-respond-to("md") {
        top: calc(#{$occasionsHeader-height} - 7px);
      }
    }
  }
}

.sticky-wrapper-bottom {
  position: sticky;
  background-color: #f2f2f2;
  transition: bottom;
  transition-duration: 250ms;
  bottom: 0;
  z-index: 10;

  &:hover,
  &--on {
    bottom: 0;
  }
}

.product-controls {
  @include ln-respond-to("max-sm") {
    margin-bottom: $ln-space;
  }

  @include ln-respond-to("min-sm") {
    &.ln-c-tooltip {
      width: auto;
    }
  }

  &__wrapper.ln-c-tooltip {
    width: 100%;
    & .product-controls.ln-o-grid {
      margin-bottom: 0;
    }
  }
}

.suggested-term {
  font-size: 16px;
  span {
    cursor: pointer;
    text-decoration: underline;
  }
}
