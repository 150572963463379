.AddAllButton {
  width: 343px;
  text-align: center;
  margin-bottom: 16px;

  @include ln-respond-to("max-mobile-sm", $gol-breakpoints) {
    width: 288px;
    font-size: 18px;
    padding: 8px;
    margin-bottom: 0;
  }
}
