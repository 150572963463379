.next-delivery {
  &__banner {
    background-color: $ln-color-white;
    padding: $ln-space-x2;
    margin: $ln-space-x3 0.25%;
    margin-bottom: 0;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include ln-respond-to("sm") {
      flex-direction: row;
      margin-bottom: $ln-space-x3;
    }
  }

  &__header {
    font-size: 18px;
    margin-bottom: 12px;
  }

  &__icon-text {
    .ln-c-icon {
      position: relative;
      bottom: 2px;
      height: 24px;
      width: 24px;
      margin-right: $ln-space;
    }

    &--info-icon {
      .ln-c-icon {
        height: 22px;
        width: 22px;
        left: 1px;
      }
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    margin-top: $ln-space-x2;

    .ln-c-button {
      width: 100%;
    }

    @include ln-respond-to("sm") {
      margin-top: 0;

      .ln-c-button {
        width: auto;
      }
    }
  }
}
