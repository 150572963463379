@use "sass:map";

.eventsfeatures {
  display: grid;
  grid-template-areas: "producttiles producttiles producttiles producttiles producttiles producttiles";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  &__placeholder {
    background-color: #d8d8d8;
    padding: 15px;
  }
  &__tileList {
    grid-area: producttiles;
  }

  &-price--bold {
    color: #f06c00;
  }
  &-wrapper {
    background-color: $ln-color-white;
  }
  &-body {
    padding: 10px 30px;
  }
  &-item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin: 0 0 10px 0;
    @include ln-respond-to("max-lg") {
      grid-template-columns: 1fr;
      justify-content: center;
      margin: 10px;
    }
    &__name {
      display: flex;
      align-items: center;
      font-weight: 600;
      padding-left: 10px;
      @include ln-respond-to("max-lg") {
        display: block;
        text-align: center;
        font-size: 80%;
        padding-left: 0;
      }
      & p {
        margin: 0;
        padding: 0;
      }
    }
    &__thumbnail {
      text-align: center;
      width: 70px;
      height: 70px;
      display: inline-block;
      margin: 10px auto;
    }
  }
}
