$navbar-height: 52px;

@mixin sticky-bar {
  position: sticky;
  background-color: #f2f2f2;
  padding: 0.4rem 1rem 0 1rem;
}

.SRF {
  display: grid;
  grid-template-areas:
    "dietary dietary dietary dietary dietary dietary"
    "categories producttiles producttiles producttiles producttiles producttiles";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

  &__optimised {
    grid-template-areas:
      "dietary dietary dietary dietary dietary dietary dietary"
      "categories producttiles producttiles producttiles producttiles producttiles producttiles";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    @include ln-respond-to("max-lg") {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "categories categories categories categories categories categories"
        "filters filters filters sorting sorting sorting"
        "dietary dietary dietary dietary dietary dietary"
        "producttiles producttiles producttiles producttiles producttiles producttiles";
    }
  }

  &__newfilters {
    grid-template-areas: "producttiles producttiles producttiles producttiles producttiles producttiles";
  }

  /* luna override */
  .ln-o-grid {
    min-width: auto;
    margin-left: -12px;

    &__item {
      padding-left: 12px;
      padding-bottom: 12px;
    }
  }

  &__filters {
    display: flex;
    padding-top: 0.625rem;
  }

  &__sorting {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.625rem;
    &.loading {
      display: block;
    }
  }

  &__dietary {
    grid-area: dietary;
    height: 68px;
    /* TODO temporary before we add pagination */
    display: flex;
    justify-content: flex-end;
    .skeleton {
      margin-top: 20px;
    }
  }

  &__categories {
    grid-area: categories;
    min-width: 300px;
    width: 100%;
    padding: 0 25px 0 0;
    box-sizing: border-box;

    .content {
      padding: 20px;
      background-color: $ln-color-white;
      position: sticky;
      transition: 0.5s;
      top: calc(#{$ln-space-x3});

      &.hiding-header {
        top: calc(81px + #{$ln-space-x3});
        max-height: 50vh;
        overflow: auto;
      }
    }
    &,
    & ul {
      list-style-type: none;
      margin: 0;
      li {
        @include ln-respond-to("max-lg") {
          padding-bottom: 6px;
        }
      }
      a {
        text-decoration: none;
        display: inline-block;
        margin-bottom: 0.2em;
      }
    }

    .isParent {
      font-weight: bold;
      &.isNotSelected {
        margin-left: -4px;
      }
    }
  }

  &__tileList {
    grid-area: producttiles;
  }

  // This css below is from CategoryFilters Component

  &--category {
    cursor: pointer;
    margin: 0;
    user-select: none;
    margin-left: 1rem;
    display: flex;
    justify-content: space-between;
    max-width: 200px;
    min-width: 15vw;
    line-height: 1.2;
    margin-bottom: 4px;
    &__title {
      @include ln-respond-to("max-lg") {
        font-family: $ln-font-family-brand;
        font-size: 20px;
        font-weight: 800;
      }
    }
  }

  .loading {
    opacity: 0.5;
  }

  .backArrow {
    height: 1em;
    transform: translateY(-0.125em);
  }

  @include ln-respond-to("max-lg") {
    grid-template-areas:
      "categories categories categories categories categories categories"
      "filters filters filters sorting sorting sorting"
      "dietary dietary dietary dietary dietary dietary"
      "producttiles producttiles producttiles producttiles producttiles producttiles";

    &__filters,
    &__sorting {
      padding: 1rem 0;
      border: 1px solid #f2f2f2;
      &.loading {
        :first-child {
          display: none;
        }
      }
    }
  }
}

.margin-top-search-findability {
  padding-top: 0.1px;
  margin-top: 24px;

  &.occasions-and-minimized {
    @include ln-respond-to("md") {
      padding-top: calc(#{$header-height-minimized} + 0.1px);
    }
  }
}

.margin-top-search-findability-left {
  margin-top: 116px;
  padding-top: 0.1px;

  @include ln-respond-to("sm") {
    margin-top: 174px;
  }

  @include ln-respond-to("md") {
    margin-top: 218px;
  }

  @include ln-respond-to("lg") {
    margin-top: 221px;
  }

  &.occasions {
    margin-top: calc(#{$header-height} + 12px);
    padding-top: 0.1px;

    @include ln-respond-to("sm") {
      margin-top: calc(#{$header-height-sm + $occasionsHeader-height} + 24px);
    }

    @include ln-respond-to("md") {
      margin-top: calc(#{$header-height-md + $occasionsHeader-height} + 24px);
    }
  }
}

.margin-top-search-findability-left-out {
  margin-top: 149px;
  padding-top: 0.1px;

  @include ln-respond-to("sm") {
    margin-top: 173px;
  }

  @include ln-respond-to("md") {
    margin-top: 216px;
  }

  @include ln-respond-to("lg") {
    margin-top: 219px;
  }

  &.occasions {
    margin-top: calc(#{$header-height} + 12px);
    padding-top: 0.1px;

    @include ln-respond-to("sm") {
      margin-top: calc(#{$header-height-sm + $occasionsHeader-height} + 24px);
    }

    @include ln-respond-to("md") {
      margin-top: calc(#{$header-height-md + $occasionsHeader-height} + 24px);
    }
  }
}

.margin-top-with-occasions {
  margin-top: 4px;
  @include ln-respond-to("xs") {
    margin-top: 56px;
  }
  @include ln-respond-to("sm") {
    margin-top: 20px;
  }
  @include ln-respond-to("md") {
    margin-top: 66px;
  }
  @media (min-width: 460px) and (max-width: 719px) {
    margin-top: 4px;
  }
}

.multi-search-view {
  background-color: white;
}

.citrus-bottom-trio {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  margin-bottom: 16px;
  & .CitrusBannerSolo {
    flex: 1 1 auto;
  }
  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
}

.product-tile-grid {
  grid-auto-flow: dense;
}

.product-tile-row {
  display: contents;
}
