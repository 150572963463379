.trolley-summary {
  margin-bottom: calc(#{$ln-space} * 2);

  @include ln-respond-to("sm") {
    margin-bottom: calc(#{$ln-space} * 3);
    width: 720px;
  }

  &__header {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      margin-bottom: 0;
    }
  }
  &__list-container {
    margin-left: 0px;
  }

  &__alternative-desc-text {
    font-size: 18px;
    font-weight: 800;
    font-family: "Mary Ann", sans-serif;
    padding-bottom: 10px;
    margin-top: -5px;
  }

  &__cta-button {
    margin-top: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      margin-top: calc(#{$ln-space} * 3);
    }
  }

  &__occasions-subs-message {
    border-top: solid 1px $ln-color-grey-v-light;
    padding-top: 24px;

    &-header {
      font-size: 18px;
      margin-bottom: calc(#{$ln-space} * 2);
    }
    &-text {
      font-size: 14px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  &__card-info-banner {
    &__container {
      display: flex;
      padding: 1rem;
      width: 100%;
      box-sizing: border-box;
      background-color: #e4f2ff;
      border: 1px solid #99cbff;
      margin-top: $ln-space;

      @include ln-respond-to("sm") {
        margin-top: calc(#{$ln-space} * 3);
        margin-bottom: calc(#{$ln-space} * 2);
        flex-wrap: nowrap;
      }
    }

    &__content {
      display: flex;
      align-items: start;
      gap: 16px;
    }

    &__icon {
      color: #1976d2;
      height: 2.5rem;
      min-width: 1.5rem;
      vertical-align: top;
      display: none;

      @include ln-respond-to("sm") {
        display: block;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
    }
  }
}
