.getStartedWithNectarCard {
  $link-btn-width: 288px;

  &__button-title {
    text-align: center;
    margin-bottom: 16px;
    width: $link-btn-width;
    margin: 0px auto 7px auto;
  }

  &__card {
    @include full-width-on-mobile;
    @include ln-respond-to("max-ss") {
      width: unset;
    }
  }

  &__link-button {
    width: 100%;
  }

  &__section-header {
    font-size: 26px;
    text-align: left;

    @include ln-respond-to("max-sm") {
      font-size: 20px;
      position: relative;
      left: -7px;
    }
  }

  &__card-header {
    font-size: 26px;
    margin-bottom: 16px;
    text-align: center;

    @include ln-respond-to("max-sm") {
      font-size: 22px;
    }
  }

  &__grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 311px;

    @include ln-respond-to("max-sm") {
      width: $link-btn-width;
    }

    &:last-of-type {
      margin-left: 20px;

      @include ln-respond-to("max-sm") {
        margin-top: 16px;
        margin-left: 0px;
      }
    }
  }

  &__grid-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @include ln-respond-to("max-sm") {
      flex-direction: column;
    }
  }
}
