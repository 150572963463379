$z-index_header: 3;

// fix to prevent an iframe created by webpack-dev-server@3.x.x from
// blocking the screen when an overlay attempts to be displayed
body > iframe {
  pointer-events: none;
}

.app {
  height: 100%;
  min-height: 100vh;
  position: relative;

  .ln-o-page__header {
    position: relative;
    z-index: 6;

    @include ln-respond-to("sm") {
      z-index: $z-index_header;
    }

    > [tabindex]:focus {
      box-shadow: none !important;
    }
  }

  .ln-o-page__body {
    margin-top: unset;
  }

  &.simplified-view {
    .ln-o-page__header,
    .ln-o-page__footer {
      display: none;
    }
  }

  &.hide-footer {
    .ln-o-page__footer {
      display: none;
    }
  }

  &.hide-header {
    .ln-o-page__header {
      display: none;
    }
    .ln-o-page__footer {
      margin-right: -1rem;

      @include ln-respond-to("xs") {
        margin-right: 0;
      }
    }
  }

  .gol-theme {
    @media (min-width: 720px) {
      .product-controls {
        margin-left: 0;
        padding-right: 24px;
        margin-bottom: 16px;
      }
    }

    &.gol-theme-light {
      .ln-c-breadcrumbs {
        color: white;
      }

      .ln-c-pagination__list {
        background: white;
      }
    }

    &.gol-theme-dark {
      .ln-c-breadcrumbs {
        color: #404040;
      }
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.no-break {
  white-space: nowrap;
}

// used to debug the breakpoint the app is in
// add #root to the url to see the info
#root:target {
  &:before {
    background: rgba(255, 255, 255, 0.8);
    color: $ln-color-orange;
    display: block;
    font-weight: bold;
    left: 1rem;
    position: fixed;
    top: 1rem;
    z-index: 100;

    @include ln-respond-to("xs") {
      content: "xs";
    }

    @include ln-respond-to("ss") {
      content: "ss";
    }

    @include ln-respond-to("sm") {
      content: "sm";
    }

    @include ln-respond-to("md") {
      content: "md";
    }

    @include ln-respond-to("lg") {
      content: "lg";
    }

    @include ln-respond-to("xl") {
      content: "xl";
    }

    @include ln-respond-to("xxl") {
      content: "xxl";
    }
  }
}

// Page body contains React components, whose brief invisibility before mount causes layout shift.
// Giving it a minimum height makes sure that only intended components appear above the fold.
.page-body {
  min-height: 100vh;
}

// This was never used?
@mixin offset-from-header($property) {
  #{$property}: calc(#{$header-height});

  @include ln-respond-to("sm") {
    #{$property}: $header-height-sm;
  }

  @include ln-respond-to("md") {
    #{$property}: $header-height-md;
  }
}

.margin-top-checkout,
.margin-top-delivery-pass-checkout {
  margin-top: 16px;
}

.padding-bottom-default {
  padding-bottom: 5rem;

  &__book-slot {
    padding: 2rem;

    @include ln-respond-to("sm") {
      padding-bottom: 5rem;
    }
  }
}

.ln-u-margin-bottom\*0 {
  margin-bottom: 0;
}

.ln-u-margin-left\*0 {
  margin-left: 0;
}

.ln-c-alert {
  &.alert-info-light {
    background-color: #e8f1fb;
    color: #135ca3;
    border: 1px solid #135ca3;
  }

  &.alert-success-light {
    background-color: #eaf2eb;
    color: #246127;
    border: 1px solid #2e7d32;
  }
}
