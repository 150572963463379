.date-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    &-warning {
      display: flex;
      margin: 0 4px 0 4px;
      vertical-align: middle;
      height: 24px;
    }
  }
}
