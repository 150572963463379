.sainsburys-logo {
  max-width: 160px;
  min-width: 96px;

  svg {
    display: block;
  }
}

.nectar-logo {
  max-width: 60px;
  min-width: 24px;
}

.select-logo,
.nectar-logo {
  img {
    display: block;
  }
}

.header--legacy .logo-image {
  width: 100%;
}

.logo-image-header {
  @include ln-respond-to("max-md") {
    padding-left: 0.5rem;
  }

  .header-minimized & {
    @include ln-respond-to("max-md") {
      padding-left: 0;
    }
  }
}

.nectar-logo-3d {
  max-width: 70px;
  min-width: 53px;

  img {
    display: block;
  }
}
