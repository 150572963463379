.occasions_skeleton_header,
.occasions_skeleton_body {
  width: 100%;
  background-color: #ffffff;
}

.occasions_skeleton_header {
  height: auto;
  padding: 40px;
  margin-bottom: 16px;
}

.occasions_skeleton_body {
  height: auto;
  padding: 32px;
}

.occasions_skeleton_thin_bar,
.occasions_skeleton_header_large_bar,
.occasions_skeleton_rect_mobile {
  margin-bottom: 16px;
}

.occasions_skeleton_body_large_bar {
  margin-bottom: 32px;
}

.occasions_skeleton_header_gridItem {
  display: grid;
}

.align_center {
  justify-self: center;
}

.occasions_skeleton_rect {
  justify-self: right;
}
