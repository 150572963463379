.modal-amend-order {
  z-index: 6;
  .ln-c-modal__body-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ln-c-modal__body {
    width: 100%;
    max-width: 600px;
    text-align: left;
    padding: 24px;
    position: fixed;

    @include ln-respond-to("max-sm") {
      padding: $ln-space-x2;
      bottom: 0;
      text-align: center;
    }
  }

  .ln-c-modal__heading {
    max-width: 100%;
  }

  .ln-c-button-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    padding-top: 0;
    justify-content: center;

    .ln-c-button--text {
      color: $ln-color-dark;
    }

    @include ln-respond-to("max-sm") {
      padding: $ln-space;
      flex-direction: column;
    }
  }

  .ln-c-button-group__secondary {
    display: none;
    @include ln-respond-to("max-sm") {
      display: flex;
      flex-direction: column;
    }
  }

  .ln-c-button-group__primary {
    width: 100%;
    max-width: 288px;

    @include ln-respond-to("max-sm") {
      width: 100%;
      max-width: 500px;
    }

    .ln-c-button {
      margin-top: $ln-space;
      width: 100%;
      max-width: 288px;

      @include ln-respond-to("max-sm") {
        width: 100%;
        max-width: 500px;
        margin: 0;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &--delivery-time {
      font-family: $ln-font-family-brand;
      display: block;
      font-size: 18px;
    }

    &--text {
      display: block;
      font-size: 14px;
    }

    &--item {
      display: flex;
      margin-bottom: $ln-space-x2;

      svg {
        margin-right: 12px;
        min-width: $ln-space-x3;
        min-height: $ln-space-x3;
        position: relative;
        bottom: 2px;
      }
    }
  }

  .ln-c-dismiss {
    @include ln-respond-to("max-sm") {
      display: none;
    }
  }
}

.modal-width-500 {
  .ln-c-modal__body {
    width: 100%;
    max-width: 500px;
  }
}

.modal-hide-secondary-button {
  .ln-c-button-group__primary {
    .ln-c-button {
      width: 209px;
    }
  }

  .ln-c-button-group__secondary {
    display: none;
  }
}
