.checkout {
  .checkout-error {
    border: 2px solid $ln-color-red;
  }
}

.page-layout-right--checkout {
  --offset: 105px;
}

.ln-c-accordion {
  margin: 0;

  &__title {
    padding: $ln-space-x3;

    @include ln-respond-to("max-sm") {
      padding: $ln-space $ln-space-x2;
    }
  }

  &__icon {
    top: 42px;

    @include ln-respond-to("max-sm") {
      top: 20px;
    }
  }
}

.ln-c-accordion__title {
  color: $ln-color-text-dark !important;
}

.accordion__header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  font-size: $ln-space-x2;

  @include ln-respond-to("max-sm") {
    justify-content: flex-start;
  }

  .nectar__title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    line-height: 1.2;
  }

  .coupons__title {
    flex-grow: 1;
  }

  h2,
  h3,
  h4 {
    margin: auto 0;
  }
}

.coupons {
  padding: 0;
  .checkout-error {
    .ln-c-accordion__head {
      border-top: 2px solid $ln-color-red;
      border-bottom: 2px solid $ln-color-red;
    }
    &.is-open {
      .ln-c-accordion__head {
        border-bottom: 0;
      }
      .ln-c-accordion__body {
        border-bottom: 2px solid $ln-color-red;
      }
    }
  }

  &__info {
    @include ln-font-size("caption");
    display: flex;
    align-items: center;
    margin-top: $ln-space-x2;
    color: $ln-color-text-dark;
    font-weight: $ln-font-weight-bold;

    @include ln-respond-to("xs") {
      @include ln-font-size("body-1");
    }

    span {
      margin-left: $ln-space;
    }
  }

  &__added {
    &-heading {
      @include ln-font-size("body-2");
      display: block;
      margin-top: $ln-space-x2;
      font-family: $ln-font-family-brand;
      font-weight: $ln-font-weight-bold;
    }
  }

  &__coupon {
    display: grid;
    margin-top: $ln-space-x2;
    border: 1px solid $ln-color-grey-light;
    box-sizing: border-box;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
    border-radius: 10px;

    &-heading {
      font-weight: $ln-font-weight-bold;

      &-wrapper {
        display: flex;
        justify-content: space-between;
      }
    }

    &-button {
      background-color: unset;
      text-decoration: underline;
    }

    &-button:hover,
    button:focus {
      color: $ln-color-orange-dark;
    }

    &-savings {
      font-weight: $ln-font-weight-bold;
      font-size: 26px;
      line-height: 30px;
      color: $ln-color-orange;
    }
  }

  &__button {
    width: 100%;
    margin-top: $ln-space-x2;

    @include ln-respond-to("max-xs") {
      width: 100%;
      margin-right: $ln-space-x2;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;

      div {
        width: 324px;
        @include ln-respond-to("max-sm") {
          width: 100%;
        }
      }

      @include ln-respond-to("max-sm") {
        flex-direction: column;
      }
    }
  }

  .ln-c-accordion {
    &.is-open .ln-c-accordion__body {
      padding-top: 0;
      @include ln-respond-to("min-xs") {
        margin-top: -8px;
      }
    }
  }
}

.nectar {
  padding: 0;

  .nectar-logo {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: $ln-space-x2;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__details {
    margin-bottom: 0;
    margin-right: $ln-space-x2;
    font-family: $ln-font-family-base;
    font-weight: $ln-font-weight-bold;
  }

  h2,
  h3,
  h4 {
    margin: 0;
    line-height: 1.2;
  }

  &__highlight {
    color: #6c18cb;
  }

  &__info {
    display: flex;
    align-items: center;
    margin: $ln-space-x2 0 0 0;
    color: $ln-color-text-dark;
    font-family: unset;
    &-text {
      @include ln-font-size("caption");
      display: block;
      margin-left: $ln-space;
      font-weight: $ln-font-weight-bold;

      @include ln-respond-to("xs") {
        @include ln-font-size("body-1");
      }
    }
  }

  .ln-c-accordion {
    &.is-open .ln-c-accordion__body {
      margin-top: -8px;
      padding-top: 0;
    }
    & .coupons__coupon-savings {
      color: #8223fa;
    }
  }

  &__button {
    width: 324px;

    @include ln-respond-to("max-xs") {
      width: 100%;
      margin-right: $ln-space-x2;
    }
  }
}

.payment {
  & .inline-error-no-background {
    margin-top: $ln-space-x2;
    padding: 0;
  }

  & .card-verify-details {
    margin-top: 16px;
  }

  &__form-group {
    display: flex;
    flex-direction: column;

    & #form-content > :last-child {
      margin-bottom: 0 !important;
    }
  }

  .ln-c-input-group {
    display: flex;
    flex-direction: column;
  }

  #card-verification-img {
    margin: 0.5rem 0;
  }

  &__button--primary {
    width: 324px;

    @include ln-respond-to("max-xs") {
      width: 100%;
      margin-right: $ln-space-x2;
    }

    &--container {
      width: fit-content;
      @include ln-respond-to("max-xs") {
        width: 100%;
      }
    }
  }

  &__radio-options {
    width: 100%;
    margin-bottom: 1rem;

    @include ln-respond-to("xs") {
      max-width: 361px;
    }

    &-label {
      display: flex;
      min-height: 52px;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.colleague-discount {
  background-color: #e8f1fb;
  border: 1px solid #135ca3;
  padding: 12px 18px 12px 18px;

  &:before {
    left: 0;
  }

  svg {
    margin-right: $ln-space-x2;
    float: left;
    color: #1976d2;
    font-size: 18px;
  }

  &__text {
    display: table-cell;
    color: #135ca3;
  }
}

.checkout__empty {
  .trolley__empty {
    margin-top: 122px;
  }
}

.trolley__subs-promise {
  margin-bottom: 0.5em;
  font-weight: $ln-font-weight-bold;
}

.trolley__subs-list {
  margin-bottom: 0;
}

.order-summary__loader-message {
  font-family: MaryAnn, serif;
  font-weight: $ln-font-weight-bold;
  font-size: 18px;
}

.payment-date-info {
  margin-top: 1rem;
  color: $dark-grey-hex;
  background: #e8f1fb;
  padding: 10px;
  display: flex;
  svg {
    margin-right: 0.5rem;
  }
  p {
    margin: 0;
  }
}
