.pd__clickable-modal {
  .ln-c-modal__body-wrapper {
    height: calc(100% - 64px);
    width: calc(100% - 64px);
  }

  .ln-c-modal__body {
    height: 100%;
    max-width: unset;
  }

  &__caption_container {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  &__caption {
    text-align: center;
    margin-top: $ln-space;
    max-width: 500px;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    margin-top: 64px;
    margin-bottom: 64px;

    &-zoomed {
      display: block;
    }
  }

  &__content-left {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100px;
    padding-top: 1px;
  }

  &__content-right {
    flex-shrink: 0;
    width: 100px;
  }

  &__content__hidden {
    display: none;
  }

  &__image-wrapper {
    width: 100%;
  }

  &__image {
    margin: auto;
    display: block;
    max-height: 100%;
    max-width: 100%;

    &__not-zoomed {
      cursor: zoom-in;
    }

    &__zoomed {
      cursor: zoom-out;
      width: 100%;
      max-height: max-content;
    }
  }

  &__image_caption {
    max-height: calc(100% - 144px);
  }

  &__additional-image {
    width: 50px;
    min-height: 50px;
    max-height: 50px;
    cursor: pointer;
    margin-left: 27px;
    margin-bottom: 16px;
    object-fit: contain;
    border: 1px #8e8e8e solid;

    &__active {
      border: 1px solid $ln-color-orange;
    }
  }
}
