.book-slot {
  &__title {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 12px;

    @include ln-respond-to("sm") {
      font-size: 2.5rem;
      margin-bottom: calc(#{$ln-space} * 2.5);
    }

    @include ln-respond-to("max-sm") {
      padding-top: 1rem;
    }
  }
}
