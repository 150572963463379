.pr-success__h1 {
  margin-bottom: $ln-space-x3;

  @include ln-font("display-3");

  @include ln-respond-to("sm") {
    margin-bottom: $ln-space-x6;

    @include ln-font("display-4");
  }
}

.pr-success-container {
  max-width: 35.625rem;
}

.pr-success-logos {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: $ln-space-x3;
  margin-top: $ln-space-x3;

  @include ln-respond-to("sm") {
    margin-top: 0;
  }
}

.pr-success-logo__sainsburys {
  margin-right: $ln-space-x2;
  max-width: 119px;
  min-width: 100px;
  width: calc(100% - 320px);

  @include ln-respond-to("xs") {
    margin-right: $ln-space-x4;
  }
}

.pr-success-logo__nectar {
  width: calc(100% - 380px);
  max-width: 55px;
  min-width: 40px;
}

.pr-success__text {
  font-family: $ln-font-family-base;
  margin-bottom: $ln-space-x4;
  text-align: center;

  @include ln-respond-to("sm") {
    margin-bottom: $ln-space-x5;
    padding: 0 calc(#{$ln-space} * 3.5);
  }
}

.pr-success__button {
  width: 100%;
}
