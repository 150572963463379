.no-orders {
  &__description {
    text-align: center;
  }

  &__image {
    height: 75px;
    width: 113px;
  }

  &__button {
    @include ln-respond-to("xs") {
      max-width: 290px;
    }
  }
}
