@mixin recipes-pill-button-on-hover($baseColor) {
  &:hover {
    background: mix($baseColor, #000, 92%);
  }
}
.recipes-pill {
  display: inline-block;
  border: solid 1px transparent;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin: 1rem 1rem 0 0;
  background-color: $ln-color-grey-v-light;
  color: $ln-color-grey-v-dark;
  font-weight: bold;

  &.clickable {
    cursor: pointer;

    &:hover {
      @include recipes-pill-button-on-hover(transparent);
    }
  }
}
