.FetchSlotFallback {
  margin: 50px 0px;

  @include ln-respond-to("min-sm") {
    margin: 0px 0px 50px 0px;
  }

  @include ln-respond-to("min-md") {
    margin: 0px 0px 100px 0px;
  }
}
