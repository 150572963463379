.recurring-slot-preference {
  max-width: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .ln-c-button--outlined {
    @include ln-respond-to("min-sm") {
      width: 70%;
    }
  }

  h1 {
    text-align: center;
  }

  span {
    font-weight: bold;
  }

  .my-recurring-slot {
    margin-bottom: 15px;

    .ln-c-button {
      width: 50%;

      @include ln-respond-to("max-sm") {
        width: 100%;
      }
    }
  }

  .form {
    padding-top: $ln-space-x4;
    width: 100%;

    a {
      width: 100%;
    }

    @include ln-respond-to("min-sm") {
      padding-left: $ln-space-x8;
      padding-right: $ln-space-x8;
    }
  }

  .img-van {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: $ln-space-x4;

    img {
      width: 205px;
      height: 94px;
    }
  }

  .ln-c-card {
    margin-top: 5px;
    width: 100%;
    text-align: left;
  }

  .book-card {
    text-align: center;
  }

  .confirmed-card {
    margin-top: 5px;
    width: 100%;

    p {
      margin: 0;
      padding: 1px;
    }

    ul {
      margin-top: $ln-space-x2;
    }

    &__nickname {
      font-weight: bold;
    }

    &__delivery-notes {
      padding-top: $ln-space;
      color: #8e8e8e;
    }

    &__confirmation-address {
      padding-top: $ln-space-x4;
    }
  }

  .terms_conditions {
    margin-top: $ln-space-x2;
  }

  .continue-shopping {
    padding-top: $ln-space-x4;
    width: 90%;

    a {
      width: 100%;
    }

    @include ln-respond-to("min-sm") {
      padding-left: $ln-space-x8;
      padding-right: $ln-space-x8;
    }
  }
}
