.order {
  padding-bottom: 0;

  @include ln-respond-to("xs") {
    padding-bottom: calc(#{$ln-space} * 2);
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-bottom: $ln-space;

    @include ln-respond-to("xs") {
      flex-direction: row;
    }

    &-title {
      font-weight: $ln-font-weight-bold;
      margin-bottom: 0;
    }

    &-text {
      margin-bottom: $ln-space;
      display: block;

      @include ln-respond-to("xs") {
        margin-bottom: calc(#{$ln-space} * 2);
      }
    }
  }

  &__order-number-container {
    min-width: 110px;

    @include ln-respond-to("xs") {
      margin-right: calc(#{$ln-space} * 4);
    }
  }

  &__address-container {
    flex-grow: 1;
  }

  &__total-container {
    min-width: 110px;
  }

  &__cutoff {
    &-message-wrapper {
      width: 100%;
      margin-bottom: calc(#{$ln-space} * 2);

      @include ln-respond-to("xs") {
        width: 170px;
        margin-right: calc(#{$ln-space} * 2);
        margin-bottom: 0;
      }
    }

    &-message {
      margin-bottom: calc(#{$ln-space} * 2);

      @include ln-respond-to("xs") {
        margin-bottom: 0;
      }
    }
  }

  &__cancel-order {
    &-button-wrapper {
      width: 100%;
      margin-bottom: calc(#{$ln-space} * 2);

      @include ln-respond-to("xs") {
        width: 170px;
        margin-bottom: 0;
      }
    }

    &-button {
      width: 170px;
      margin-bottom: 0px;

      @include ln-respond-to("max-xs") {
        max-width: 100%;
        margin-bottom: calc(#{$ln-space} * 2);
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include ln-respond-to("xs") {
      flex-direction: row;
      margin-bottom: calc(#{$ln-space} * 1.5);
    }

    &-button-wrapper {
      width: 100%;

      @include ln-respond-to("xs") {
        width: 170px;
        margin-bottom: 0;
        margin-right: calc(#{$ln-space} * 2);
      }
    }

    &-button {
      width: 100%;
      margin-bottom: calc(#{$ln-space} * 2);

      @include ln-respond-to("xs") {
        width: 170px;
        margin-bottom: 0;
        margin-right: calc(#{$ln-space} * 2);
      }
    }
  }
}
