header {
  .header__accessible-skipto {
    font-size: 20px;
    line-height: 1.4;
    background-color: $ln-color-orange;
    left: 0;
    top: -47px;
    padding: 6px 12px;
    position: absolute;
    color: $ln-color-white;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: top 0.3s;

    &:focus {
      top: 0;
    }
  }
}
