.book-slot {
  margin-top: 15px;

  @include ln-respond-to("max-sm") {
    &__cnc-promo-switch-link {
      text-decoration: none;
    }
  }

  &__cnc-promo-switch-link-button {
    text-decoration: none;
    width: 100%;
  }

  &__title-container {
    padding-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("max-sm") {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: -2rem;
    }
  }

  &__link:hover {
    color: $ln-color-orange;
  }

  &__grid-container {
    position: relative;
    z-index: 0; // prevents stuff overlapping mobile header menu
    padding-bottom: 0;
  }

  &__below-grid {
    position: relative;
    padding-top: $ln-space-x3;
    padding-bottom: 0;
    background-color: $ln-color-grey-v-light;
    z-index: 5;

    @include ln-respond-to("max-sm") {
      text-align: center;
    }

    .green-delivery-slot {
      &__link {
        color: $ln-color-success;
      }
    }
  }

  &__green-van {
    padding-bottom: $ln-space-x2;
    @include ln-respond-to("max-sm") {
      display: none;
    }

    @include ln-respond-to("md") {
      float: left;
    }
  }

  &__continue {
    @include ln-respond-to("sm") {
      float: left;
    }

    @include ln-respond-to("md") {
      float: right;
    }
  }

  &__continue-btn {
    @include ln-respond-to("max-sm") {
      width: 288px;
    }

    @include ln-respond-to("sm") {
      float: left;
      width: 256px;
    }

    @include ln-respond-to("md") {
      float: right;
      width: 256px;
    }
  }

  &__no-slots-image {
    margin: 43px 0 33px 0;
  }

  &__cnc-promo {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px;
    align-items: center;

    &-icon {
      color: $ln-color-orange;
      font-size: 22px;

      @include ln-respond-to("max-md") {
        font-size: 27px;
      }
    }

    &-bold {
      font-weight: $ln-font-weight-bold;
    }

    &-icon-text {
      margin-right: $ln-space-x2;
      margin-left: $ln-space;
      color: $ln-color-orange;
      font-size: 1.12rem;
      font-family: MaryAnn, "Trebuchet MS", sans-serif;
      font-weight: bold;

      @include ln-respond-to("md") {
        font-size: 1.3rem;
      }

      @include ln-respond-to("max-sm") {
        font-size: 22px;
      }
    }

    &-text-container {
      font-size: 1rem;
      margin-top: 4px;
      display: flex;
      column-gap: 5px;
      align-items: baseline;
      flex-wrap: wrap;

      @include ln-respond-to("max-sm") {
        line-height: 25px;
        row-gap: 5px;

        &-first-row {
          column-gap: 5px;
          display: flex;
          align-items: baseline;
        }
      }

      @include ln-respond-to("md") {
        font-size: 1.12rem;
      }

      &--text {
        font-size: inherit;
        color: $ln-color-dark;
        font-family: Arial, Helvetica, sans-serif;

        @include ln-respond-to("max-sm") {
          font-size: inherit;
        }
      }
    }
  }

  &__cnc-promo-xs {
    background-color: white;
    width: 100%;
    padding: $ln-space-x2;
    margin-bottom: var(--ds-size-space-6);

    &-info {
      float: left;
      width: 50%;
      text-align: left;
      padding-right: 5px;

      @include ln-respond-to("max-xs") {
        width: 100%;
      }
    }

    &-icon-text {
      @include ln-respond-to("max-xs") {
        font-size: 22px !important;
      }
    }

    &-btn {
      margin: 16px 0 16px 0;
      max-width: 233px;
      width: 50%;
      font-size: 18px;

      @include ln-respond-to("max-xs") {
        width: 100%;
        max-width: none;
      }
    }
  }

  // This alert styling is available in @jsluna/alert, unable to install due to out of date peer dep (react v16, requires react v17)
  &__alert {
    padding: 0.5rem 1rem;
    &-wrapper {
      align-items: center;
      display: flex;

      @include ln-respond-to("max-md") {
        align-items: flex-start;
        text-align: left;
      }

      .alert-icon-wrapper {
        .alert-icon {
          display: block;
          margin: 4px 8px 4px 0px;
        }
      }

      .alert-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include ln-respond-to("max-md") {
          flex-direction: column;
          align-items: flex-start;
        }

        .alert-cnc-button {
          margin-left: 12px;
          color: #ffffff;
          border-color: #ffffff;

          @include ln-respond-to("max-md") {
            margin-left: 0px;
            margin-top: 8px;
          }

          .click-and-collect-icon {
            margin-right: 12px;
            vertical-align: top;
          }
        }
      }
    }
  }
}
