.cnc-booking-confirmation {
  @include ln-respond-to("sm") {
    margin-top: 42px;
  }

  @include ln-respond-to("md") {
    margin-top: 20px;
  }

  &__title {
    margin: 0 auto 17px 9px;
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 17px;

    @include ln-respond-to("sm") {
      font-size: 2.5rem;
    }

    &--without-magnolia {
      margin: 16px auto 32px;
      width: 100%;

      @include ln-respond-to("sm") {
        margin-bottom: 52px;
      }
    }
  }

  &__section {
    background: $ln-color-white;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    margin-bottom: $ln-space;
    max-width: 688px;
    padding: 16px;
    width: 100%;
  }

  &__image-container {
    align-items: center;
    text-align: center;
  }

  &__image {
    margin: 30px 0;
    width: 200px;
  }

  &__checkout_warning {
    width: 100%;
    background: #e7f1fe;
    padding: 10px;
  }

  &__expiry-time {
    font-weight: bold;
  }

  &__subtitle {
    display: block;
    font-family: MaryAnn-ExtraBold, "Trebuchet MS", sans-serif;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 14px;
  }

  &__text {
    margin-bottom: 8px;
    font-weight: bold;
  }

  &__text-price {
    margin-bottom: 8px;
  }

  &__text-info {
    margin-bottom: 15px;
  }

  &__slot_date {
    font-weight: bold;
    margin-bottom: 0;
  }

  &__delivery_cost_title {
    font-weight: bold;
  }

  &__terms {
    color: $ln-color-grey;
    font-size: 14px;

    p {
      font-size: 14px !important;
    }
  }

  &__link-container {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: $ln-font-weight-bold;
    color: $ln-color-orange;
    font-family: $ln-font-family-brand;
  }

  &__icon {
    color: $ln-color-green;
    margin-left: 12px;
  }

  &__icon-small {
    color: $ln-color-green;
    margin-left: 5px;
    padding-bottom: 2px;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    max-width: 688px;
    width: 100%;
    margin: $ln-space 0 60px;

    &--without_checkout_btn {
      margin: $ln-space auto 60px;
    }

    @include ln-respond-to("xs") {
      flex-direction: row;
      margin-top: calc(#{$ln-space} * 0.25);
      gap: 16px;
      padding-bottom: 0;
    }

    @include ln-respond-to("md") {
      margin-top: calc(#{$ln-space} * 2);
    }

    &__wrapper {
      display: flex;
      justify-content: center;
      margin: 0;
      flex: 1;
      width: 100%;
    }

    /* Handle scenarios where only one button is present */
    &:not(:has(:nth-of-type(2))) {
      max-width: 288px; /* Limit max-width to 288px for single button case */
    }
  }

  &__button {
    margin: 0;
    width: 100%;
  }

  &__bullet-points {
    margin-bottom: 0;
  }

  &__text-info.booking-confirmation__terms {
    color: #4c4c4c;
  }

  &__magnolia {
    width: 100%;
    @include ln-respond-to("xs") {
      max-width: 320px;
    }

    @include ln-respond-to("lg") {
      max-width: 720px;
    }

    // Hard patch until we bump the bolt && bolt-cms-components component (which requires upgrade to React 18)
    > div > div {
      margin-left: 0;
      max-width: 100%;
    }
  }
}
