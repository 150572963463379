.mp-pageBody {
  width: 100%;
  padding: $ln-space-x4;
  background-color: $ln-color-white;
  color: $ln-color-black;
  text-align: center;
  padding-top: $ln-space-x8;
  padding-bottom: $ln-space-x8;

  &__paragraph {
    margin: auto;
    width: 45%;
  }

  &__paragraph,
  &__paragraph * {
    @include ln-font("body-2");
    @include ln-respond-to("max-sm") {
      @include ln-font("caption");
    }
  }
}

.mp-container {
  @include ln-respond-to("max-sm") {
    padding-bottom: 55px;
  }
}

.mph-pageFooter {
  position: absolute;
  left: 0px;
  width: 100vw;
  padding: 2rem;
  background-color: $ln-color-white;
  color: black;

  &__paragraph {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include ln-respond-to("max-sm") {
      flex-direction: column;
    }
    @include ln-respond-to("min-xl") {
      margin: auto;
      width: 86rem;
    }
  }

  &__price {
    margin: 0 0 5px 0;
    display: inline-block;
    @include ln-respond-to("min-sm") {
      @include ln-font("display-2");
      margin-right: $ln-space-x2;
    }
    @include ln-respond-to("max-sm") {
      flex-direction: row;
    }
  }

  p {
    display: inline-block;
    @include ln-respond-to("max-sm") {
      flex-direction: row;
      margin-left: 10px;
    }
  }
}
