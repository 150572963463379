.offers {
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 20px;

  @include ln-respond-to("max-sm") {
    margin-top: 10px;
  }

  &__title-container {
    margin-top: 9px;
    background-color: #ffffff;
  }
  &__title {
    font-size: 2.5rem;
    font-weight: 800;
    padding-top: 11px;
    padding-bottom: 28px;
    margin-bottom: 0px;
  }
}

.leftpadding-login {
  margin-top: 116px;
  margin-bottom: 16px;

  @include ln-respond-to("sm") {
    margin-top: 184px;
  }
  @include ln-respond-to("md") {
    margin-top: 218px;
  }
  @include ln-respond-to("lg") {
    margin-top: 221px;
  }
}

.leftpadding-login-out {
  margin-top: 149px;
  margin-bottom: 16px;

  @include ln-respond-to("sm") {
    margin-top: 173px;
  }
  @include ln-respond-to("md") {
    margin-top: 216px;
  }
  @include ln-respond-to("lg") {
    margin-top: 219px;
  }
}

.padding-bottom-offers {
  padding-bottom: 1.5rem;
}

.offers-zonal-sort-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-basis: auto;
  margin-bottom: 24px;

  &__label {
    font-family: "MaryAnn";
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $dark-grey-hex;
    margin-right: 15px;
  }

  &__select-field {
    margin: 0;
  }
}
