.basic-modal {
  .ln-c-modal {
    &__body {
      text-align: center;
      width: 288px;

      @include ln-respond-to("xs") {
        width: 447px;
      }

      @include ln-respond-to("sm") {
        width: 460px;
      }
    }

    &__scroll-area {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    font-weight: 800;
  }

  &__primary-button {
    width: 188px;

    @include ln-respond-to("xs") {
      width: 198px;
    }
  }

  &__secondary-button {
    display: block;
    margin-top: $ln-space;
  }
}

.reserve-slot-modal.ln-c-modal {
  flex-direction: column-reverse;
  justify-content: normal;
  z-index: 6;
}

.reserve-slot-modal {
  padding: 0;

  .ln-c-modal {
    &__body-wrapper {
      margin: 0;
      max-width: unset;
      padding: 0;
      text-align: center;
      width: 100%;
    }

    &__body {
      max-width: unset;
      @include ln-respond-to("max-sm") {
        padding: $ln-space-x2;
      }
    }

    &__scroll-area {
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
      @include ln-respond-to("sm") {
        padding-bottom: $ln-space-x2;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-family: Arial, serif;
    font-size: 18px;
    font-weight: 700;
    margin: 16px 0;
    white-space: pre;
    width: 235px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p {
      margin: 5px;
    }
  }

  &__message {
    font-size: 14px;
    line-height: 22px;
    max-width: 687px;
    color: #4c4c4c;
  }

  &__price {
    font-size: 18px;
    line-height: 26px;
  }

  &__error {
    color: #d83e34;
    max-width: 687px;
  }

  &__primary-button,
  &__secondary-button {
    width: 288px;
    margin-top: $ln-space-x2;

    @include ln-respond-to("sm") {
      width: 448px;
    }
    @include ln-respond-to("max-sm") {
      width: 100%;
    }
  }
}

.amend-order-modal {
  .ln-c-modal__body-wrapper {
    .ln-c-modal__body {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $ln-space-x3;

      @include ln-respond-to("sm") {
        position: relative;
      }
    }
    @include ln-respond-to("sm") {
      min-width: 600px;
    }
  }
}

.amend-order-modal {
  z-index: 6;
  display: flex;
  flex-direction: column;

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $ln-space;
    width: 100%;

    .ln-c-button--text {
      color: $ln-color-dark;
    }
  }

  &__confirm-button {
    width: 100%;

    @include ln-respond-to("sm") {
      max-width: 288px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &--delivery-time {
      font-family: $ln-font-family-brand;
      display: block;
      font-size: 18px;
    }

    &--text {
      display: block;
      font-size: 14px;
    }

    &--item {
      display: flex;
      margin-bottom: $ln-space-x2;

      svg {
        margin-right: 12px;
        min-width: $ln-space-x3;
        min-height: $ln-space-x3;
        position: relative;
        bottom: 2px;
      }
    }
  }
}

.amend-order-modal .ln-c-dismiss {
  display: none;
  @include ln-respond-to("sm") {
    display: block;
  }
}
