.payment-authorisation {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__iframe {
    border: 0;
    height: 460px;

    &-background {
      width: 100%;
      height: 460px;
      background-color: #fff;

      @include ln-respond-to("xs") {
        width: 500px;
      }
    }

    &-wrapper {
      display: flex;
      max-width: 500px;
      height: 460px;
      margin: 0 auto;
      width: 100%;
    }

    &-loading {
      height: 460px;
    }
  }

  &__security {
    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 64px;
    }

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-image {
      margin: 0 8px 24px 0;
    }

    &-questions {
      margin: 8px 0 16px 0;
      font-weight: bold;
      font-family: "Mary Ann", sans-serif;
      font-size: 20px;
    }
  }
}
