.bs {
  &__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $ln-color-white;
    min-height: 300px;
    .loading-content {
      width: 150px;
      height: 150px;
      display: block;
      opacity: 0.8;
      animation: fadelines 4s infinite;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@mixin respond-to-and-class($name, $previewName) {
  @include ln-respond-to($name) {
    &:not(&--preview) {
      @content;
    }
  }
  .citrus-preview-bp-#{$previewName} & {
    @content;
  }
}

@mixin medium-size() {
  flex-direction: row;
  .BrandPageBanner {
    &-image {
      min-width: 50%;
      // because of Citrus image size requirements works
      // but normally we'd prefer "contain"
      background-size: cover;
    }

    &-DoubleImage {
      &--portrait {
        @include enable-size-in-flex();
        min-width: 50%;
      }
      &--landscape {
        @include disable-size-in-flex();
      }
    }

    &-cta {
      .BrandPageBanner-copy {
        margin: $ln-space-x4 auto;
      }

      .BrandPageBanner-copy--body {
        font-size: 18px;
      }
    }

    &-roundel {
      position: static;
      margin: 24px auto 0 auto;
    }
    &-button {
      width: auto;
      max-width: 200px;
    }
  }
}

@mixin enable-size-in-flex {
  min-height: 50%;
  flex-grow: 2;
}

@mixin disable-size-in-flex {
  min-height: 0;
  flex-grow: 0;
}

.BrandPageBanner {
  // Mobile first

  font-family: $ln-font-family-brand;
  font-weight: $ln-font-weight-medium;

  width: 100%;
  display: flex;
  flex-direction: column;

  // allow some children to be absolutely positioned
  position: relative;

  // look clickable
  cursor: pointer;

  min-height: 300px;

  &-image,
  &-DoubleImage--portrait,
  &-DoubleImage--landscape {
    @include enable-size-in-flex();

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &-DoubleImage--landscape {
    @include disable-size-in-flex();
  }

  &-cta {
    width: 100%;
    text-align: center;
    padding: $ln-space-x2;
    padding-top: 0;

    .BrandPageBanner {
      &-copy {
        margin-bottom: calc(#{$ln-space}* 0.5);
        &--title,
        &--body {
          font-size: 26px;
          font-weight: $ln-font-weight-bold;
        }

        &--title {
          line-height: 32px;
        }

        &--body {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
        }
      }
      &-roundel {
        position: absolute;

        // Consistent with Magnolia ads
        top: 24px;
        left: 10%;

        // fix scrollbars without giving parent overflow: hidden
        bottom: 50%;
        right: 0;

        background-repeat: no-repeat;
        background-position: top left;

        width: 110px;
        height: 110px;
        border-radius: 50%;
        visibility: hidden;

        // Text inside
        span {
          font-size: 32px;
          color: white;
          font-weight: 700;
          line-height: 105px;
        }

        &--offer {
          background-color: #d61f26;
          visibility: visible;
        }

        &--new {
          background-color: #1976d2;
          visibility: visible;

          span {
            font-size: 40px;
          }
        }
      }
      &-button {
        max-width: 140px;
        margin: 0 auto;
        white-space: nowrap;
      }
    }
  }

  // Overrides for higher breakpoints

  // Above 479px (xs)
  @include respond-to-and-class("xs", "small") {
    .BrandPageBanner-DoubleImage--portrait {
      @include disable-size-in-flex();
    }

    .BrandPageBanner-DoubleImage--landscape {
      @include enable-size-in-flex();
    }
  }

  // All the changes above 600px and below 960px
  @include ln-respond-to("ss") {
    @include ln-respond-to("max-md") {
      &:not(&--preview) {
        @include medium-size();
      }
    }
  }

  .citrus-preview-bp-medium & {
    @include medium-size();
  }

  // All the changes above 960px
  @include respond-to-and-class("md", "large") {
    .BrandPageBanner-image {
      background-size: contain;
    }
  }
}
