$screen-break-xl: 1440px;

.mega-nav--column-wrapper {
  @apply ds-p-2 ds-overflow-y-auto;
  scrollbar-color: #d8d8d8 #f2f2f2;
  max-height: calc(100vh - 145px);
}

.mega-nav--column-wrapper:has(*) {
  @apply ds-border-r ds-border-r-grey-300;
  border-right-style: solid;
}

// when the column is filled with category content, make the background lighter
.mega-nav--column-wrapper:has(div.mega-nav__column) {
  background-color: $ds-color-alias-component-background-light;
}

// Specifics for the CMS column
.mega-nav--column-wrapper#mega-nav--column-3 {
  @apply ds-overflow-y-auto ds-border-none;
}

.mega-nav--cms-wrapper {
  @apply ds-w-full md:ds-max-w-75 ds-p-4 md:ds-p-0 md:ds-mx-auto;
  @media (min-width: $screen-break-xl) {
    margin-left: var(--ds-size-space-4);
  }
}

// do not apply any background to the list items in the content
.mega-nav__item-content {
  background-color: unset;
}

body:has(.mega-nav--backdrop) {
  overflow: hidden;
}

.mega-nav {
  &--backdrop {
    position: fixed;
    left: 0;
    width: 100vw;
    height: calc(100vh - var(--mega-nav-top-position, 0px));
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    display: flex;
    justify-content: center;
    overflow: visible;
    top: var(--mega-nav-top-position, 0);
  }

  width: calc(100% - 40px);
  max-width: $ds-size-breakpoint-max-width-lg;
  height: fit-content;
  max-height: calc(100vh - var(--mega-nav-scroll-offset, 0px) - 40px);
  background-color: $ds-color-alias-body-background-light;
  margin: 0 auto;
  position: relative;

  @media (min-width: $screen-break-xl) {
    max-width: $screen-break-xl;
    margin: 0;
    padding: 0;
  }

  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  position: relative;

  &--content {
    width: 100%;
    height: 100%;
    background-color: $ds-color-alias-body-background-light;
    display: grid;
    grid-template-columns: 33% 33% 34%;
  }

  &__column {
    width: 100%;
    overflow-y: auto;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    height: 48px;
    cursor: pointer;
    color: var(--ds-color-content-grey-darker);

    &:hover {
      color: var(--ds-color-alias-cta-secondary-hover-text);
    }

    &--selected {
      background-color: #f2f2f2;
    }

    &-content {
      height: 100%;
      width: 100%;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: inherit;
      text-align: left;
    }

    &-text {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      flex-shrink: 0;
      color: var(--ds-color-content-grey-darker);
      margin-left: 14px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    font-family: var(--ds-font-family-brand);
    position: sticky;
    top: 0;
    z-index: 1;

    .mega-nav__item-content {
      height: 48px;

      > span {
        font-weight: bold;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    grid-column: 1 / -1;
  }

  @media (max-width: 1024px) {
    &__column {
      min-width: 0;
    }
  }

  @media (max-width: 719px) {
    &-container,
    &-wrapper,
    & {
      display: none;
    }
  }
}
