.pd__slidable-modal {
  .ln-c-modal__body {
    height: auto;
    overflow: hidden;
    max-width: calc(100% - 16px);
  }

  &__wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__heading {
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
  }
}
