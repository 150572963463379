@import "../CYSM.scss";

.CYSMCard {
  display: flex;
  flex-direction: column;
  $left-margin-icon: 38px;

  &__icon {
    margin: 0px 10px 0px 0px;
    color: $ln-color-orange;
    width: 30px;
    height: 24px;
    position: absolute;
  }

  &__icon-margin {
    margin-left: 0px;
  }

  &__heading {
    @extend .CYSMCard__icon-margin;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    margin-left: $left-margin-icon;
  }

  &__subHeading {
    @extend .CYSMCard__icon-margin;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-family: $ln-font-family-base;

    margin-bottom: 16px;
  }

  &__paragraph {
    @extend .CYSMCard__icon-margin;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__asteriskText {
    $asteriskTextHeight: 22px;

    @extend .CYSMCard__icon-margin;
    font-size: 14px;
    line-height: $asteriskTextHeight;
    margin-bottom: 0px;

    &--loading {
      @extend .CYSMCard__asteriskText;
      max-width: 180px;
      margin-top: 0px;
      height: $asteriskTextHeight;
    }
  }

  &__learnMoreLink {
    font-size: 16px;
    line-height: 24px;
    display: inline-flex;
  }

  &__linkContainer {
    @extend .CYSMCard__icon-margin;
    display: flex;
    row-gap: 12px;
    column-gap: 40px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  @include ln-respond-to("sm") {
    &__icon-margin {
      margin-left: $left-margin-icon;
    }
  }

  @include ln-respond-to("md") {
    $no-of-columns: 2;
    $half-gap-size: calc($CYSM-card-gap-desktop / $no-of-columns);
    flex-basis: calc(50% - $half-gap-size);
  }
}
