.CYSMAddressDetails {
  &__postcode {
    font-weight: 700;
    display: inline-block;
  }

  &__changeAddress {
    display: block;
  }
}
