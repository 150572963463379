.pageHeaderLeftAligned {
  display: flex;
  flex-direction: column;

  margin-left: -16px;
  margin-right: -16px;

  @include ln-respond-to("sm") {
    margin-left: -24px;
    margin-right: -24px;
  }

  // not using ln-respond-to("xxl") because there is a small part when the heading is longer than the product grid, and then it jumps to being equal
  @media (min-width: 1500px) {
    margin-left: 0;
    margin-right: 0;
  }

  margin-top: $ln-space;
  &--hasMarginTop {
    margin-top: 4px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__subheading {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
  }

  &__paragraph {
    padding: 0;
    p {
      margin-bottom: 16px;
    }
  }

  &__breadCrumb {
    color: rgb(240, 108, 0);
  }

  .multi-search-tabs,
  .multi-search-tab__list,
  .browse-pill-scroll-wrapper,
  .browse-pill-list,
  .fav-pill-list,
  .offer-pill-list,
  .offer-pills {
    justify-content: left;
    margin-bottom: 0;
    padding: 0;
  }

  .btn-go-back {
    margin-bottom: 24px;
  }
}
