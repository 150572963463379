.previous-orders-dropdown {
  width: 287px;
  display: inline-block;

  @include ln-respond-to("ss") {
    width: 264px;
  }
}

.leftpadding {
  margin-top: 116px;

  @include ln-respond-to("sm") {
    margin-top: 184px;
  }
  @include ln-respond-to("md") {
    margin-top: 218px;
  }
  @include ln-respond-to("lg") {
    margin-top: 221px;
  }
}

.bottom-pad {
  margin-bottom: 16px;
}

.previous-orders {
  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 24px;

    @include ln-respond-to("ss") {
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &__loading-icon-container {
    background-color: $ln-color-orange;
    width: 100%;
  }

  &__add-all-button,
  &__loading-icon-container {
    align-self: center;
    max-width: 287px;

    @include ln-respond-to("ss") {
      align-self: flex-end;
      max-width: 264px;
      margin-left: 40px;
    }
  }
}

.previous-orders-no-data {
  margin: 0 auto;
  max-width: 570px;
  text-align: center;

  .previous-orders-no-data__inner {
    margin: 0 auto;
    width: 290px;
  }

  .previous-orders-no-data__image {
    margin: 85px 0 75px 0;
  }
}
