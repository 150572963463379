.slot-grid-controls {
  display: flex;
  margin: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @include ln-respond-to("md") {
    justify-content: space-between;
    min-height: 48px;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.25%;

    @include ln-respond-to("sm") {
      justify-content: space-between;
    }
  }

  &__arrow {
    background: transparent;
    border: solid 1px $ln-color-grey;
    border-radius: 2px;
    color: rgba($ln-color-black, 0.75);
    font-family: $ln-font-family-base;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: 48px;
    letter-spacing: normal;
    line-height: 1.5;
    padding: 12px 12px 12px 18px;
    position: relative;
    text-align: center;
    width: 104px;

    &:hover,
    &:focus {
      background-color: rgba($ln-color-black, 0.08);
      color: $ln-color-black;
    }

    .ln-c-icon {
      left: 5px;
      position: absolute;
      top: 13px;
    }

    &--right {
      padding: 12px 18px 12px 12px;

      .ln-c-icon {
        left: auto;
        right: 5px;
      }
    }

    &--right,
    &--right-xmas {
      span {
        padding-right: 5px;
      }
    }

    &--left-xmas,
    &--right-xmas {
      width: 139px;
      min-width: fit-content;
    }

    &--left-xmas {
      padding: 12px 12px 12px 0px;
      .ln-c-icon {
        right: 0;
        left: 0;
        position: relative;
        top: -2px;
      }
    }

    &--right-xmas {
      padding: 12px 18px 12px 12px;
      .ln-c-icon {
        left: auto;
        right: 5px;
      }
    }
  }
}
