.grid-sticky-header {
  display: flex;
  background-color: $ln-color-light;
  height: 80px;
  z-index: 4;
  padding-left: 131px;
  opacity: 0;
  width: calc(100vw - 3rem);
  max-width: 90rem;

  @include ln-respond-to("md") {
    top: 222px;
  }

  &__label {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;

    @include ln-respond-to("md") {
      flex: 5;
    }
  }

  &__visible {
    animation: fadein 0.2s 1 forwards;
  }

  &__hidden {
    animation: fadeout 0.2s 1 forwards;
    pointer-events: none;
  }
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 1.5rem;
    font-weight: 800;

    @include ln-respond-to("sm") {
      font-size: 2.5rem;
      margin-top: calc(#{$ln-space} * 5);
    }
  }

  &__description {
    max-width: 672px;

    @include ln-respond-to("sm") {
      margin: calc(#{$ln-space} * 2);
      max-width: 702px;
    }
  }

  &__button {
    margin-top: 2rem;
    font-weight: 800;
    width: 288px;

    &-secondary {
      margin-top: 0.5rem;
    }
  }
}
