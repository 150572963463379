.delivery-pass-renewal-options {
  max-width: 960px !important;
  margin: 0 auto;
  margin-bottom: -5rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
  }

  .pass-toggle-btn {
    width: 100%;
    display: block;
    background: $ln-color-white;
    border: 3px solid $ln-color-grey-light;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.5rem;
    text-decoration: none;
    text-align: center;
  }

  .pass-toggle-btn:focus,
  .pass-toggle-btn:hover {
    border-color: $ln-color-orange;
    color: $ln-color-grey-dark;
    background-color: rgba(229, 80, 0, 0.06);
  }

  .pass-toggle-btn--active {
    border: 3px solid $ln-color-orange;
  }

  @media (max-width: 719px) {
    .pass-toggle-btn {
      padding: 1rem;
    }

    .pass-toggle-btn h3 {
      font-size: 1.375rem;
      line-height: 1.18182;
    }
  }

  .product-tile {
    width: 100%;
    display: block;
    background: $ln-color-white;
    border: $ln-color-grey-light 2px solid;
    border-radius: 8px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-decoration: none;
  }

  .product-tile:focus,
  .product-tile:hover {
    border-color: $ln-color-orange;
    color: $ln-color-grey-dark;
    background-color: rgba(229, 80, 0, 0.06);
  }

  .product-tile--active {
    border: 2px solid $ln-color-orange;
  }

  .product-tile .product-tile__savings {
    color: #d50000;
    font-weight: bold;
  }

  .product-tile .product-tile__recommended {
    display: inline-block;
    background: $ln-color-green;
    border-radius: 80px;
    color: $ln-color-white;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    padding: 0 16px;
    margin: 12px 0;
  }

  .product-tile .product-tile__recommended-empty {
    line-height: 30px;
    margin: 12px 0;
  }

  .product-tile .product-tile__renews-text {
    font-size: 14px;
    margin-top: 12px;
  }

  @media (max-width: 719px) {
    .product-tile {
      padding: 1rem;
    }
  }

  .dp-info-alert {
    color: $dark-grey-hex;
    background-color: #e8f1fb;
    border: 1px solid #1976d2;
    border-radius: 4px;
  }

  .dp-button-group {
    display: flex;
    flex-direction: row;
    & > :first-child {
      margin-right: 1.5rem;
      .ln-c-progress-indicator {
        width: auto;
      }
    }
  }

  @media (max-width: 719px) {
    .dp-button-group {
      flex-direction: column;
      & > :first-child {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  .dp-wyg {
    display: flex;
    flex-direction: row;

    .dp-wyg__item {
      flex-grow: 1;
      flex-basis: 33.3%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: 0 1.5rem;
      border-right: 1px solid #c4c4c4;

      &:first-child {
        padding: 0 1.5rem 0 0;
      }

      &:last-child {
        padding: 0 0 0 1.5rem;
        border-right: none;
      }

      div {
        text-align: center;
      }
    }

    .dp-wyg__icon {
      margin-top: 1rem;
      line-height: 1;
    }
  }

  @media (max-width: 719px) {
    .dp-wyg {
      flex-direction: column;

      .dp-wyg__item {
        flex-basis: auto;

        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;

        padding: 1rem 0;
        border-right: none;
        border-bottom: 1px solid #c4c4c4;

        &:first-child {
          padding: 0 0 1rem 0;
        }

        &:last-child {
          border-bottom: none;
          padding: 1rem 0 0 0;
        }

        div {
          text-align: left;
        }
      }

      .dp-wyg__icon {
        margin-top: 0;
        margin-right: 1rem;
      }
    }
  }

  .dp-guarantee {
    .dp-guarantee__wrapper {
      display: flex;
    }

    .dp-guarantee__img {
      margin: 0 1rem 0 0;
    }

    .dp-guarantee__content-inner {
      display: block;
    }

    .dp-guarantee__content-outer {
      display: none;
    }
  }

  @media (max-width: 719px) {
    .dp-guarantee {
      .dp-guarantee__img {
        margin: 0 1rem 1rem 0;
      }

      .dp-guarantee__content-inner {
        display: none;
      }

      .dp-guarantee__content-outer {
        display: block;
      }
    }
  }
}
