.delivery-instructions-modal {
  z-index: 6;
  line-height: 150%;

  &.delivery-instructions-modal-disabled {
    .ln-c-modal__scroll-area {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .ln-c-modal__body-wrapper {
    display: flex;
    align-items: center;
  }

  .ln-c-modal__body {
    width: 100%;
    max-width: 500px;
    text-align: left;
    max-height: 332px;
    @include ln-respond-to("min-md") {
      max-height: 420px;
    }
    @include ln-respond-to("max-ss") {
      max-height: 412px;
    }
  }

  .ln-c-modal__heading {
    @include ln-respond-to("xs") {
      font-size: 30px;
    }

    margin-bottom: $ln-space-x2;
  }

  .ln-c-button-group {
    display: flex;
    flex-direction: column;
    flex-basis: fit-content;
    height: auto;

    @include ln-respond-to("xs") {
      flex-direction: row-reverse;
      justify-content: end;
    }

    margin-bottom: 0;
    padding-top: 0;
  }

  .ln-c-button-group__primary,
  .ln-c-button-group__secondary {
    .ln-c-button {
      margin-right: calc(#{$ln-space} * 2);
      margin-top: calc(#{$ln-space} * 2);
      min-width: 160px;
      width: 100%;

      @include ln-respond-to("xs") {
        width: auto;
        margin: 0;
      }
    }
  }

  .ln-c-button-group__secondary {
    .ln-c-button {
      @include ln-respond-to("xs") {
        margin-right: calc(#{$ln-space} * 2);
      }
    }
  }

  div {
    height: 100%;

    .ln-c-modal__scroll-area {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__text-area {
    flex: 1 1;
    resize: none;
    @include ln-respond-to("min-md") {
      margin-bottom: $ln-space-x3;
    }
  }

  &__description {
    margin-bottom: $ln-space-x2;
  }

  .invalid-character-warning {
    background-color: #fbe6e6;
    color: #dd2e2e;
    border: 1px solid #dd2e2e;
    text-align: left;
    padding: 12px;
    display: flex;
    flex-basis: content;
    gap: 8px;
    margin-bottom: $ln-space-x2;
    height: auto;

    &__icon {
      margin-top: 5px;
    }

    &__text {
      flex: 1;
    }
  }

  &.delivery-instructions-modal-invalid-warning {
    .ln-c-modal__body {
      max-height: 422px;
      @include ln-respond-to("min-md") {
        max-height: 500px;
      }
      @include ln-respond-to("max-ss") {
        max-height: 502px;
      }
    }
  }
}
