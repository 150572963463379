$recipes-browse-height: 25px;

.recipes {
  &--author {
    margin: $ln-space-x3 0 0 0;
    color: $ln-color-grey;
  }
  &__description-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &--header-margin {
    padding: 24px 24px 24px 0;
    @media (max-width: 959px) {
      padding: 16px;
    }
  }
  &__details-tabs {
    padding-left: 16px;
    padding-right: 16px;
  }
  &-action-buttons__container {
    margin: $ln-space-x2 0 0 0;
    padding-top: $ln-space-x2;
    border-top: 1px solid $ln-color-grey-light;
    display: flex;
    button {
      padding-right: $ln-space-x2;
      font-weight: bold;
      font-family: $ln-font-family-brand;
      background: initial;
      color: $ln-color-dark;
    }
  }
  &--block-margin {
    @include ln-respond-to("max-md") {
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem);
    }
  }

  &__nutrition {
    padding-left: 0;
    @include ln-respond-to("md") {
      border-left: 1px solid $ln-color-grey-light;
      padding-left: 20px;
    }
  }

  &__video-container {
    position: relative;
    padding-bottom: 56%;
    > img {
      object-fit: cover;
      object-position: left;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .credit {
      background-color: rgba(255, 255, 255, 0.7);
      color: #333;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .youtube {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
    }
    .toggle-display {
      display: inherit;
    }

    .fallback-img {
      width: 56%;
      margin-left: 22%;
    }

    .play-button {
      position: absolute;
      display: grid;
      place-items: center;
      height: 100%;
      width: 100%;
      top: 0;
      > img {
        cursor: pointer;
      }
    }
  }

  &__northfork {
    .sticky-wrapper-bottom {
      z-index: 2;
    }

    &--container {
      position: sticky;
      bottom: 0;
      z-index: 2;
      padding-bottom: 0;
    }

    &--banner {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-items: center;
      margin-bottom: $ln-space-x3;

      @include ln-respond-to("max-sm") {
        flex-direction: column;
      }

      .northfork-banner {
        &__info {
          &--container {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: $ln-space;

            @include ln-respond-to("min-sm") {
              margin-right: $ln-space-x2;
            }

            @include ln-respond-to("max-sm") {
              align-items: center;
              justify-content: center;
              margin-bottom: $ln-space;
            }
          }

          &--title,
          &--details {
            margin-bottom: 0;
            @include ln-respond-to("max-sm") {
              text-align: center;
            }
          }
        }

        &__CTA {
          &--container {
            display: flex;
            width: 100%;

            @include ln-respond-to("max-sm") {
              align-items: center;
              justify-content: center;
            }
          }

          &--button {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          &--icon {
            margin-right: $ln-space;
            font-size: 18px;
          }
        }
      }
    }
  }

  &-northfork-block-margin-bottom {
    margin-bottom: $ln-space-x4;
    @include ln-respond-to("max-md") {
      margin-bottom: $ln-space-x2;
    }
  }

  &-northfork-section {
    background-color: $ln-color-grey-v-light;
    @include ln-respond-to("max-md") {
      margin-left: -1rem;
      margin-right: -1rem;
      iframe {
        height: auto !important;
        max-height: 130px;
      }
    }
  }
  &__back {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    > a {
      text-decoration: none;
    }
    img {
      vertical-align: text-top;
      padding-right: 6px;
    }
  }
}

@mixin margin-top-recipes {
  margin-top: $recipes-browse-height;

  &.minimized {
    @include ln-respond-to("sm") {
      margin-top: calc(#{$header-height-minimized-md} + #{$recipes-browse-height});
    }
    @include ln-respond-to("md") {
      margin-top: calc(#{$header-height-minimized} + #{$recipes-browse-height});
    }
  }
}

.margin-top-recipes-comp {
  @include margin-top-recipes;
  padding-top: 0.1px;
}

.margin-top-recipes-error {
  @include margin-top-recipes;
}

.show-more {
  display: flex;
  align-items: center;
  flex-direction: column;
  > p {
    font-weight: 700;
    margin-bottom: 8px;
  }
  > progress {
    border-radius: 0;
    width: 320px;
    height: 8px;
    margin-bottom: 32px;
    @media (max-width: 320px) {
      width: 240px;
    }
  }
  > progress::-webkit-progress-bar {
    background-color: $ln-color-grey-light;
  }
  > progress::-webkit-progress-value {
    background-color: $ln-color-orange;
  }
  > progress::-moz-progress-bar {
    background-color: $ln-color-orange;
    border: none;
  }
  > button {
    width: 100%;
  }
}

.recipe-print {
  padding: 2rem;

  .recipes--block-margin {
    .recipes__video-container {
      img {
        object-position: inherit;
      }
    }
  }
  .pd__details {
    .ingredients {
      .ln-o-bare-list {
        .ln-o-bare-list__item {
          padding-bottom: 1rem;
        }
      }
    }
  }

  .ln-o-section {
    padding-bottom: 0 !important;
  }
}
