@mixin lister-pill {
  display: inline-block;
  width: max-content;
  height: 40px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 1rem;
  color: $dark-grey-hex;
  border-radius: 1000px;

  &__active {
    background-color: #7f0a41;
    color: white;
  }
}

@mixin pill-list-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
}

.multi-search-tabs {
  padding: 0.1px;
  margin-block: 24px;

  &__new_header {
    margin-block: 0;
  }
}

.multi-search-page {
  padding: 16px;
}

.multi-search-tab__list {
  @include pill-list-container;
}

button.multi-search-item {
  @include lister-pill;
}

.btn-find-products {
  text-decoration: none;
  &:hover {
    color: white;
  }
}

.btn-clear {
  margin-left: 12px;
  background-color: #d8d8d8;
  color: #737373;
  border: none;
}

.btn-show-all {
  display: block flex;
  background: none;
  gap: 12px;
  justify-content: center;
  font-weight: 600;
  margin: 24px auto 0 auto;
}

.btn-go-back {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  & a {
    text-decoration: none;
    font-weight: bold;
  }
}

.multi-search-input {
  display: block;
  width: 90%;
  height: 305px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.multi-search-textarea-label {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
}
