.nectar-offer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #6c18cb;
  background-color: #f3e9ff;
  padding: 8px 16px;

  &__label {
    display: flex;
    font-weight: bold;
    font-size: 16px;

    &-text {
      padding-left: 10px;
    }
  }

  &__caption {
    font-size: 14px;
  }

  @include ln-respond-to("max-md") {
    &__caption {
      margin-left: 10px;
    }
  }
}
