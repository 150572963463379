.mobile-info-accordion {
  position: relative;
  z-index: 5;

  margin-bottom: $ln-space-x2;

  text-align: left;

  &__link {
    display: flex;
    justify-content: space-between;
    text-decoration: none;

    &--text {
      text-decoration: none;
      margin-left: 0;

      font-size: 16px;
      font-weight: 700;
    }

    &--icon {
      height: 20px;
    }
  }
}
