$nectar-background-colour: #f3e9ff;
$nectar-background-colour-hover: #dfcef5;
$offer-background-colour: #fbe6e6;
$offer-background-colour-hover: #eebdbd;
$offer-colour: #d50000;
$offer-colour-darker: #8d0000;
$nectar-colour: #8223fa;
$nectar-colour-darker: #4a0983;

.grouped-promotion-message__link {
  @include ln-font("caption");

  color: $ln-color-red;
  text-decoration: none;
  background-color: transparent;
  text-align: left;

  &:hover {
    text-decoration: underline;
    background-color: $nectar-link-background-color;
  }

  &--purple {
    color: $nectar-colour;
    text-decoration: none;

    &:visited {
      color: $nectar-colour-darker;
    }
  }
}

a.promotion-message {
  @include ln-respond-to("min-xs") {
    margin: 0 auto 4px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 4px;
  padding: $ln-space-half $ln-space-half $ln-space-half $ln-space;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;

  color: $offer-colour;
  background-color: $offer-background-colour;
  transition-property: background-color, color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: $offer-colour-darker;
    background-color: $offer-background-colour-hover;
    text-decoration: underline;
    cursor: pointer;
  }
  &--nectar {
    color: $nectar-colour;
    background-color: $nectar-background-colour;

    &:hover,
    &:active,
    &:focus {
      color: $nectar-colour-darker;
      background-color: $nectar-background-colour-hover;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  & .arrow {
    align-self: flex-start;

    &__arrow {
      margin-left: auto;
      font-size: 14px;
    }
  }
}

button.promotion-message {
  @include ln-respond-to("min-xs") {
    margin: 0 auto 4px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 4px;
  padding: $ln-space-half $ln-space-half $ln-space-half $ln-space;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  width: 100%;

  color: $offer-colour;
  background-color: $offer-background-colour;
  transition-property: background-color, color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: $offer-colour-darker;
    background-color: $offer-background-colour-hover;
    text-decoration: underline;
    cursor: pointer;
  }
  &--nectar {
    color: $nectar-colour;
    background-color: $nectar-background-colour;

    &:hover,
    &:active,
    &:focus {
      color: $nectar-colour-darker;
      background-color: $nectar-background-colour-hover;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  & .arrow {
    align-self: flex-start;

    &__arrow {
      margin-left: auto;
      font-size: 14px;
    }
  }
}
