.pd {
  &__breadcrumbs {
    display: flex;
    align-items: baseline;

    &--left-arrow {
      position: absolute;
    }
  }
}
