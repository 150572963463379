.recipes-search-results {
  .recipes-search-indicator {
    &__title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 0.5rem;
      @include ln-respond-to("max-sm") {
        @include ln-font("display-3");
      }
    }

    &__no-results {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      max-width: 1100px;
      margin: 0 auto 50px;
    }
  }

  .recipes-pill {
    &.clear-filters {
      color: $ln-color-grey;
      background: $ln-color-light;
      border: 1px solid $ln-color-light;
      @include recipes-pill-button-on-hover(#d8d8d8);
    }
    &.selected {
      color: $ln-color-white;
      background: $ln-color-plum;
      border: 1px solid $ln-color-plum;
      @include recipes-pill-button-on-hover(#7f0442);
    }
  }
}

.margin-top-recipes-search {
  @include margin-top-recipes;

  // TODO: check this out.
  &.amend-mode {
    @include ln-respond-to("sm") {
      margin-top: $header-height-sm;
    }

    @include ln-respond-to("md") {
      margin-top: $header-height-md;
    }
  }
}

.recipes-search-box {
  margin-bottom: 0;
  padding-bottom: 0;
  input {
    margin-bottom: 0;
    width: 100%;
    height: 48px;
    padding-left: 10px;
  }
  > ul {
    overflow-y: auto;
    > li {
      padding: 12px;
      cursor: pointer;
    }
  }
  .search-bar--bttn {
    position: absolute;
    top: 0;
    height: 42px;
    background: $ln-color-white;
    z-index: 1;
    right: 0 !important;
  }
}
