@import "../../../components/Carousel/CarouselWrapper/CarouselWrapper.scss";

.howToAccess {
  &__paragraph {
    @include ln-respond-to("max-sm") {
      font-size: 14px;
    }
  }

  &__section-header {
    @extend .carousel-header__title;

    margin-bottom: $ln-space-x2;
    text-align: left;
  }

  &__card {
    padding-bottom: 0px;

    &-header {
      font-size: 26px;
      margin-bottom: 16px;
      text-align: start;

      @include ln-respond-to("max-sm") {
        font-size: 20px;
      }
    }
  }

  &__icon {
    height: 2.25em;
    width: 2.25em;
  }

  &__text {
    padding-left: 12px;
  }

  &__header {
    margin-bottom: 8px;
    font-size: 18px;
  }
}
