.delivery-pass-renewal-options-confirmation {
  .page-layout-right {
    --offset: 24px;
  }

  .pass-details {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      width: 720px;
    }

    @include ln-respond-to("lg") {
      margin-bottom: calc(#{$ln-space} * 3);
    }

    &__header {
      color: $ln-color-black;
    }

    &__title {
      margin-bottom: 0;
      color: $ln-color-black;
    }

    &__content {
      &:last-child {
        margin: auto;
      }
    }

    &__divider {
      border-bottom: 1px solid #c4c4c4;
      margin-bottom: 16px;
    }

    &__terms-title {
      margin-bottom: 8px;
    }

    &__terms-content {
      font-size: 14px;

      &:last-child {
        margin: auto;
      }
    }
  }

  .navigation-section {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      width: 720px;
    }

    @include ln-respond-to("lg") {
      width: 395px;
    }

    &__header {
      color: $ln-color-black;
    }

    &__button {
      width: 100%;
      max-width: 288px;

      @include ln-respond-to("lg") {
        max-width: none;
      }
    }
  }

  .legal-section {
    font-size: 14px;
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      width: 720px;
    }

    @include ln-respond-to("lg") {
      margin-bottom: calc(#{$ln-space} * 3);
    }
  }
}
