.header-background {
  background-color: $ln-color-white;
  width: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;

  &__hide-occasions {
    margin-top: calc(-#{$occasionsHeader-height});

    @include ln-respond-to("sm") {
      margin-top: calc(-#{$occasionsHeader-height});
    }

    @include ln-respond-to("md") {
      margin-top: calc(-#{$occasionsHeader-height});
    }
  }
}
