.green-delivery-slot {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__icon {
    height: $ln-space-x4;
    margin-right: $ln-space;
    color: $ln-color-green;
  }

  &__link {
    text-decoration: underline;
    background-color: unset;
    line-height: 200%;
    text-align: left;
    &:hover {
      color: $ln-color-orange;
    }
  }

  &__modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      color: $ln-color-success;
      height: $ln-space-x4;
      width: $ln-space-x4;
      margin-bottom: $ln-space;
    }
  }
}
