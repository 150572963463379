.mph-pageHeader {
  width: 100%;
  padding: 2rem;
  background-color: $ln-color-white;
  color: $ln-color-black;
  border-radius: 4px 4px 0 0;

  &__title {
    margin: 0px 0px 10px 0px;
    @include ln-respond-to("max-sm") {
      @include ln-font("display-4");
      margin: 24px 0px 12px 0px;
    }
  }

  &__section {
    width: 100%;
    padding-bottom: 0;
    &__paragraph {
      @include ln-font("body-2");
      @include ln-respond-to("max-sm") {
        @include ln-font("caption");
      }
    }
  }

  &__select {
    display: inline-flex;
    flex-wrap: wrap;
    &__two {
      margin-left: 2rem;
      @media (max-width: 425px) {
        margin-left: 0;
      }
    }
  }

  &__mealSelect {
    @include ln-respond-to("min-sm") {
      max-width: 425px;
    }
    select {
      border-color: $ln-color-orange;
      @include ln-font("label-1");
      color: $ln-color-orange;
      padding: 0px 30px 0px 15px;
      border-width: 3px;
      appearance: none;
      width: 255px;
      height: 51px;
    }
  }
}

.mph-pageHeaderPreferences {
  width: 100%;
  padding: 2rem;
  background-color: $ln-color-white;
  color: $ln-color-orange;
  margin-top: $ln-space-x4;
}
