.carouselLoadingSkeleton {
  $tile-gutter: 24px;

  &__tile {
    width: 200px;

    @include ln-respond-to("max-sm") {
      &:first-of-type {
        margin-left: $ln-space-x2;
      }

      &:last-of-type {
        margin-right: $ln-space-x2;
      }
    }

    @include ln-respond-to("min-sm") {
      &:first-of-type {
        margin-left: $ln-space-x3;
      }

      &:last-of-type {
        margin-right: $ln-space-x3;
      }
    }

    @include ln-respond-to("min-lg") {
      &:first-of-type {
        margin-left: unset;
      }

      &:last-of-type {
        margin-right: unset;
      }
    }

    flex-shrink: 0;

    &.ln-o-grid__item {
      padding-bottom: 0px;
      padding-left: 0px;
    }

    @include ln-respond-to("max-sm") {
      margin-left: 16px;

      &:first-of-type {
        margin-left: 16px;
      }

      &:last-of-type {
        margin-right: 16px;
      }
    }
  }

  &__content {
    width: 100%;
    background-color: $ln-color-white;
    padding: 175px 0 70px 0;
    display: flex;
    flex-direction: column;
    height: 525px;
    position: relative;
  }

  &__button {
    height: 48px;
    background-color: $ln-color-grey-light;
    width: 85%;
    margin: 1rem;
  }

  &__button-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    animation: fadelines 4s infinite alternate;
  }

  &__line {
    height: 14px;
    background-color: $ln-color-grey-light;
    display: block;
    margin-bottom: 8px;
    opacity: 0.8;
    animation: fadelines 4s infinite alternate;
    width: 80%;

    &:nth-child(3) {
      width: 50%;
    }

    @include ln-respond-to("min-xs") {
      width: 80%;

      &:nth-child(3) {
        width: 50%;
        margin: 0;
      }
    }

    @include ln-respond-to("min-sm") {
      width: 50%;
      &:nth-child(3) {
        width: 25%;
        margin: 0;
      }
    }

    @include ln-respond-to("min-md") {
      width: 80%;
      &:nth-child(3) {
        width: 50%;
      }
    }
  }
}
