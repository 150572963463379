$mobile-break-at: "sm";
$mobile-break-at-max: "max-sm";

// Styling for the ingredient tiles (common for the select
// and swap variants)
.ingredient-wrap {
  background-color: white;
  width: 100%;
  max-width: 100%;
  margin: $ln-space 0px;
  padding: $ln-space-x2;

  @include ln-respond-to($mobile-break-at) {
    margin: $ln-space-x2 0px;
  }

  // Reset margin and padding on all child elements
  // (nested included)
  & * {
    margin: 0px;
    padding: 0px;
  }

  // Grid templates for the two variants of product area
  .product-area {
    width: 100%;
    height: 100%;

    // Default setup for the ingredients select view
    display: grid;

    // Mobile select layout
    @include ln-respond-to($mobile-break-at-max) {
      grid-template-columns: minmax(100px, 1fr) 2fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-auto-rows: 1fr;
      gap: 0px $ln-space-x2;
      grid-auto-flow: row;
      grid-template-areas:
        "product-select product-name"
        "product-icon product-name"
        "product-icon product-price"
        "product-swap product-quantity-controls";

      // Changes for swap layout
      &.product-area-swap {
        grid-template-rows: 1fr 1fr fit-content(100px) auto;
        grid-template-areas:
          "product-icon product-name"
          "product-icon product-price"
          ". product-quantity-controls"
          ". product-link";
      }

      .product-name {
        align-self: start;
      }

      .product-price {
        align-items: end;
      }
    }

    // Desktop configuration
    @include ln-respond-to($mobile-break-at) {
      display: grid;
      grid-template-columns: 40px auto 1fr auto 160px 1fr;
      grid-template-rows: 1fr;
      gap: $ln-space $ln-space-x2;
      grid-auto-flow: row;
      grid-template-areas: "product-select product-icon product-name product-swap product-quantity-controls product-price";
      align-items: top;

      // Changes for swap layout
      &.product-area-swap {
        grid-template-columns: auto 2fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          "product-icon product-name product-price"
          "product-icon product-link product-quantity-controls";
      }

      &.product-area-unavailable {
        grid-template-columns: auto 2fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas:
          "product-icon product-name product-price"
          "product-icon product-name product-price";
      }

      .product-price {
        align-items: center;
      }
    }

    // Common for mobile and desktop
    .product-select {
      grid-area: product-select;
    }

    .product-icon {
      grid-area: product-icon;
      place-self: center;

      flex-grow: 0;
      height: 100px;
      width: 100px;
      img {
        background-color: rgb(217, 217, 217);
      }
    }

    .product-name {
      grid-area: product-name;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #4c4c4c;
      font-weight: 700;
      font-size: 16px;
    }

    .controls {
      grid-area: product-quantity-controls;
      align-self: center;

      // Plus/Minus add to basket buttons in the select variant
      &.product-quantity-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h6 {
          text-align: center;
          margin: 0px;
          min-width: 30px;
        }
      }

      // Shove button right in parent
      &.selection-controls {
        display: block;
        .button {
          display: block;
          margin-left: auto;
          width: 100%;
          max-width: 400px;
        }
      }
    }

    .product-swap {
      grid-area: product-swap;
      place-self: center;

      & > button.ln-c-button {
        width: 100%;
        max-width: 100px;
        padding: $ln-space;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        .swap-icon,
        span {
          padding: 0 $ln-space-half;
        }
      }
    }

    .product-price {
      grid-area: product-price;
      display: grid;

      .pt__cost {
        & > div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          // Should apply to span children
          & > span {
            padding: 0 0 0 $ln-space-half;
          }
          // Fix prices showing up weird on mobile
          & > div:first-child {
            flex-wrap: wrap-reverse;
          }

          & > div:last-child {
            flex-wrap: wrap;
          }
        }
      }
    }

    .product-link {
      grid-area: product-link;
      align-self: end;
      padding: $ln-space 0 0 0;
    }
    .shared {
      min-width: 175px;
      font-weight: 100;
    }
    .tippadding {
      padding: 16px;
    }
  }
}
