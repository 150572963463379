.fav-loading {
  margin: auto;
  width: 210px;
}

.fav-pagination {
  margin: 1rem;
  text-align: center;
}

.first-favourites-message {
  @media (min-width: 960px) {
    width: 700px;
    text-align: center;
    margin: 0 auto;
  }
}

.no-favourites-header-message {
  text-align: center;
}

.fav-header {
  margin-top: calc(#{$favourites-subheader-height});

  @include ln-respond-to("sm") {
    margin-top: calc(#{$favourites-subheader-height} + 43px);
  }

  @include ln-respond-to("md") {
    margin-top: calc(#{$favourites-subheader-height} + 13px);
  }
}

.fav-header-with-pills {
  background-color: #ffffff;
  margin-top: -7px;

  @include ln-respond-to("sm") {
    margin-top: 32px;
  }

  @include ln-respond-to("md") {
    margin-top: 0px;
  }
}

.fav-product-control-tooltip[class*="-top"],
.fav-product-control-tooltip[class*="-bottom"] {
  &.is-open {
    @include ln-respond-to("max-sm") {
      > div:first-child::after {
        left: 70%;
      }
    }
    @include ln-respond-to("min-md") {
      div.ln-c-tooltip__message {
        margin-top: $ln-space-x3;
      }
    }
  }
}

.fav-pill-list {
  margin: 0;
  width: 100%;
  list-style-type: none;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;

  &__swiper {
    justify-content: flex-start;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.fav-pill {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  height: 40px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  color: $dark-grey-hex;
  background-color: #f2f2f2;
  border-radius: 24px;
  padding: 0px 17.64px;
  font-family: Arial;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  &__selected {
    color: #ffffff !important;
    background-color: #7f0442 !important;
    border-color: #7f0442;
  }

  &:hover {
    background-color: #d8d8d8;
    color: $dark-grey-hex;
  }
}

.leftpadding {
  margin-top: 116px;

  @include ln-respond-to("sm") {
    margin-top: 184px;
  }
  @include ln-respond-to("md") {
    margin-top: 218px;
  }
  @include ln-respond-to("lg") {
    margin-top: 221px;
  }
}

.bottom-pad {
  margin-bottom: 16px;
}

.notif {
  padding: 10px;
  &__div {
    display: flex;
  }

  &__icon {
    font-size: 20px;
  }

  &__heading {
    text-align: left;
    margin: 1px 0px 10px 8px;
    font-weight: 800;
  }

  &__p {
    margin: 0;
    text-align: left;
  }
}
