.browse-header + .fav-banner {
  margin-top: $ln-space-x3;
}

.fav-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $ln-space-x2;
  column-gap: 12px;
  gap: 10px;
  margin-bottom: $ln-space-x3;
  width: 100%;

  &__icon-wrapper {
    height: 20px;
    width: 20px;
    display: flex;
  }

  &__heart-icon {
    color: $ln-color-plum;
    font-size: unset !important;
  }

  &__sm-text {
    display: flex;
    align-items: center;
    color: $ln-color-monochrome-dark;
  }

  &__link {
    background-color: transparent;
    text-decoration: underline;
    font-family: inherit;
    color: $ln-color-dark;

    &:hover {
      color: $ln-color-orange;
    }
  }
}
