.pr__logos {
  margin: 0 auto;

  &__container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    & .sainsburys-logo {
      width: 41%;
    }

    & .nectar-logo {
      margin: 0 1rem;
      min-width: auto;
      width: 20%;
    }

    & .select-logo {
      width: 66%;
    }

    .pr__plus {
      margin: $ln-space-x2;
      width: $ln-space-x2;
    }

    @include ln-respond-to("min-xs") {
      width: 80%;

      & .select-logo {
        width: 66%;
      }
    }

    @include ln-respond-to("max-xs") {
      width: 90%;
    }

    @media (max-width: 320px) {
      flex-direction: row;

      .sainsburys-logo {
        min-width: auto;
        width: 31%;
      }

      .nectar-logo {
        margin: 0.5rem;
        width: 13%;
      }

      .select-logo {
        width: 48%;
      }
    }

    @media (max-width: 240px) {
      flex-direction: column;

      .sainsburys-logo {
        width: 62%;
      }

      .nectar-logo {
        width: 30%;
      }

      .select-logo {
        width: 100%;
      }
    }
  }

  &--plus-shown {
    & .nectar-logo {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (max-width: 240px) {
    &--plus-shown {
      & .pr__plus {
        margin: 0.5rem;
      }
    }
  }
}

.pr-confirm-changes {
  &__content {
    .select-logo {
      max-width: 193px;
      min-width: 121px;
      width: calc(100% - 100px);

      .logo-image {
        max-height: 23px;
      }
    }
  }

  &__logos {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;

    @media (max-width: 240px) {
      align-items: start;
      flex-direction: column;
    }

    & .nectar-logo {
      margin-right: 1rem;
      max-width: 60px;
      min-width: 42px;
      width: 20%;

      @media (max-width: 240px) {
        margin-bottom: 0.5rem;
      }

      @media (min-width: 240px) {
        margin-right: 0.5rem;
      }

      @media (min-width: 320px) {
        margin-right: 1rem;
      }
    }

    & .select-logo {
      margin-right: 1rem;

      @media (max-width: 240px) {
        margin-right: 0.5rem;
      }
    }
  }

  /* specific ovverides for luna default styles */
  .ln-c-button {
    .ln-o-flag {
      justify-content: center;

      .ln-o-flag__body {
        flex-grow: 0;
        white-space: nowrap;
      }
    }
  }
}

.pr-mobile-and-postcode {
  @include ln-respond-to("sm") {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;

    &__input {
      width: 48%;
    }
  }

  &__input {
    margin-bottom: 1rem;
  }
}

.pr-cpm-v2 {
  .pr-cpm-option {
    align-items: center;
    border: 1px solid $ln-color-grey;
    display: flex;
    margin: 2px;
    min-width: 200px;
    padding-left: 0;

    &--active {
      border: 3px solid $ln-color-orange;
      margin: 0;
    }

    input:focus {
      & ~ .ln-c-form-option__label {
        box-shadow: none;
      }
    }

    .ln-c-form-option__label {
      height: 80px;
      padding-bottom: 13px;
      padding-left: 75px;
      padding-top: 13px;
      width: 100%;

      &:before {
        left: 26px;
      }

      @include ln-respond-to("sm") {
        height: 100px;
      }
    }

    img,
    svg {
      max-height: 50px;
      min-width: 50px;
      width: 100%;
    }

    .ln-c-form-group {
      margin: 0;
    }

    .pr-cpm-option-wrapper {
      align-items: center;
      border-left: 1px solid $ln-color-grey-light;
      display: flex;
      height: 100%;
      padding: 1rem;
    }

    fieldset {
      width: 100%;
    }
  }

  .ln-o-bare-list__item {
    display: inline-block;
  }

  .pr-cpm-options-text {
    margin-bottom: $ln-space-x4;
  }

  .pr-cpm-options-disclaimer-new {
    margin-top: $ln-space-x4;
  }

  &__fieldset {
    legend {
      margin-bottom: calc(#{$ln-space} * 3.5);
    }
  }

  &__info-link {
    cursor: pointer;
    text-decoration: underline;
    margin-right: 2px;
    margin-left: 2px;

    &__message {
      @media (max-width: 750px) {
        background: $ln-color-white;
        color: $ln-color-black;
        border: 2px solid $ln-color-black;
      }
    }
  }
}

.header.pr-header {
  height: 50px;
  position: relative;

  @include ln-respond-to("sm") {
    height: 80px;
  }

  .pr-back-link {
    color: $ln-color-grey-dark;
    font-family: Arial, sans-serif;
    left: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .pr-link-text-wrap {
      display: none;
      vertical-align: middle;

      @include ln-respond-to("sm") {
        display: inline;
      }
    }
  }

  .pr-js-logo {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 96px;

    @include ln-respond-to("sm") {
      max-width: 140px;
      width: auto;
    }

    svg {
      display: block;
    }
  }
}

.pr-margin-top {
  margin-top: 50px;

  @include ln-respond-to("sm") {
    margin-top: 80px;
  }
}
