.homepage__nectar-offers {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "title logo"
    "subtitle logo"
    "btn btn";
  gap: $ln-space;

  #homepage__nectar-offers--tile {
    grid-area: title;
  }

  #homepage__nectar-offers-subtitle {
    grid-area: subtitle;
  }

  #homepage__nectar-offers--logo {
    grid-area: logo;
    justify-self: end;
  }

  #homepage__nectar-offers--btn {
    grid-area: btn;
    align-content: end;
  }
}
