.alternativeFavourites {
  // overrides product tile media query specifically for tiles on alternatives modal
  @include ln-respond-to("xs") {
    .ln-u-1\/2\@sm {
      width: 100%;
    }
  }

  .gridModal {
    button.ln-c-button {
      top: 0.25rem;
    }

    @include ln-respond-to("max-sm") {
      .ln-c-modal__body-wrapper {
        width: 100%;
      }
    }

    @include ln-respond-to("sm") {
      .ln-o-grid {
        justify-content: center;
        overflow-x: hidden;
      }

      .pt-grid-item {
        margin: 0 auto;
        display: flex;
      }
    }

    &.ln-c-modal__body {
      overflow-x: hidden;
    }
  }

  .ln-c-modal {
    z-index: 11;
  }
}
