.oauth-registration-confirmation {
  &__category {
    width: 100%;

    @include ln-respond-to("xs") {
      width: 210px;
    }
  }

  &__button {
    width: 100%;

    @include ln-respond-to("xs") {
      width: 309px;
    }
  }

  &--image-magnify {
    width: 53px;
    height: 53px;

    @media (min-width: 720px) {
      width: 65px;
      height: 65px;
    }
  }
}

.collection-container {
  display: block;

  p {
    margin-top: 1rem;
  }

  @include ln-respond-to("sm") {
    display: flex;
  }
}
