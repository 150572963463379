$Homepage-card-gap: 24px;
$Homepage-card-gap-desktop: 40px;

.homepage {
  padding-top: $ln-space-x3;

  &__desktop-hidden {
    display: block;
    @include ln-respond-to("sm") {
      display: none;
    }
  }

  .mobile-actions {
    padding: 0;
    margin-bottom: var(--ds-space-spacing-x2);

    .ds-o-grid-item {
      background-color: $ln-color-white;
      color: $ln-color-grey-dark;
      padding: var(--ds-space-spacing-x2);
      border-radius: 4px;

      &.center {
        text-align: center;
      }

      a {
        text-decoration: none;
        font-weight: bold;
        text-align: center;

        &.nectar {
          color: $nectar-colour;
        }

        svg {
          height: 20px;
          scale: 1.5;
        }
      }
    }
  }

  // Area A customisation
  &__area-a .ln-o-section {
    @include ln-respond-to("sm") {
      margin: 0 !important;
      height: 100%;
      overflow: auto;

      picture {
        height: 100%;
        img {
          max-width: none;
        }
      }

      // Selects the ug002 container grid row wrapper
      & > div:first-child {
        height: 100%;
        min-width: 140%;
        @include ln-respond-to("md") {
          min-width: unset;
        }

        // Selects the card inner container
        & [data-test="c004-wrapper"] {
          margin-bottom: 0;
          height: 100%;
        }
      }
    }
  }

  &__speedybasket-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: $ln-space-x2;
    margin-top: $ln-space-x2;
    @include ln-respond-to("min-md") {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__cta-wrapper {
    align-self: stretch;

    .homepagecard {
      padding: $ln-space-x2;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $ln-space;

      &__subtitle {
        display: none;
        @include ln-respond-to("min-md") {
          display: block;
        }
      }

      &__link-container {
        display: flex;
        flex-direction: column;
        gap: $ln-space;
      }

      &__delivery-wrapper {
        display: flex;
      }

      &__delivery-icon {
        width: 24px;
        margin-right: $ln-space;
      }

      &__delivery-date,
      &__confirm-checkout {
        padding: 2px;
        margin: 0;
      }

      &__expiry-time {
        font-weight: bold;
      }
    }
  }
}
