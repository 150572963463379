.loading {
  &:hover,
  &:focus,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  text-decoration: none;
}

.loading span {
  animation-duration: 1.4s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: blink;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
