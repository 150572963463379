.pr-progressive-reg-v2__page {
  margin-top: 35px;

  @media (min-width: 720px) {
    font-size: 1em;
    h2 {
      margin-top: 10px;
      margin-bottom: 15px;
    }
    h3 {
      margin-bottom: 0.4em;
    }

    .pr-text {
      margin-bottom: 7px;
    }
  }

  @include ln-respond-to("sm") {
    width: 570px;
  }

  // overriding until DOM is tidied up
  .ln-o-section {
    padding: 0;
  }

  .error-banner {
    h3 {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      margin-bottom: 5px;
    }

    &__content {
      display: block;
      margin-left: 40px;
    }

    padding: 10px 15px;
    margin-bottom: 1.5rem;
    background-color: $ln-color-white;
    border: 2px solid $ln-color-grey;
    position: relative;
    font-weight: normal;

    &:before {
      background-color: $ln-color-red;
      color: $ln-color-white;
      position: absolute;
      top: 12px;
      left: 15px;
    }
  }

  .sainsburys-logo {
    margin-left: auto;
    margin-right: auto;
  }

  .middle-section {
    text-align: center;
  }

  .tc-checkbox {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.pr-postcode-and-nectarcard {
  &__input {
    .ln-c-input-group {
      @include ln-respond-to("sm") {
        width: 50%;
      }
    }

    .ln-c-label {
      font-size: 1.05em;
      margin-bottom: 0;
    }

    .additional-label {
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 0.5rem;
      display: inline-block;
    }

    &-nectarcard {
      @include nectar-card-input;

      margin-bottom: 0;
    }
  }
}

.pr-confirm-changes__submit-btn {
  width: 100%;
  @include ln-respond-to("sm") {
    width: 50%;
  }
}
