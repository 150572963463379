.slot-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 7px;

  @include ln-respond-to("md") {
    margin-top: 0;
  }

  td,
  th {
    box-sizing: border-box;
  }

  &__date-header {
    font-size: 16px;
  }

  &__wrapper {
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    overflow-y: visible;
    position: relative;
    display: flex;
  }

  &__sticky-col {
    left: 0;
    min-width: 131px;
    max-width: 131px;
    background-color: #f2f2f2;
    z-index: 2;
    text-align: left;

    h4 {
      font-weight: 400;
      position: relative;
      bottom: 2px;
      font-size: 16px;
      margin-bottom: 0;
      font-family: sans-serif;
    }

    &-time-heading {
      margin-bottom: 5px;
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-left: 9px;
      height: 54px;

      @include ln-respond-to("sm") {
        font-size: 20px;
        margin-left: 0;
      }

      @include ln-respond-to("md") {
        font-size: 32px;
        margin-left: 0;
      }
    }
  }

  &__xmas-icon {
    display: flex;
    margin: 0 4px 0 4px;
    vertical-align: middle;
    @media (min-width: 960px) and (max-width: 1203px) {
      display: none;
      visibility: hidden;
    }
  }

  &__xmas-img {
    width: 25px;
  }
}
