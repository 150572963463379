.order-summary {
  ul {
    margin-bottom: calc(#{$ln-space} * 2);
    margin-top: calc(#{$ln-space} * 1 / 2);
  }

  li {
    margin-left: calc(#{$ln-space} * 2);
  }

  img {
    margin-left: $ln-space;
  }

  display: flex;
  flex-direction: column;

  @include ln-respond-to("sm") {
    flex-direction: row;
    justify-content: space-between;
  }

  @include ln-respond-to("lg") {
    flex-direction: column;
  }

  &__details {
    flex-direction: column;
    width: 100%;

    @include ln-respond-to("sm") {
      width: 324px;
    }

    @include ln-respond-to("lg") {
      width: 347px;
    }
  }

  &__slot-pricing {
    display: flex;
    align-items: center;
  }

  &__chevron {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: $ln-color-dark;
  }

  &__item {
    display: flex;
    justify-content: space-between;

    &:nth-last-child(1) {
      border-top: solid 1px $ln-color-grey-v-light;
    }
  }

  &__payment-information {
    display: flex;
    justify-content: center;
  }

  &__savings {
    background-color: #eaf2eb;
    padding: 6px;
    text-align: center;
    font-family: "Arial";
    color: #246127;
  }

  &__nectar-information {
    display: flex;
    border-top: solid 1px $ln-color-grey-v-light;

    @include ln-respond-to("sm") {
      border-top: none;
    }

    @include ln-respond-to("lg") {
      border-top: solid 1px $ln-color-grey-v-light;
    }

    &--logo {
      width: 50px;

      img.logo-image {
        margin-left: 0;
      }
    }
  }
}
