.xmas-booking-confirmation {
  &__section {
    background: $ln-color-white;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    margin-bottom: $ln-space;
    max-width: 688px;
    padding: 16px;
    width: 100%;
  }

  &__container {
    align-items: center;
    padding-bottom: 24px;
    @include ln-respond-to("max-sm") {
      padding-bottom: 16px;
    }
  }

  &__container-title {
    margin-bottom: 0;
    align-self: flex-start;

    @include ln-respond-to("max-sm") {
      margin-bottom: 16px;
    }
  }

  &__container-subtitle {
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 18px;
    text-align: center;
  }

  &__container-grid {
    display: flex;

    @include ln-respond-to("max-sm") {
      flex-direction: column;
    }
  }

  &__container-text-info {
    text-align: center;
    margin-bottom: 0;
    font-size: 14px;
    font-family: Arial;

    @include ln-respond-to("max-sm") {
      text-align: left;
      margin-left: 48px;
      margin-right: 40px;
    }
  }

  &__container-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 20%;
    margin-top: 24px;
    margin-bottom: 24px;

    @include ln-respond-to("max-sm") {
      align-items: flex-start;
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  &__container-grid-item:nth-of-type(-n + 2) {
    margin-right: 16px;

    @include ln-respond-to("max-sm") {
      margin-right: 0;
    }
  }

  &__container-grid-icon {
    color: $ln-color-orange;
    font-size: 32px;

    @include ln-respond-to("max-sm") {
      margin-right: 16px;
      font-size: 25px;
    }
  }

  &__container-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include ln-respond-to("max-sm") {
      flex-direction: row;
    }
  }

  &__button-shop-xmas {
    width: 257px;
  }
}
