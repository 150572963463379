.date-picker {
  &__wrapper {
    position: relative;
    height: 100%;
    margin-right: 36px;
    overflow: visible;
  }

  &__container {
    display: flex;
    background: transparent;
    color: $ln-color-dark;

    &:hover,
    &:focus {
      color: $ln-color-orange-dark;
    }
  }

  &__label {
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 0;
    margin-left: 0.375rem;
  }

  &__calendar {
    position: absolute;
    top: 28px;
    z-index: 10;

    .ds-c-datepicker__dialog {
      margin-top: 0;
    }

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
    }
  }
}
