.pd__dietary-profile {
  margin-bottom: 1.5rem;

  h3 {
    font-size: 1.125rem;
  }

  p {
    font-size: 0.875rem;
  }
}

.outlined {
  label {
    border: 1px solid #8e8e8e;
  }

  &-active {
    label {
      border-color: #f06c00;
    }
  }
}

.dietary-modal {
  z-index: 10;
  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__link {
    padding-top: 20px;
    color: rgba($ln-color-grey-dark, 0.5);
    text-decoration: none;
  }

  &__toggle {
    margin-bottom: 20px;
  }

  &__legal {
    a {
      font-weight: bold;
    }
  }

  &__title {
    font-size: 40px;
    text-align: center;
    &.reduced {
      margin: 0 auto 1.5rem;
      max-width: 500px;
    }
  }

  &__content {
    display: flex;
    &--centered {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 504px;
      margin: 0px auto;
      div {
        max-width: 100%;
      }
    }
    &--legal {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 622px;
      margin: 20px auto 0;
      div {
        max-width: 100%;
      }
    }
  }

  &__example {
    border-radius: 3px;
    margin: 0 10px 0 20px;
    min-width: 200px;
    display: none;
    flex: 0 !important;
    img {
      width: 100%;
    }
    @include ln-respond-to("sm") {
      display: block;
    }
  }

  .ln-c-modal__body {
    max-width: 850px;
    padding: 50px 50px 20px;

    .privacy-policy {
      label {
        margin: 0px auto;
      }
    }

    .ln-c-button {
      &--centered {
        display: block;
        margin: 0px auto;
        width: 280px;
      }
      &--left {
        margin: 0px;
        width: 200px;
      }
      &--transparent {
        padding: 20px;
        margin-bottom: 10px;
        width: 200px;
      }
    }

    .conditions {
      ul {
        display: -ms-grid;
        display: grid;
        -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; /* adjusted */
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        -ms-grid-columns: 33%;
        grid-auto-columns: 33%;
        grid-auto-flow: column;
      }
    }

    .diet {
      margin-bottom: 20px;
      width: 190px;
      padding: 10px;
      border-top: 1px solid #7f0442;
      border-bottom: 1px solid #7f0442;
    }
  }

  @include ln-respond-to("max-sm") {
    &__title {
      font-size: 20px;
      text-align: left;
    }

    &__content {
      &--centered {
        margin: 0px;
        text-align: left;
        align-items: flex-start;
      }
    }

    &__legal {
      text-align: left;
    }

    &__bottom {
      padding-top: 10px;
      width: 100%;
      display: block;
    }
    &__link {
      padding-top: 5px;
      display: inline-block;
      width: 100%;
    }
    .ln-c-modal__body {
      padding: 30px 30px 20px;
      .ln-c-button {
        &--centered {
          margin: 0px 0 10px;
          width: 100%;
        }
        &--left {
          width: 50%;
        }
      }
      .conditions {
        margin-bottom: 0;
        ul {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
