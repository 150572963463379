.slot-details {
  line-height: 200%;

  &__expiry,
  &__user-message {
    line-height: 150%;
    & ~ .slot-details__green-delivery-slot {
      margin-top: 0.5rem;
    }
  }

  .green-delivery-slot {
    &__container {
      color: $ln-color-success;
    }

    &__link {
      color: $ln-color-success;
      line-height: 150%;
      &:hover {
        color: $ln-color-orange;
      }
    }
  }
}

.slot-details-link {
  text-decoration: underline;
  cursor: pointer;
  background-color: unset;
  color: $ln-color-monochrome-dark;
  line-height: 200%;
}

.slot-details-link:hover,
.slot-details-link:focus {
  color: $ln-color-orange-dark;
}
