.clpWarningContainer {
  border: 2px solid #000;
  margin-bottom: 15px;
  padding: 17px;
  position: relative;
}

.clpWarningContainer h4 {
  font-size: 1.8em;
  font-weight: 700;
  margin-bottom: 0.4em;
}

.clpWarningContainer h5 {
  font-size: 1.3em;
  font-weight: 700;
  margin-bottom: 1em;
}

.clpWarningContainer .iconGhsPictogram {
  background-image: url("./views/ProductDetails/ProductDetailsDetails/LegacyWarningContainer/sprite-ghs-pictograms.png");
  background-repeat: no-repeat;
  display: inline-block;
  height: 80px;
  left: 17px;
  overflow: hidden;
  position: absolute;
  top: 17px;
  width: 80px;
}

.clpWarningContainer .clpExplodingBomb {
  background-position: 0 0;
}

.clpWarningContainer .clpFlame {
  background-position: -80px 0;
}

.clpWarningContainer .clpFlameOverCircle {
  background-position: -160px 0;
}

.clpWarningContainer .clpGasCylinder {
  background-position: -240px 0;
}

.clpWarningContainer .clpCorrosion {
  background-position: -320px 0;
}

.clpWarningContainer .clpSkullCrossbones {
  background-position: -400px 0;
}

.clpWarningContainer .clpExclamationPoint {
  background-position: -480px 0;
}

.clpWarningContainer .clpHealthHazard {
  background-position: -560px 0;
}

.clpWarningContainer .clpEnvironmental {
  background-position: -640px 0;
}

.clpWarningContainer .pictogram2 {
  top: 107px;
}

.clpWarningContainer .pictogram3 {
  top: 198px;
}

.clpWarningContainer .pictogram4 {
  top: 289px;
}

.clpWarningContainer .multiplePictograms {
  padding-left: 100px;
}

.multiplePictograms2 {
  min-height: 170px;
}

.multiplePictograms3 {
  min-height: 260px;
}

.multiplePictograms4 {
  min-height: 350px;
}

.access,
.js .jsAccess {
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}
