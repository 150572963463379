.cnc {
  &__title {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 12px;

    @include ln-respond-to("sm") {
      font-size: 2.5rem;
      margin-bottom: $ln-space;
    }

    @include ln-respond-to("max-sm") {
      margin-bottom: $ln-space;
      margin-top: calc(#{$ln-space} * 2.5);
      font-size: 24px;
    }
  }
}
