.order-confirmation {
  .modal-button {
    width: 100%;
    max-width: 209px;
  }

  &__button {
    width: 100%;
    max-width: 480px;

    @include ln-respond-to("sm") {
      width: 288px;
    }
  }

  &__header {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("max-sm") {
      margin-bottom: $ln-space;
    }
  }

  &__order-details {
    &-button-wrapper {
      width: 100%;
      max-width: 288px;

      @include ln-respond-to("max-sm") {
        max-width: 480px;
      }
    }

    &-button-container {
      display: flex;
      flex-direction: column;
      column-gap: calc(#{$ln-space} * 2);
      row-gap: calc(#{$ln-space} * 2);
      width: 100%;

      @include ln-respond-to("xs") {
        flex-direction: row;
        margin-bottom: 0;
      }
    }
  }

  &__order-summary {
    &-details {
      flex-direction: column;
      width: 100%;

      @include ln-respond-to("sm") {
        width: 324px;
      }

      @include ln-respond-to("lg") {
        width: 347px;
      }
    }

    &-item {
      display: flex;
      justify-content: space-between;
    }

    &-colleague-discount {
      @include ln-font("body-1");
      background-color: #e8f1fb;
      padding: 12px 0px 12px 0px;
      border: none;

      &:before {
        left: 0;
      }

      svg {
        margin-left: $ln-space;
        margin-right: $ln-space;
        float: left;
        color: $dark-grey-hex;
        font-size: 18px;
      }

      &-text {
        display: table-cell;
        color: #135ca3;
      }
    }
  }

  .slot-details__container {
    line-height: 2;

    .green-delivery-slot {
      &__container {
        color: $ln-color-success;
      }

      &__link {
        color: $ln-color-success;
        line-height: 1.5;

        &:hover {
          color: $ln-color-orange;
        }
      }
    }
  }

  &__magnolia {
    max-width: 720px;

    // Hard patch until we bump the bolt && bolt-cms-components component (which requires upgrade to React 18)
    > div > div {
      margin-left: 0;
      max-width: 100%;
    }
  }

  .rokt-wrapper:not(:empty) {
    margin-bottom: $ln-space;
  }
}

.order-savings {
  list-style-type: none;
  margin: 0;
  display: grid;

  @include ln-respond-to("sm") {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  p {
    margin-bottom: 0;
  }

  &__item {
    @include ln-respond-to("max-sm") {
      padding: calc(#{$ln-space} * 1.5) calc(#{$ln-space} / 2);
    }

    @include ln-respond-to("sm") {
      padding-top: $ln-space;
      text-align: center;
    }

    & + & {
      @include ln-respond-to("max-sm") {
        border-top: 1px solid $ln-color-grey-light;
      }

      @include ln-respond-to("sm") {
        border-left: 1px solid $ln-color-grey-light;
      }
    }

    img {
      height: 72px;

      @include ln-respond-to("sm") {
        margin-bottom: $ln-space;
      }
    }
  }

  &__info {
    @include ln-respond-to("max-sm") {
      display: flex;
      align-items: baseline;
    }
  }

  &__amount {
    font-size: 18px;

    @include ln-respond-to("max-sm") {
      margin-right: calc(#{$ln-space} / 2);
    }

    @include ln-respond-to("sm") {
      margin-bottom: calc(#{$ln-space} / 2);
      font-size: 22px;
      font-family: $ln-font-family-brand;
    }
  }
}

.register-nectar {
  display: grid;

  @include ln-respond-to("xs") {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffcd00;
    min-height: 160px;

    @include ln-respond-to("xs") {
      order: 1;
    }

    img {
      width: 180px;

      @include ln-respond-to("xs") {
        width: 234px;
      }
    }
  }
}

.join-nectar {
  &__logo {
    background-color: $nectar-colour;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include ln-respond-to("sm") {
      height: 190px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      flex-direction: row;
      justify-content: center;
      flex-basis: auto;
      flex-grow: 1;
    }
  }

  &__button {
    @include ln-respond-to("sm") {
    }
  }
}
