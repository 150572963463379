.footer {
  &__link {
    color: $ln-color-white;
    margin-bottom: 0.9rem;
    text-decoration: none;
    background: transparent;
    text-align: left;

    @include ln-font("body-1");

    &:hover,
    &:focus,
    &:visited {
      color: $ln-color-white;
      text-decoration: underline;
    }
  }

  &__icons {
    text-align: center;

    .sainsburys-logo,
    .nectar-logo {
      display: inline-block;
      padding: 0 10px;
    }
  }

  &__link:last-child {
    margin: 0;
  }

  &__links {
    display: flex;
    flex-direction: column;
    text-align: start;
  }

  @include ln-respond-to("sm") {
    display: block;

    &__link {
      margin-bottom: 0;
      margin-right: 1.5rem;
      white-space: nowrap;
    }

    &__links {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

.app-layout-footer-icons {
  background-color: $ln-color-white;
}
