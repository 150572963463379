.pending-slot-grid {
  &__pending {
    display: flex;
  }

  &__slot {
    align-items: center;
    background: $ln-color-white;
    border: solid 2px $ln-color-white;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 54px;
    margin: 0 2%;
    margin-bottom: 5px;
    padding: 16px;
    position: relative;
    width: 96%;
    justify-content: space-between;

    @include ln-respond-to("sm") {
      justify-content: center;
      text-align: center;
    }

    &:disabled {
      opacity: 1;
    }

    &:hover,
    &:focus {
      border-color: $ln-color-orange;
    }

    &--hidden {
      background: transparent;
      border-color: $ln-color-grey-v-light;
      cursor: unset;
      float: left;
      font-family: $ln-font-family-brand;
      font-size: 18px;
      font-weight: 800;
      padding: 15px 0;
      text-transform: capitalize;

      &:hover,
      &:focus {
        border-color: $ln-color-grey-v-light;
      }

      @include ln-respond-to("md") {
        display: flex;
        align-items: center;
        font-family: $ln-font-family-base;
      }
    }

    &-pending {
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &-placeholder {
      height: 12px;
      width: 24px;
      background-color: $ln-color-black;
      animation: pulse 1.5s infinite ease-in-out;

      @keyframes pulse {
        0% {
          opacity: 0.2;
        }

        25% {
          opacity: 0.35;
        }

        100% {
          opacity: 0.2;
        }
      }
    }
  }

  &__time {
    float: left;
    width: 123px;
    position: relative;
    margin-right: $ln-space;
  }

  &__hour {
    height: 54px;
    margin: 5px 0;
    padding: 16px 0;
  }

  &__day-time {
    font-family: $ln-font-family-brand;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 0.7;
    max-width: 1440px;
    position: relative;
    text-align: left;
    text-transform: capitalize;
    z-index: 2;
    padding: 16px 0;
    margin: 5px 5px 8px 0;

    @include ln-respond-to("md") {
      line-height: 0.45;
    }
  }
}

.grid-tablet-calendar-button {
  min-height: $ln-space-x3;
  margin: 10px 0;

  &__pending {
    display: flex;
    align-items: center;
  }
}
