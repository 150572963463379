.mealdeal-join-nectar-box {
  grid-area: bundlebox;
  @include ln-respond-to("max-md") {
    margin: 0;
  }

  background-color: $ln-color-white;

  &__header {
    align-items: center;
  }

  .ln-c-accordion {
    &__icon {
      margin-right: $ln-space;
    }

    &.is-open .ln-c-accordion__body {
      margin-top: -8px;
      padding-top: 0;

      .mealdeal-join-nectar-box__button-group {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-top: $ln-space-x2;

        &__add-nectar-button {
          font-size: 16px;
          margin-bottom: $ln-space-x2;
        }
      }
    }
  }
}
