.collapsable {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.collapsable--show-more {
  font-family: var(--ds-font-family-brand);
  font-weight: var(--ds-font-weight-extra-bold);
  font-size: var(--ds-font-size-label-2);
  background-color: transparent;
  padding: 0.75rem 1rem;
  transition: color ease-in-out 0.125s;
}

.collapsable--show-more:hover,
.collapsable--show-more:focus,
.collapsable--show-more:active {
  color: var(--ds-color-interaction-primary-hover);
}
