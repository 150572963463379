.FBT_v2 {
  border-top: 1px solid #d8d8d8;
  margin-top: 24px;
  padding-top: 23px;
  width: 100%;

  @include ln-respond-to("lg") {
    border-top: none;
    border-left: 1px solid #d8d8d8;
    padding-top: 0;
    padding-left: 28px;
    margin-top: 0;
    margin-left: 24px;
    width: 353px;
  }

  &__title {
    font-size: 22px;
    width: 100%;
  }

  &__productCards {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 20px;

    @include ln-respond-to("sm") {
      flex-direction: row;
      gap: 40px;
    }

    @include ln-respond-to("lg") {
      gap: 32px;
      flex-direction: column;
    }

    .FBT_v2__productCard {
      @include ln-respond-to("sm") {
        max-width: fit-content;
      }
    }

    .FBT_v2__skeletonCard {
      height: 114px;
      width: 100%;

      @include ln-respond-to("sm") {
        height: 250px;
      }
      @include ln-respond-to("md") {
        height: 114px;
      }
    }
  }
}
