.recipes-share-modal {
  .share-social-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 468px;
    height: 60px;

    div {
      min-width: 40px;
      float: center;
      :hover {
        cursor: pointer;
      }

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 33px;
        height: 33px;
      }
    }
  }
}
