.content-wrap {
  &--padding {
    margin: 0 auto;
    max-width: 77.5rem;
    padding-left: $ln-space-x2;
    padding-right: $ln-space-x2;

    @include ln-respond-to("max-sm") {
      padding-left: $ln-space;
      padding-right: $ln-space;
    }
  }
}
