.StartShopping {
  grid-area: card;
  grid-template-columns: repeat(5, 1fr);

  @include ln-respond-to("max-xs") {
    margin-left: -1rem;
    margin-right: -1rem;
    width: unset;
  }

  grid-template-areas:
    "ynp-logo ynp-logo title title title"
    "ynp-logo ynp-logo title title title";

  @include ln-respond-to("xs") {
    grid-template-areas:
      "ynp-logo title title title ."
      "ynp-logo title title title .";
  }

  @include ln-respond-to("ss") {
    grid-template-areas:
      "ynp-logo title title title title"
      "ynp-logo title title title title";
  }

  @include ln-respond-to("md") {
    min-height: 400px;
  }

  &--ynp-logo-container {
    grid-area: ynp-logo;
    display: flex;
    align-items: center;

    @include ln-respond-to("sm") {
      justify-content: left;
    }
  }

  &--ynp-logo {
    @include ln-respond-to("sm") {
      max-width: 120px;
    }

    @include ln-respond-to("md") {
      align-self: center;
      justify-self: center;
      max-width: 160px;
    }

    @include ln-respond-to("lg") {
      max-width: 250px;
    }
  }

  &--title-container {
    grid-area: title;
    display: flex;
    align-items: center;
  }

  &--title {
    font-family: MaryAnn, "Trebuchet MS", sans-serif;
    color: var(--ds-color-palette-orange);
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin: 0px;

    @include ln-respond-to("xs") {
      font-size: 26px;
      line-height: 32px;

      justify-content: flex-start;
      align-items: center;
    }

    @include ln-respond-to("md") {
      font-size: 28px;
      line-height: 36px;
    }
  }
}
