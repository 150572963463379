$CYSM-card-gap: 24px;
$CYSM-card-gap-desktop: 40px;

.CYSM {
  & .CYSMCard:last-child {
    margin-top: 0px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    row-gap: $CYSM-card-gap;
  }

  @include ln-respond-to("md") {
    &__cards {
      gap: $CYSM-card-gap-desktop;
    }
  }

  @include ln-respond-to("md") {
    &__cards {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
