.FallbackMessage {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  top: 50px;

  @include ln-respond-to("min-md") {
    top: 115px;
  }

  &--heading {
    font-size: 32px;
    margin-bottom: 12px;
  }

  &--text {
    @include ln-respond-to("min-md") {
      font-size: 14px;
    }

    font-size: 16px;
    margin-bottom: 24px;

    &-separator {
      display: none;
      @include ln-respond-to("min-sm") {
        display: inline;
      }
    }
  }

  &--link {
    width: 283px;
    box-sizing: border-box;
    margin: auto;
  }
}
