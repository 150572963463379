.book-slot-grid {
  &__pending {
    display: flex;
  }

  &__day {
    list-style: none;

    &-mobile {
      list-style: none;
      padding-top: 20px;
    }

    &-header {
      font-family: $ln-font-family-base;
      font-size: 16px;
      margin: 10px 0;
      text-align: center;
    }
  }

  &__slot-full {
    color: #404040;
  }

  &__time {
    float: left;
    width: 123px;
    position: relative;
    margin-right: $ln-space;
  }

  &__hour {
    height: 54px;
    margin: 5px 0;
    padding: 16px 0;
  }

  &__day-time {
    font-family: $ln-font-family-brand;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 0.7;
    max-width: 1440px;
    position: relative;
    text-align: left;
    text-transform: capitalize;
    z-index: 2;
    padding: 16px 0;
    margin: 5px 5px 8px 0;

    @include ln-respond-to("md") {
      line-height: 0.45;
    }
  }

  &__arrow {
    background: transparent;
    border: solid 1px $ln-color-grey;
    border-radius: 2px;
    color: rgba($ln-color-black, 0.75);
    font-family: $ln-font-family-base;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: 48px;
    letter-spacing: normal;
    line-height: 1.5;
    padding: 12px 12px 12px 18px;
    position: relative;
    text-align: center;
    width: 104px;

    &:hover,
    &:focus {
      background-color: rgba($ln-color-black, 0.08);
      color: $ln-color-black;
    }

    .ln-c-icon {
      left: 5px;
      position: absolute;
      top: 13px;
    }

    &--right {
      padding: 12px 18px 12px 12px;

      .ln-c-icon {
        left: auto;
        right: 5px;
      }
    }
  }
}

.grid-mobile-navigation {
  background: $ln-color-white;
  border-bottom: 1px solid $ln-color-grey-light;
  border-top: 1px solid $ln-color-grey-light;
  height: 64px;
  margin-left: -1rem;
  padding: 10px 0;
  padding-left: 54px;
  width: -webkit-calc(100% + 2rem);
  width: -moz-calc(100% + 2rem);
  width: calc(100% + 2rem);
  z-index: 4;
  position: relative;

  &__buttons-container {
    overflow-x: scroll;
    display: flex;
    scroll-behavior: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  &__day {
    border: solid 2px $ln-color-white;
    border-radius: 2px;
    font-size: 0.8rem;
    min-height: 44px;
    text-align: center;
    min-width: 120px;
    background: transparent;
    color: $ln-color-dark;

    &:focus {
      box-shadow: none;
      outline: none;
      text-decoration: underline;
    }

    @media (min-width: 370px) {
      font-size: 1rem;
      line-height: 1.3rem;
    }

    &--selected {
      border-color: $ln-color-orange;
    }
  }

  &__time-of-day {
    display: flex;
    align-items: center;
    height: $ln-space-x7;
    background-color: $ln-color-light;
    font-family: $ln-font-family-brand;
    margin-top: 8px;
    position: absolute;
    left: 0;
    width: 100%;
    font-size: 18px;
    font-weight: 800;
    padding-left: calc(1rem + 9px);
    text-transform: capitalize;
  }
}

.grid-mobile-navigation-calendar-button {
  position: absolute;
  left: 0;
  background-color: $ln-color-white;
  height: calc(100% - 16px);
  width: 54px;

  &__pending {
    padding: $ln-space-x2;
  }
}

.grid-tablet-calendar-button {
  min-height: $ln-space-x3;
  margin: 10px 0;

  &__pending {
    display: flex;
    align-items: center;
  }
}

.grid-swiper-container {
  margin: 0;
}
