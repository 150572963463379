.recipe-search-hoc-homepage {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;

  .search-container {
    width: 100%;
    padding: $ln-space-x3 $ln-space-x4;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    box-sizing: border-box;
    margin: $ln-space-x5;
    @media (max-width: 960px) {
      margin: $ln-space-x2;
    }
    @media (max-width: 720px) {
      h1 {
        font-size: 2rem;
      }
      margin: 8px;
    }
    @media (max-width: 375px) {
      h1 {
        font-size: 1.8rem;
      }
      margin: 8px;
    }
  }

  > img {
    z-index: -1;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
