$mobile-break-at: "xs";
$mobile-break-at-max: "max-xs";

.recipes-basket-status-modal {
  #recipes-basket-status-inner-wrap {
    max-width: 420px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

// Styles which have custom values that need tweaking for swap modal
.recipes-select-modal.ln-c-modal__body {
  #recipes-select-inner-wrap {
    @include ln-respond-to($mobile-break-at) {
      max-height: min(950px, calc(100vh - 2 * $ln-space-x2));
    }
  }

  .recipes-modal-header#recipes-select-header {
    padding-bottom: 0;

    .recipes-select-header-primary {
      height: 40px;
    }

    .recipes-select-header-secondary {
      padding-top: $ln-space-x2;

      .portion-selector {
        width: 150px;
        margin-top: $ln-space;

        .ln-c-form-group {
          margin: 0px;
        }
      }

      @include ln-respond-to($mobile-break-at) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .portion-selector {
          margin-top: 0px;
        }
      }
    }
  }

  .recipes-modal-content {
    .recipes-modal-subsection {
      padding: 0 $ln-space-x2;
    }
  }

  .recipes-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;

    @include ln-respond-to($mobile-break-at-max) {
      justify-content: start;
      flex-direction: column;
      gap: $ln-space;
    }
    .recipes-modal-total-price {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: start;

      @include ln-respond-to("sm") {
        flex-direction: row;
        gap: $ln-space-x2;
        align-items: baseline;
        justify-content: start;
      }
    }

    .atb-button {
      @include ln-respond-to($mobile-break-at-max) {
        flex-grow: 1;
        width: 100%;
      }
      .recipes-modal-submit-button {
        @include ln-respond-to($mobile-break-at) {
          width: 250px;
        }
      }
    }
  }
}
