$paragraph-max-width: 760px;

.nectarDestination {
  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    display: inline-flex;
    width: 100%;
    text-align: center;
  }

  &-nectar-associated {
    &__add-all {
      text-align: center;
      padding-bottom: 16px;

      @include ln-respond-to("max-mobile-sm", $gol-breakpoints) {
        padding-bottom: 0;
      }

      &-btn {
        width: 343px;

        @include ln-respond-to("max-mobile-sm", $gol-breakpoints) {
          width: 288px;
          font-size: 18px;
          padding: 8px;
        }
      }
    }
  }

  &-nectar-not-associated {
    &__section {
      max-width: $paragraph-max-width;
      width: 100%;
    }
  }

  &__getStartedWithNectarCard {
    max-width: unset;
  }
}
