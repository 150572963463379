$content-padding: 1rem;
$nectar-background-colour: #f3e9ff;
$nectar-background-colour-hover: #dfcef5;
$offer-background-colour: #fbe6e6;
$offer-background-colour-hover: #eebdbd;
$offer-colour: #d50000;
$offer-colour-darker: #8d0000;
$nectar-colour: #8223fa;
$nectar-colour-darker: #4a0983;
$nectar-bonus-color-text: $nectar-colour;
$nectar-bonus-color-background: #f3e9ff;
$nectar-text-colour: #6c18cb;
$nectar-link-background-color: rgba(198, 154, 253, 0.4);
$tag-width: 98px;
$ynp-tag-width: 133px;
$basket-controls-width: 168px;

@mixin priceText {
  font-weight: 800;
  font-family: $ln-font-family-brand;
  margin-right: 8px;
}

.product-header {
  color: $ln-color-black;
  font-family: $ln-font-family-brand;
  font-size: 12px;
  font-weight: 600;
  text-align: left;

  &--citrus {
    margin: $ln-space-x2 0;
    font-weight: 700;
    font-size: 12px;
    height: $ln-space-x3;
  }

  &--mobile-wrapper {
    @include ln-respond-to("max-xs") {
      margin: $ln-space $ln-space-x2 -12px;

      .product-header--citrus {
        margin: unset;
      }
    }
  }

  &--offer {
    color: white;
    background-color: #d70000;
    margin-top: 16px;
    width: 64px;
    font-size: 14px;
    font-family: arial;
    border-radius: 0px 2px 2px 0px;
    padding-left: 0px !important;
    text-align: center !important;
    line-height: 24px;
    width: 50px;
    height: 24px;

    &--nectar {
      background-color: $nectar-colour;
      width: $tag-width;
    }

    &--tagless {
      background-color: transparent;
      width: $tag-width;
    }

    &--bonus {
      background-color: $nectar-bonus-color-background;
      color: $nectar-bonus-color-text;
      width: $tag-width;
    }
  }
}

.promotional-tag {
  @extend .product-header;
  @extend .product-header--offer;
  display: block;
  margin: 0;

  @media only screen and (max-width: 480px) {
    &--mobile {
      border-radius: 2px;
      margin-top: 0px;
    }
  }

  &--nectar {
    @extend .promotional-tag;

    background-color: $nectar-colour;
    width: $tag-width;
  }

  &--your-nectar-price {
    @extend .promotional-tag--nectar;
    width: $ynp-tag-width;
    &--optimised {
      @media (min-width: 960px) and (max-width: 1024px) {
        width: 120px;
        line-height: 16px;
        padding: 5px 0;
      }
    }
  }
}

.promotion-message {
  margin: 0;

  @include ln-respond-to("min-xs") {
    margin: 0 auto;
  }

  &__link {
    @include ln-font("caption");

    color: $nectar-colour;
    font-size: 14px;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: $nectar-colour-darker;
      text-decoration: underline;
      background-color: $nectar-link-background-color;
    }

    &:visited {
      color: $nectar-colour-darker;
    }

    &--missed {
      display: flex;
      align-items: center;
      padding: $ln-space-half $ln-space;
      background-color: $nectar-bonus-color-background;
      border-radius: 2px;
    }
  }

  &__arrow {
    margin-left: auto;
  }

  .promotion-message__link {
    @include ln-font("caption");

    color: $ln-color-red;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &--purple {
      color: $nectar-colour;

      &:visited {
        color: $nectar-colour-darker;
      }
    }
  }
}

a.promotion-message {
  @include ln-respond-to("min-xs") {
    margin: 0 auto 4px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 4px;
  padding: $ln-space-half $ln-space-half $ln-space-half $ln-space;
  font-size: 14px;
  text-decoration: none;

  color: $offer-colour;
  background-color: $offer-background-colour;
  transition-property: background-color, color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: $offer-colour-darker;
    background-color: $offer-background-colour-hover;
    text-decoration: underline;
    cursor: pointer;
  }
  &--nectar {
    color: $nectar-colour;
    background-color: $nectar-background-colour;

    &:hover,
    &:active,
    &:focus {
      color: $nectar-colour-darker;
      background-color: $nectar-background-colour-hover;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  & .arrow {
    align-self: flex-start;

    &__arrow {
      margin-left: auto;
      font-size: 14px;
    }
  }
}

.down-arrow {
  width: 40px;
  height: 40px;
  background-color: $ln-color-white;
  position: absolute;
  bottom: -20px;
  left: 50%;
  z-index: -1;
  opacity: 0;
  transform: translateX(-50%) rotate(45deg);

  &--visible {
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
  }

  &--hidden {
    opacity: 0;
    height: 0px;
    width: 0px;
  }
}

.pt-card {
  position: relative;

  @include ln-respond-to("min-xs") {
    position: relative;
    max-width: 100%;
  }
}

.pt {
  background-color: $ln-color-white;
  display: flex;
  padding: 0;
  text-align: left;
  width: 100%;

  @keyframes fadelines {
    0% {
      opacity: 0.8;
    }

    50% {
      opacity: 0.1;
    }
  }

  &-image {
    &__ribbon-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__unavailable-sticker {
      bottom: 0;
      background-color: $ln-color-black;
      text-align: center;
      color: $ln-color-white;
      font-weight: 500;
      line-height: initial;
      padding: 4px;
      width: 100%;
      border-radius: 2px;
      pointer-events: none;
      flex: 33%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      &:hover,
      &:focus,
      &:active {
        cursor: default;
      }
    }
  }

  &__xmas-notification-wrapper {
    @include ln-respond-to("xs") {
      width: 168px;
      margin: 0 auto;
      padding-bottom: 16px;
    }
  }

  &__badges {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    margin-bottom: var(--ds-size-space-2);
  }

  &__loading-indicator-tile {
    flex-flow: wrap;
    flex-direction: column;
    align-content: center;

    &--md.pt-grid-item {
      display: none;
    }

    &--lg.pt-grid-item {
      display: none;
    }

    &__content {
      background-color: $ln-color-white;
      padding: 30px 20px 70px calc(8vw + 50px);

      & .line {
        height: 14px;
        background-color: $ln-color-grey-light;
        display: block;
        margin-bottom: 8px;
        opacity: 0.8;
        animation: fadelines 4s infinite;
      }

      & .line:nth-child(3) {
        margin-right: 25%;
      }
    }
  }

  @include ln-respond-to("min-xs") {
    &__loading-indicator-tile {
      &--md.pt-grid-item {
        display: flex;
      }

      &__content {
        padding: 175px 0 70px 0;
        display: flex;
        flex-direction: column;
        height: 372px;

        & .line {
          width: 80%;
        }

        & .line:nth-child(3) {
          width: 50%;
          margin: 0;
        }
      }
    }
  }

  @include ln-respond-to("min-sm") {
    &__loading-indicator-tile {
      &--md.pt-grid-item {
        display: flex;
      }

      &__content {
        & .line {
          width: 50%;
        }

        & .line:nth-child(3) {
          width: 25%;
          margin: 0;
        }
      }
    }
  }

  @include ln-respond-to("min-md") {
    &__loading-indicator-tile {
      &--md.pt-grid-item {
        display: flex;
      }

      &--lg.pt-grid-item {
        display: flex;
      }

      &__content {
        & .line {
          width: 80%;
        }

        & .line:nth-child(3) {
          width: 50%;
        }
      }
    }
  }

  &__header {
    margin-bottom: 8px;

    @include ln-respond-to("min-xs") {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  & .product-details-title__tag {
    top: 0;
    left: 0;
    position: absolute;
    @include ln-respond-to("max-ss") {
      position: relative;
      margin-top: 0px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    justify-content: space-between;
    padding: $content-padding $content-padding;
    @include ln-respond-to("min-xs") {
      padding: ($content-padding * 4) $content-padding $content-padding;
    }
    &--optimised {
      @include ln-respond-to("min-xs") {
        $content-padding: 0.5rem;
        padding: ($content-padding * 7) $content-padding $content-padding;
        min-width: 200px;
      }
      @include ln-respond-to("max-xs") {
        $content-padding: 0.5rem;
        padding: ($content-padding * 2) $content-padding $content-padding;
      }
      @media (min-width: 960px) and (max-width: 1024px) {
        min-width: 100% !important;
      }
    }
    &--reduced-padding {
      $content-padding: 0.5rem;
      padding: ($content-padding * 7) $content-padding $content-padding !important;
      @include ln-respond-to("min-xs") {
        min-width: 200px;
      }
    }
  }

  &__position-observer {
    display: inherit;
    flex-grow: 1;
  }

  &__link {
    flex-direction: column;
    max-width: 100%;
    text-decoration: none;
  }

  &__link:hover {
    text-decoration: underline;
  }

  &__icons {
    &__fav {
      font-size: 20px;
      line-height: 1rem;
      position: absolute;
      right: 6px;
      top: 10px;
      padding: 6px 10px;
      display: flex;
      background-color: transparent;
      &--optimised {
        top: 5px;
        padding: 9.5px;
        @media (min-width: 720px) and (max-width: 1200px) {
          padding: 9.5px 2.5px;
        }
      }

      &.button {
        cursor: pointer;
      }

      &--cta {
        margin: auto;
        line-height: 1rem;
        background-color: transparent;
      }

      &--icon-filled {
        font-size: unset !important;
        color: $ln-color-plum;
      }

      &--icon-empty {
        font-size: unset !important;
        color: $ln-color-plum;
      }

      &:disabled {
        color: $ln-color-grey-light;
        cursor: default;
      }

      & svg {
        pointer-events: none;
        margin: auto;
      }

      .is-open &--tooltip_message {
        padding: calc($ln-space + $ln-space-half) $ln-space-x2;
        white-space: nowrap;
        transition-delay: 0s;
        text-align: center;
        z-index: 1;
      }

      @include ln-respond-to("max-xs") {
        // this is to put the heart at the bottom left of the tile for tiles in a GRID on a mobile viewport,
        // but we need to account for products in a carousel on mobile too.

        // and for frequently bought together on PDP on mobile
        &--fbt,
        .pt-grid-item & {
          right: unset;
          top: unset;
          left: $ln-space-x2;
          bottom: $ln-space-x2;
          padding: unset;
          height: 48px;
          width: 48px;
          padding-top: 20px;
        }
      }
    }
  }

  &__label {
    &-serves {
      margin-bottom: var(--ds-size-space-2);
    }
  }

  &__product-image {
    height: 140px;
    margin-bottom: 8px;
  }

  &__message {
    margin: 0;
    text-align: left;
    width: 180px;

    @include ln-respond-to("min-xs") {
      margin: 0 auto;
    }
  }

  &__wrapper {
    display: inherit;
    flex: 1 1 auto;
    flex-direction: column;

    &-inner {
      display: inherit;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
    }
  }

  &__wrapper__top {
    margin-bottom: auto;
  }

  &__wrapper__bottom {
    margin-bottom: 16px;
    margin-top: auto;

    &-nectar {
      margin-top: 16px;
      margin-bottom: 0;

      .pt__cost {
        margin-bottom: 16px;
      }
    }
  }

  &__info {
    font-family: $ln-font-family-brand;
    margin-bottom: 8px;

    @media only screen and (max-width: 480px) {
      width: 85%;
    }

    &__description {
      @include ln-font("caption");
      font-weight: bold !important;
      margin-bottom: 8px;
      line-height: 1.42857 !important;
    }

    &__review {
      margin-bottom: 8px;
    }
  }

  &__data-entry {
    margin-left: -10px;

    & .form-dropdown {
      margin: 8px auto 16px 12px;
    }

    & .form-radio {
      width: 168px;
      margin-bottom: 0px;
    }

    //* start: override due to unable to use listType inline
    & .form-radio li {
      display: inline-block;
    }

    & .form-radio label {
      font-size: 14px;
      padding: 0.5rem 0.5rem 8px calc(10px + 2rem);
    }

    & .form-radio label:before {
      left: 0.6rem;
    }

    & .form-radio label:after {
      left: calc(0.6rem + 5px) !important;
    }

    /* end */
  }

  &__promotions {
    margin: 0 auto 8px;
  }

  &__promotion {
    list-style-type: none;
  }

  &__cost {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .nectar-price {
      color: #4c4c4c;
    }

    &__unit-price-per-measure {
      @include ln-font("caption");
      display: inline;
      color: $ln-color-grey-dark;
      font-family: inherit;
    }

    &__per-unit {
      @include ln-font("caption");
      color: $ln-color-grey-dark;
      font-family: inherit;
      word-spacing: -1px;
      text-decoration: none;

      &--discount {
        color: initial;
      }
    }

    &__contextual {
      color: $ln-color-grey-dark;
      flex-shrink: 0;
      font-size: 18px;
      font-family: inherit;
      @include priceText;

      & .pt__cost--price {
        text-decoration: line-through;
      }

      &--wrapper {
        width: 100%;
        display: block;
        align-items: baseline;
      }

      &--with-nectar {
        @mixin withNectarText {
          display: inline;
          font-size: 18px;
          text-decoration: none;
          font-weight: 800;
          font-family: $ln-font-family-brand;

          & .pt__cost--price {
            text-decoration: none;
          }
        }

        &-associated {
          @include withNectarText;
        }

        &-not-associated {
          @include withNectarText;
          color: $nectar-colour;
        }

        &__unit {
          color: initial;
        }
      }
    }

    .price-header {
      display: block;
    }

    &__retail-price {
      @include ln-font("display-1");
      color: inherit;
      font-family: inherit;
      font-weight: 800;
      font-size: 22px;
      @include priceText;

      &--with-nectar {
        @mixin withNectarText {
          display: inline;
          font-size: 22px;
          text-decoration: none;
          font-weight: 800;
          font-family: $ln-font-family-brand;

          & .pt__cost--price {
            text-decoration: none;
          }
        }

        &-associated {
          @include withNectarText;
          color: $nectar-colour;
        }

        &-not-associated {
          @include withNectarText;
        }

        &__unit {
          color: initial;
        }
      }

      &__wrapper {
        align-items: baseline;
        font-family: $ln-font-family-brand;
        width: 100%;

        @mixin wrapperExtended {
          @include ln-font("display-1");
          display: block;
          flex-direction: row;
          margin-right: 8px;
        }

        &--nectar {
          @include wrapperExtended;
          color: $nectar-colour;
        }

        &--promo {
          @include wrapperExtended;
          color: $ln-color-red;
        }

        &--no-promo {
          @include wrapperExtended;
          color: $ln-color-grey-dark;
        }
      }
    }
  }

  &__with-or-without-nectar {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: Arial;
    color: #0c0c0c;
    display: inline;
  }

  &__with-nectar-bonus-points-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: Arial;
    color: #8223fa;
    margin-bottom: 3px;
  }

  &__with-nectar-bonus-points {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    font-family: Arial;
    color: #8223fa;
    margin-bottom: 3px;

    &__promotion {
      margin-left: 8px;
    }
  }

  &__add-button {
    height: 48px;
    min-width: $basket-controls-width;

    &--reduced-height {
      min-height: 44px;
      @include ln-respond-to("max-xs") {
        height: 48px;
      }
    }

    @include ln-respond-to("max-sm") {
      width: 100%;
    }
  }

  &__controls {
    margin-top: auto;
    &--optimised {
      min-width: 100%;
      @include ln-respond-to("min-sm") {
        min-width: 100% !important;
      }
      @include ln-respond-to("max-sm") {
        min-width: 100% !important;
      }
    }

    .pt-button__loading {
      height: 34px;
      margin: 10px 0 4px;
    }
  }

  &__nectar-offer {
    margin: 0 auto;
    min-width: 172px;
    margin-top: 1rem;

    .nectar-offer {
      justify-content: center;
    }
  }

  @include ln-respond-to("max-sm") {
    .nectar-offer-mobile {
      width: 100%;
      padding: 0 1rem 1rem;
      align-self: center;
    }
  }

  @include ln-respond-to("min-xs") {
    &__image-wrapper {
      margin-top: 8px;

      .pt-image {
        height: 140px;
        width: 140px;

        &__product {
          height: 140px;
          width: 140px;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }
  }

  @include ln-respond-to("max-xs") {
    flex-direction: column;
    margin-left: -1rem;
    margin-right: -1rem;
    text-align: left;
    width: calc(100% + 2rem) !important;

    &__image-wrapper {
      width: 100px;
      min-height: 185px;
    }

    &__wrapper {
      margin-left: 16px;
      width: 180px;
    }

    &__controls {
      display: flex;
      margin-left: auto;
      min-width: $basket-controls-width;
      text-align: center;

      // modified product tile, controls right side
      &--right {
        & .pt__wrapper {
          display: flex;
        }

        & .pt__wrapper__top {
          margin-bottom: 0;
          margin-right: 12px;
        }

        & .pt__wrapper__bottom {
          margin-bottom: 0;
        }

        & .pt__controls {
          display: flex;
          flex-direction: column-reverse;
          margin-bottom: auto;
          margin-left: auto;
          width: auto;
        }

        & .pt__quantity__value--s {
          font-size: 0.75em;
          line-height: 16px;
        }

        & .pt__quantity__value--xs {
          font-size: 0.7em;
          line-height: 16px;
        }
      }

      @include ln-respond-to("min-xs") {
        margin-top: auto;
      }
    }

    .pt-image {
      &__product {
        height: 100px;
        width: 100px;
      }

      &__link {
        margin-bottom: auto;
      }
    }

    flex-wrap: wrap;

    &__nectar-offer {
      margin: 0 auto;
      min-width: 172px;
      margin-top: 1rem;

      .nectar-offer {
        justify-content: center;
      }
    }
  }

  @include ln-respond-to("min-xs") {
    flex-direction: column;

    &__info {
      margin: 0 auto;
      width: 100%;
    }

    &__promotions {
      display: flex;
      flex-direction: column;
      margin-top: auto;
    }

    &__controls {
      display: flex;
      margin: 0 auto;
      min-width: $basket-controls-width;
      text-align: center;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  @include ln-respond-to("min-sm") {
    &__info {
      margin: 0 auto;
    }
  }

  @include ln-respond-to("xs") {
    &__info {
      margin: 0 auto;
    }
  }

  &__swiper {
    width: 100%;
    display: flex;
    margin: 0;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    list-style-type: none;
    column-gap: $ln-space-x2;
    scroll-snap-type: x mandatory;

    @include ln-respond-to("max-sm") {
      & > li:first-child {
        margin-left: $ln-space-x2;
      }

      & > li:last-child {
        margin-right: $ln-space-x2;
      }
    }

    @include ln-respond-to("min-sm") {
      & > li:first-child {
        margin-left: $ln-space-x3;
      }

      & > li:last-child {
        margin-right: $ln-space-x3;
      }
    }

    @include ln-respond-to("min-lg") {
      & > li:first-child {
        margin-left: unset;
      }

      & > li:last-child {
        margin-right: unset;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &--wrapper {
      display: flex;
      justify-content: flex-start;

      &--center {
        width: auto;
        justify-content: center;
      }

      @include ln-respond-to("max-sm") {
        margin: 0 calc($ln-space-x2 * -1);
      }

      @include ln-respond-to("min-sm") {
        margin: 0 calc($ln-space-x3 * -1);
      }

      @include ln-respond-to("min-lg") {
        margin: unset;
      }
    }

    &--arrow {
      background-color: transparent;

      &:disabled {
        cursor: not-allowed;
      }

      &:first-of-type {
        padding-left: 4px;
        padding-right: 4px;
      }

      &:last-of-type {
        padding-right: 4px;
        padding-left: 4px;
      }

      &--hide {
        visibility: hidden;
      }

      @include ln-respond-to("max-lg") {
        display: none;
        visibility: hidden;
      }

      svg.ln-c-icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &--grid-item {
      display: flex;
      justify-content: center;
      width: calc(209px + (#{$content-padding} * 2)) !important;
      padding-bottom: 0rem !important;
      padding-left: 0;
      scroll-snap-align: center;

      @include ln-respond-to("max-xs") {
        .pt {
          flex-direction: column;
          margin-left: 0;
          margin-right: 0;
        }

        article > .pt,
        div > .pt {
          &__info {
            margin: 0 auto;
            width: 100%;
          }

          &__promotions {
            display: flex;
            flex-direction: column;
            margin-top: auto;
          }

          &__controls {
            display: flex;
            margin: 0 auto;
            width: $basket-controls-width;
          }

          &__wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-left: 0;
          }

          &__loading-indicator-tile {
            margin-bottom: 10px;

            &--md.pt-grid-item {
              display: flex;
            }

            &__content {
              padding: 175px 0 70px 0;
              display: flex;
              flex-direction: column;
              height: 372px;

              & .line {
                width: 80%;
              }

              & .line:nth-child(3) {
                width: 50%;
                margin: 0;
              }
            }
          }

          &__content {
            display: flex;
            flex-direction: column;
            padding: ($content-padding * 5) $content-padding $content-padding;
            &--optimised {
              $content-padding: 0.5rem;
              padding: ($content-padding * 8) $content-padding $content-padding;
            }
          }

          &__message {
            margin: 0 auto;
          }

          &__image-wrapper {
            width: 100%;

            .pt-image {
              height: 140px;
              width: 140px;

              &__product {
                height: 140px;
                width: 140px;
              }
            }
          }
        }
      }
    }

    & .product-details-title__tag {
      top: 0;
      left: 0;
      position: absolute;
      margin: 16px 0px 8px;
    }
  }
}

@include ln-respond-to("max-xs") {
  .ln-o-grid--matrix > .pt-grid-item {
    padding-bottom: $ln-space;
  }
}

.pt-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @include ln-respond-to("sm") {
    justify-content: center;
  }

  &__dec {
    margin-right: auto;
  }

  &__inc {
    margin-left: auto;
  }

  &__dec,
  &__inc {
    fill: $ln-color-white;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    width: 48px;
    &--reduced-height {
      height: 44px;
      min-height: 44px;
    }
  }

  &__quantity {
    @include ln-font-fixed("display-1");
    white-space: nowrap;

    border: 0;
    color: $ln-color-black;
    height: 48px;
    line-height: normal;
    padding: 0;
    width: 72px;
    &--reduced-height {
      height: 44px;
      min-height: 44px;
      @include ln-respond-to("min-sm") {
        min-width: 55px;
        width: 55px;
      }
    }

    @include ln-respond-to("max-sm") {
      width: 100%;
    }

    &--s {
      font-size: 1.1rem;
    }

    &--xs {
      font-size: 0.9rem;
    }
  }

  .ln-c-button {
    &--outlined {
      padding: 0 $ln-space-x2;
    }
    &--optimised {
      min-height: 44px;
    }
  }
}

.pt-mini {
  background-color: $ln-color-white;
  display: inline-block;
  margin-bottom: 10px;
  min-height: 148px;
  padding: 10px;
  width: 400px;

  &__nectar-color {
    color: $nectar-colour;
  }

  &__strike-through-price {
    font-size: 16px !important;
    line-height: 22px;
    font-family: $ln-font-family-base;
    padding-bottom: $ln-space/2;
    padding-left: 2px;
  }

  &__unit-price {
    padding-left: 6px;
    margin-right: 8px;
  }

  &__image {
    position: relative;
    float: left;
    margin-right: 15px;
    width: 100px;
    line-height: 0;

    .pt-image {
      &__occasions-sticker {
        width: 100px;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    float: left;
    min-height: 120px;
    width: 210px;
  }

  &__offer {
    font-family: Arial;
  }

  &__title {
    display: flex;
    flex-flow: column;
    color: $dark-grey-hex;

    a.pt-mini__link {
      @include ln-font("caption");

      color: $dark-grey-hex;
      cursor: pointer;
      display: inline-block;
      font-family: $ln-font-family-base;
      line-height: 1.5;
      margin-bottom: 5px;
      text-decoration: none;
      white-space: normal;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__promotions-container {
    clear: both;
    padding-top: $ln-space;
  }

  &__promotions {
    align-self: flex-end;
  }

  &__promotion {
    line-height: 1;
    list-style-type: none;
  }

  & + & {
    margin-top: $ln-space-half;
  }

  &__promotions-missed {
    color: $ln-color-red;
  }

  &__total {
    font-size: 22px;
    font-weight: 800;
    color: $dark-grey-hex;

    &--promo {
      color: $ln-color-red;
    }

    &--promo-nectar {
      color: $dark-grey-hex;
    }
  }

  &__buttons {
    float: right;
    width: 48px;
  }

  .pt-button {
    flex-direction: column;

    &__dec,
    &__inc {
      height: 40px;
      min-height: 40px;
    }

    &__quantity {
      height: 40px;
    }
  }

  .pt-button__loading {
    margin: 50px 0 0 0;
  }
}

.pt-image {
  flex-direction: column;
  height: 100px;
  margin-bottom: 8px;
  min-width: 100px;
  position: relative;
  width: 100px;

  &__link {
    flex: 33%;
    display: inline-block;
    margin-bottom: auto;
    line-height: 0;

    text-decoration: none;
    &:has(> .pt-image__banner) {
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  &__banner {
    width: 100%;
    background-color: #3f384c;
    flex: 33%;
    font-size: 14px;
    line-height: 24px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: $ln-font-family-base;
    pointer-events: none;
    &:hover,
    &:focus,
    &:active {
      cursor: default;
    }
  }
}

.pt__controls--right {
  & .pt-button__quantity {
    &--s {
      font-size: 1rem;
    }

    &--xs {
      font-size: 0.8rem;
    }
  }
}

.pt-extra-row {
  margin: 30px 0px;
}

.pt-espot {
  background: none;
  height: auto;
  min-height: 400px;
  padding: 0;

  @include ln-respond-to("max-xs") {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem) !important;
  }

  &--isSRF {
    height: 100%;
  }
}

.product-description-box {
  @include ln-respond-to("max-xs") {
    max-height: 2.5em;
    line-height: 1.2;
    overflow: hidden;
  }
}

.op {
  &__not-available {
    text-align: center;
    color: $ln-color-black;
    font-size: 18px;
    font-weight: 700;
  }
}

.SRF {
  &__pt {
    height: 100%;
  }
}

.pt-image__occasions-sticker {
  width: 100px;
  @include ln-respond-to("min-xs") {
    width: 140px;
  }
}

// fbt v2 product tile

.pt--forced-mobile {
  min-width: min-content;

  &__content {
    flex-direction: row !important;
    padding-top: 0;
  }

  &__wrapper {
    margin-left: 16px;
    width: 180px;
  }

  &__controls {
    display: flex;
    margin-left: auto;
    min-width: 168px;
    text-align: center;
  }

  &__image-wrapper {
    width: 100px;
    min-height: fit-content;
    margin-top: unset;

    .pt-image {
      height: 100px;
      width: 100px;
    }
  }

  &__data-entry {
    margin-left: 0;

    @include ln-respond-to("sm") {
      margin-left: -10px;
    }
  }

  .product-details-title__tag {
    position: relative;
    margin: 0px 0px 8px;
  }

  .promotion-message {
    max-width: 100%;
  }

  .pt__icons__fav,
  .pt__icons__fav--fbt {
    right: unset;
    top: unset;
    left: var(--ds-space-spacing-x2);
    bottom: var(--ds-space-spacing-x2);
    padding: unset;
    height: 48px;
    width: 48px;
    padding-top: 20px;
  }

  &__loading-indicator-tile {
    &__content {
      background-color: $ln-color-white;
      padding: 30px 20px 70px 30px;
      height: 100%;

      & .line {
        height: 14px;
        background-color: $ln-color-grey-light;
        display: block;
        margin-bottom: 8px;
        opacity: 0.8;
        animation: fadelines 4s infinite;
        width: 100%;
      }

      & .line:nth-child(3) {
        width: 50%;
        margin-right: 25%;
      }
    }
  }
}

.similar-products-wrapper {
  button {
    margin-top: 5px;
  }
}
