.store-card {
  padding: 16px;
  background-color: $ln-color-white;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;

  @include ln-respond-to("sm") {
    min-width: 355px;
    max-width: 355px;
  }

  &__list {
    width: 100%;
    display: flex;
    margin: 0;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &--item {
      list-style-type: none;
      margin-right: 16px;

      button {
        min-width: 150px;
        font-size: 18px;
        line-height: 24px;
        color: $ln-color-orange;
        font-family: $ln-font-family-brand;
        background: transparent;
        height: 100%;

        &:hover {
          color: #e55000;
        }
      }
    }

    &--wrapper {
      display: flex;
    }

    &--button {
      background-color: transparent;

      &:first-of-type {
        padding-right: 16px;
      }

      &:last-of-type {
        padding-left: 16px;
      }
    }
  }

  &__modal-list {
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &--item {
      list-style-type: none;
      margin-bottom: 10px;

      button {
        font-size: 18px;
        color: $ln-color-orange;
        font-family: $ln-font-family-brand;
        background: transparent;
        margin-top: 10px;
        margin-left: 50%;
        -webkit-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%);
        min-width: 180px;

        &:hover {
          color: #e55000;
        }
      }

      .store-card__address1 {
        display: inline-block;
        padding-right: 4px;
      }

      .store-card__address2 {
        display: inline-block;
      }

      .store-card__text-container {
        min-height: 0;
      }
    }

    &--button {
      display: none;
    }
  }

  &__selected {
    border: 1px solid $ln-color-orange;
  }

  &__disabled {
    opacity: 0.35;
    border: 1px solid #d8d8d8;
    cursor: default;
  }

  &__text,
  &__address1,
  &__address2,
  &__distance {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 24px;
    hyphens: none;

    &-container {
      min-height: 94px;
      margin-left: 16px;
    }

    &-loading {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 96px;
      padding-top: 4px;
      padding-bottom: 8px;
    }
  }

  &__address1,
  &__address2,
  &__distance {
    @include ln-respond-to("max-sm") {
      font-size: 14px;
    }
  }

  &__radio {
    appearance: none;
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    box-shadow: inset 0 0 0 4px $ln-color-white;
    border-radius: 50%;
    background-color: $ln-color-white;
    border: 1px solid $ln-color-black;
    transition: all 0.5s;

    &:focus {
      border-color: $ln-color-orange;
      box-shadow: inset 0 0 0 2px $ln-color-orange;
    }

    &-selected {
      background-color: $ln-color-orange;
      border: 1px solid $ln-color-orange;

      &:focus {
        background-color: $ln-color-orange;
        box-shadow: inset 0 0 0 4px $ln-color-white;
      }
    }

    &-loading {
      border: none;
      box-shadow: none;
      background-color: $ln-color-black;
      animation: pulse 1.5s infinite ease-in-out;

      @keyframes pulse {
        0% {
          opacity: 0.2;
        }

        25% {
          opacity: 0.35;
        }

        100% {
          opacity: 0.2;
        }
      }
    }
  }
}
