.YourNectarPriceSlotPrompt {
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "card";

  width: 100%;

  @include ln-respond-to("md") {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "card widget1 widget2";
    gap: 24px;
  }
}
