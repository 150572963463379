.about-cnc {
  margin-top: 169px;
  padding-top: 4.5rem;

  @include ln-respond-to("sm") {
    margin-top: 187px;
  }

  &__card {
    margin-bottom: 24px;

    .ln-c-button--filled {
      width: 100%;

      @include ln-respond-to("sm") {
        width: auto;
      }
    }

    &--usps {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include ln-respond-to("sm") {
        flex-direction: row;
      }
    }

    &--usp-heading {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 8px;

      @include ln-respond-to("sm") {
        flex-direction: column;
        margin-bottom: 16px;
      }

      svg {
        position: relative;
        bottom: 1px;
        color: $ln-color-orange;
        height: 24px;
        width: 24px;
        margin-right: 8px;

        @include ln-respond-to("sm") {
          bottom: 0;
          margin-right: 0;
          margin-bottom: 16px;
          height: 48px;
          width: 48px;
        }
      }

      h3 {
        color: $ln-color-orange;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 0;
        width: 100%;
        font-weight: 800;
      }
    }

    &--usp {
      max-width: 200px;

      @include ln-respond-to("sm") {
        margin-right: 16px;
        width: 25%;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &--header {
      font-size: 20px;

      @include ln-respond-to("sm") {
        font-size: 22px;
      }
    }

    &--small-header {
      margin-bottom: 0;
      font-family: $ln-font-family-base;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__banner {
    position: relative;
    background-color: $ln-color-white;
    overflow: hidden;

    @include ln-respond-to("sm") {
      display: flex;
      justify-content: center;
      max-height: 153px;
    }

    img {
      @include ln-respond-to("md") {
        min-width: 645px;
      }
    }

    &--header {
      color: $ln-color-orange;
      margin: 0 16px;
      font-size: 32px;

      @include ln-respond-to("sm") {
        font-size: 48px;
        margin: 0;
        position: absolute;
        top: 17px;
        left: 92px;
      }

      @include ln-respond-to("md") {
        font-size: 50px;
      }
    }

    &--subheader {
      color: $ln-color-orange;
      font-size: 24px;
      font-family: $ln-font-family-brand;
      font-weight: 700;
      margin: 0 16px;
      text-align: right;

      @include ln-respond-to("xs") {
        text-align: left;
        padding-left: 36px;
      }

      @include ln-respond-to("sm") {
        padding-left: 0;
        margin: 0;
        text-align: left;
        position: absolute;
        top: 87px;
        left: 259px;
      }

      @include ln-respond-to("md") {
        font-size: 26px;
      }
    }
  }
}
