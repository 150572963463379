$gap-between-rows: 16px;
$gap-between-rows-mobile: 20px;

@mixin wider-margin {
  margin-left: -1rem;
  margin-right: -1rem;
}

@mixin price-supplementary-text {
  font-size: 14px;
  font-family: Arial;
}

.product-details-page {
  padding-top: 16px;

  .ln-c-alert__wrapper {
    width: 100%;
    justify-content: center;
    line-height: 22px;
  }

  .dietary-profile-warning {
    margin-bottom: 16px;
  }
}

.bv-modal {
  z-index: 1000;

  .ln-c-modal {
    &__body-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__body {
      padding: 0px;
      margin-bottom: 15px;
      background: none;
      max-width: 75%;
    }

    &__scroll-area {
      display: flex;
      justify-content: center;
    }
  }

  .ln-c-button {
    visibility: hidden;
  }
}

.bv-iframe {
  width: 100%;
  border-width: 0px;
  height: 75vh;
  padding-top: 0px;
}

.pd {
  background-color: $ln-color-white;
  display: flex;
  flex-direction: column;
  text-align: left;

  &__label-serves {
    margin-bottom: var(--ds-size-space-2);
  }

  &__similar-products {
    margin: 24px 16px 0px;

    @include ln-respond-to("sm") {
      margin: 32px 16px 0px;
    }

    @include ln-respond-to("md") {
      margin: 32px 0px;
    }
  }

  &__frequently-bought-together {
    margin: 24px 16px 0px;
  }

  &__xmas-notification-wrapper {
    margin-top: 8px;
    padding-bottom: 8px;

    @include ln-respond-to("sm") {
      margin-top: 16px;
    }
  }

  &__description {
    font-size: 14px;
    margin: 0px 0px $gap-between-rows 0px;

    @include ln-respond-to("max-md") {
      margin: 0px 0px $gap-between-rows-mobile 0px;
    }
  }

  .promotion-message {
    margin: 0;
    font-size: 14px;
    width: 18.75rem;
    margin-bottom: 4px;

    .promotion-message__link {
      @include ln-font("caption");

      color: $ln-color-red;
      text-decoration: underline;

      &--purple {
        color: $nectar-colour;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &:visited {
          color: $nectar-colour-darker;
        }
      }
    }
  }

  &__controls {
    height: 50px;
    display: flex;
    flex-basis: 50%;

    & .pt-button {
      display: flex;
      width: 100%;
      margin: auto 0;
      max-width: 190px;

      @include ln-respond-to("max-md") {
        max-width: 343px;
      }

      &__loading {
        margin-top: 7px;
      }
    }
  }

  &__with-nectar-bonus-points-container {
    margin-bottom: 8px;
  }

  &__with-nectar-bonus-points-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    font-family: Arial;
    color: #8223fa;
    margin-bottom: 3px;
  }

  &__with-nectar-bonus-points {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    font-family: Arial;
    color: #8223fa;
    margin-bottom: 3px;

    &__promotion {
      margin-left: 8px;
    }
  }

  .pd__controls .ln-c-progress-indicator.is-loading {
    width: 168px;

    @include ln-respond-to("max-sm") {
      width: 100%;
      height: 40px;
    }
  }

  .ln-c-breadcrumbs {
    text-align: left;
  }

  @mixin wider-margin-and-width {
    @include wider-margin;
    width: calc(100% + 2rem);
  }

  .product-details-details {
    @include wider-margin-and-width;
  }

  @include ln-respond-to("max-md") {
    &__header {
      font-size: 1.5rem;
    }
  }

  @include ln-respond-to("max-md") {
    @include wider-margin-and-width;
  }

  &__fixed {
    position: fixed;
    bottom: 0;
    left: 0.5rem;
    width: calc(100% - 1rem);
    padding: 0 1.5rem;
    box-sizing: border-box;
    background: #fff;
    z-index: 5;

    @include ln-respond-to("max-sm") {
      padding: 0 1rem;
      width: 100%;
      left: 0;
    }
  }

  &__nofixed {
    position: static;
  }

  &__left {
    @include ln-respond-to("max-sm") {
      margin: 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__reviews {
    display: flex;
    font-size: 14px;

    &__write {
      margin-left: 16px;
    }

    & .star-rating-link {
      background-color: transparent;
      text-decoration: underline;
      color: $ln-color-links;

      &:hover {
        color: $ln-color-text-alpha-active;
        transition: color 0.5s ease-out;
      }
    }

    @include ln-respond-to("max-md") {
      margin-bottom: 16px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__frozen {
    margin: 19px 0 0 0;
  }

  &__promotions {
    margin: 0px 0px $gap-between-rows-mobile 0px;
  }

  &__promotion {
    list-style-type: none;
  }

  &__nectar-price-wrapper {
    @include ln-respond-to("max-sm") {
      padding: 10px 0 5px;
      display: flex;
      width: 100%;
      justify-content: flex-start;

      .pd__cost {
        width: auto;
      }
    }
  }

  &__price-wrapper {
    display: block;
    justify-content: space-between;
    width: 318px;
    margin: 8px 0px;

    @include ln-respond-to("max-md") {
      display: flex;
      width: 100%;
      align-items: end;
    }

    @include ln-respond-to("max-xs") {
      align-items: end;
    }

    &-padding-top {
      padding-top: 100px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  &__with-or-without-nectar {
    @include price-supplementary-text;
    font-weight: 400;
    line-height: 22px;
    color: $ln-color-black;
    margin-left: 5px;

    @include ln-respond-to("max-mobile-md", $gol-breakpoints) {
      margin: 0px;
    }
  }

  &__cost-wrapper {
    margin-bottom: 16px;
    margin-right: 20px;

    @include ln-respond-to("max-md") {
      margin: 0px;
    }
  }

  &__cost {
    width: 100%;
    font-weight: 800;
    display: flex;
    column-gap: 8px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .price-header {
      font-size: 14px;
      margin-bottom: 5px;
    }

    @mixin unitPriceBreakdown {
      @include price-supplementary-text;

      line-height: 22px;
      font-weight: 400;

      color: $ln-color-black;

      @include ln-respond-to("max-xs") {
        margin: 0px;
      }
    }

    &__unit-price-per-measure {
      @include unitPriceBreakdown;
      margin-left: 5px;
    }

    &__per-unit {
      @include unitPriceBreakdown;

      @include ln-respond-to("max-xs") {
        display: none;
      }

      &--discount {
        color: initial;
      }
    }

    &__pdp-unit {
      @include price-supplementary-text;
    }

    &__retail-price {
      font-family: $ln-font-family-brand;
      font-size: 32px;
      line-height: 30px;

      @include ln-respond-to("max-xs") {
        font-size: 26px;
        margin-right: 5px;
      }

      &--with-nectar {
        &-associated {
          color: $nectar-text-colour;
        }

        &-not-associated {
          color: $ln-color-grey-v-dark;
        }
      }

      &__wrapper {
        display: flex;
        align-items: baseline;
        margin-top: 5px;
        @include ln-font-size("display-3");
        font-family: $ln-font-family-brand;
        line-height: 0.8;

        @include ln-respond-to("max-mobile-md", $gol-breakpoints) {
          flex-direction: column;
          align-items: flex-start;
        }

        &--no-promo {
          color: #0c0c0c;
        }

        &--promo {
          display: flex;
          color: $ln-color-red;
          align-items: end;
          flex-direction: row;
          justify-content: center;

          @include ln-respond-to("max-xs") {
            font-size: 26px;
            flex-wrap: wrap;
            justify-content: flex-start;
          }
        }
      }
    }

    &__contextual {
      color: initial;
      font-size: 1.375rem;
      line-height: 1.625rem;
      font-family: $ln-font-family-brand;

      & .pd__cost--price {
        text-decoration: line-through;
      }

      @include ln-respond-to("max-xs") {
        font-size: 20px;
      }

      &--wrapper {
        display: flex;
        align-items: baseline;
        @include ln-font-size("display-3");
        font-family: $ln-font-family-brand;
        line-height: 0.8;

        @include ln-respond-to("max-mobile-md", $gol-breakpoints) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &--with-nectar {
        &-associated {
          color: $ln-color-grey-dark;

          & .pd__cost--price {
            text-decoration: none;
          }
        }

        &-not-associated {
          color: $nectar-text-colour;

          & .pd__cost--price {
            text-decoration: none;
          }
        }

        &__unit {
          color: initial;
        }
      }

      &--without-nectar {
        color: $ln-color-grey-dark;
      }
    }
  }

  .nectar-price {
    @include ln-respond-to("max-xs") {
      line-height: 1.1;
      padding: 0 15px 0 5px;
    }

    @include ln-respond-to("min-sm") {
      width: 35%;
      line-height: 1.5;
    }
  }

  &__message {
    width: 180px;
    height: 36px;
    font-size: 14px;
    text-align: center;
    color: $ln-color-red;
  }

  &__data-entry {
    & .form-dropdown {
      width: 318px;
      margin: 14px auto;

      @include ln-respond-to("min-sm") {
        margin: 14px 0;
      }

      @include ln-respond-to("max-xs") {
        width: 100%;
      }
    }
  }

  @include ln-respond-to("min-md") {
    padding: 23px;

    &__frozen {
      margin: 0 0 21px 0;
    }

    &__header {
      margin-bottom: 16px;
      font-size: 1.75rem;
      line-height: 40px;
    }

    &__promotions {
      margin: 0px 0px $gap-between-rows 0px;
    }

    &__reviews {
      margin: 0px 0px $gap-between-rows 0px;

      @include ln-respond-to("max-md") {
        margin: 0px 0px $gap-between-rows-mobile 0px;
      }

      &__write {
        display: inline;
        margin-left: 16px;
      }
    }

    &__wrapper {
      flex-direction: row;
    }

    &__image {
      margin: unset;
    }

    &__right {
      display: flex;
      flex-direction: column;
      padding-left: 24px;
    }
  }

  @include ln-respond-to("min-md") {
    &__right {
      padding-left: 48px;
    }
  }

  @include ln-respond-to("min-lg") {
    &__header {
      margin: 0px 0px $gap-between-rows 0px;
      font-size: 2.5rem;
      line-height: 40px;

      @include ln-respond-to("max-md") {
        margin: 0px 0px $gap-between-rows-mobile 0px;
      }
    }
  }

  &__attachments--table {
    padding: 30px;
    background-color: white;
  }

  &__attachments--first-col {
    font-weight: bold;
  }

  &__attachments--pdf-icon {
    width: 30px;
    float: left;
    padding-right: 10px;
  }

  &__favourite {
    position: relative;
    display: flex;
    align-items: baseline;
    background-color: transparent;

    @include ln-respond-to("max-sm") {
      margin-bottom: 20px;
    }

    & .pt__icons__fav {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      padding: 0;

      &--cta-link {
        font-size: 16px;
        line-height: 24px;
        color: $ln-color-links;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 0;
        font-weight: 700;
        float: right;
        padding-left: var(--ds-size-space-2);

        &:hover {
          color: $ln-color-text-alpha-active;
          transition: color 0.5s ease-in-out;
        }
      }
    }
  }
}

.product-details-tile {
  padding-bottom: 0;
}

.pd__important-information {
  h3 {
    font-size: 1.125rem;
  }

  p {
    font-size: 0.875rem;
  }
}

.pd__disclaimer {
  background-color: unset;
  padding: unset;

  @include ln-respond-to("max-sm") {
    padding-right: 16px;
    padding-left: 16px;
  }

  @include ln-respond-to("min-md") {
    margin-top: 24px;
  }

  h3 {
    font-size: 1.125rem;
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 0.9em;
  }
}

.pd__shortmeta {
  display: flex;
  margin: 0px 0px var(--ds-size-space-2) 0px;

  @include ln-respond-to("max-md") {
    margin: 0px 0px $gap-between-rows-mobile 0px;
  }

  &__badges {
    margin-top: 16px;
    margin-bottom: 16px;
    justify-content: center;
  }
}

.pd__shortmeta__item {
  margin-right: 6px;
}

.pd__badge_container {
  margin-top: 12px;
  margin-bottom: 0;
}

.slick-slider {
  width: 300px;
  margin-bottom: 32px;
}

.product-details {
  margin-right: 16px;
}

.pd__sidebar > * {
  margin-bottom: 24px;
}

.pd__loading {
  @include ln-respond-to("max-sm") {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  @include ln-respond-to("min-sm") {
    margin-left: 0;
    margin-right: 0;
  }
}

.product-tile-notification {
  text-align: left;
  display: flex;
  padding: $ln-space;
  background-color: #e5f2ff;
  border: 1px solid #9acdff;

  &__icon {
    margin-right: $ln-space;
  }

  &__text {
    font-size: 14px;
  }

  &__dummy-button {
    width: 168px;
    margin-bottom: 16px;
  }

  &__tile-wrapper {
    padding: 0 16px 16px 16px;
  }
}

.product-tile-secondary-notification {
  text-align: left;
  padding: 15px;
  background-color: #135ca3;
  color: #fff;
  border: 1px solid #9acdff;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 20px;

  @media (max-width: 479px) {
    margin-left: 0;
    margin-right: 0;
  }

  &__icon {
    display: flex;
    font-size: 18px;
    font-weight: bold;

    p {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  &__text {
    font-size: 16px;
    margin-left: 33px;
    width: 90%;

    @media (max-width: 479px) {
      margin-left: 27px;
    }

    @media (max-width: 375px) {
      margin-left: 24px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.pd-merchandising-product {
  @media (480px <= width < 960px) {
    width: 200px;
  }

  &__wrapper {
    padding: 0px $ln-space-x2;
    overflow: hidden;
    width: 100%;
    @media (width > 960px) {
      padding: 0px;
    }
  }

  & .ln-c-card.pt {
    margin-bottom: $ln-space-x3;
  }

  & .pt-button {
    max-width: 343px;
  }
}
.sponsored-title {
  font-size: 20px;
  margin-bottom: 16px;

  @include ln-respond-to("min-sm") {
    font-size: 26px;
  }
}

.fbt {
  flex-wrap: wrap;

  @include ln-respond-to("lg") {
    display: grid;
    grid-template-columns: 1.4fr 0.6fr;
    flex-wrap: nowrap;
  }
}

.pd__leftRight {
  display: flex;
  flex-direction: column;
  @include ln-respond-to("md") {
    flex-direction: row;
  }
}
