.dietary-profile-warning {
  background-color: #d70000;
  color: white;
  text-align: center;
  padding: 8px;
  margin-bottom: 30px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  &__icon {
    font-size: 1.4rem;
    vertical-align: bottom;
  }

  &__new_header {
    margin-top: 16px;
    margin-bottom: 0;
  }
}
