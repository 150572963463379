.CustomerRatingAccordionItem {
  display: flex;
  align-items: center;
  column-gap: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;

  & .star-rating {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__rating {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    display: flex;
    column-gap: 5px;
    font-size: 14px;
  }
}
