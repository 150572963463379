.recipes-search-container-full-width {
  margin-inline: 0;
  max-width: 100%;
}

.recipes-browse-results__pill-selected {
  background-color: #7f0a41;
  border: solid 1px $ln-color-grey;
  color: white;
}

.recipes-browse-results__pill {
  border: solid 1px $ln-color-grey;
  color: $ln-color-grey-v-dark;
}
