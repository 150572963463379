.promo-lister {
  margin-top: 70px;
  padding-bottom: 0px;
  margin-bottom: 0px;

  @include ln-respond-to("max-sm") {
    margin-top: -10px;
  }

  @include ln-respond-to("max-md") {
    margin-top: 20px;
  }

  &__strapline-title-container {
    margin-top: 9px;
    margin-bottom: 24px;
  }
  &__strapline-title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 11px;
  }

  &__promo-text {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}

.promo-lister-sort-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-basis: auto;
  margin-bottom: 24px;

  &__label {
    font-family: "MaryAnn";
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $dark-grey-hex;
    margin-right: 15px;
  }

  &__select-field {
    margin: 0;
  }
}
