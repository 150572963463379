.recipe-homepage {
  margin-top: 32px;

  &__contents {
    a {
      font-weight: 700;
      font-family: MaryAnn, "Trebuchet MS", sans-serif;
    }

    ul {
      margin: 0;
    }

    li {
      margin-bottom: 0;
      margin-top: 13px;
    }

    h2 {
      padding-left: 6px;
      padding-top: 16px;
    }

    p {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  // Adjusts the text box in the featured-recipe card. Temporary fix until Bolt component has been adjusted from CMS side.
  &__contents > div:first-child > div:nth-child(2) {
    margin: 0;
    padding: 24px;
  }
}
