@import "NoScheduledOrders";
@import "NoOrders";
@import "../../components/LoadingIndicator/LoadingIndicator";
@import "Order";

.my-orders {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__breadcrumbs {
    display: none;

    @include ln-respond-to("xs") {
      display: block;
      width: 100%;
    }
  }

  &__segment {
    max-width: 720px;
    width: 100%;
  }

  &__card {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      margin-bottom: calc(#{$ln-space} * 3);
    }
  }
}
