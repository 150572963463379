$btn-width: 120px;

.grid-mobile-navigation {
  background: $ln-color-white;
  border-top: 1px solid $ln-color-grey-light;
  border-bottom: 1px solid $ln-color-grey-light;
  height: 64px;
  margin-left: -1rem;
  padding: 10px 0;
  padding-left: 54px;
  width: -webkit-calc(100% + 2rem);
  width: -moz-calc(100% + 2rem);
  width: calc(100% + 2rem);
  position: relative;
  z-index: 4;

  &__buttons-container {
    margin: 0px;
    overflow-x: scroll;
    display: flex;
    scroll-behavior: smooth;
    scrollbar-width: none;
    list-style-type: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  &__button-calendar {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 48px;
    height: calc(100% - 16px);
  }

  &__day {
    border: solid 2px $ln-color-white;
    border-radius: 2px;
    font-size: 0.8rem;
    min-height: 44px;
    text-align: center;
    min-width: $btn-width;
    background: transparent;

    &:focus {
      box-shadow: none;
      outline: none;
      text-decoration: underline;
    }

    @media (min-width: 370px) {
      font-size: 1rem;
      line-height: 1.3rem;
    }

    &--selected {
      border-color: $ln-color-orange;
    }
  }

  &__time-of-day {
    display: flex;
    align-items: center;
    height: $ln-space-x7;
    background-color: $ln-color-light;
    font-family: $ln-font-family-brand;
    margin-top: 8px;
    position: absolute;
    left: 0;
    width: 100%;
    font-size: 18px;
    font-weight: 800;
    padding-left: calc(1rem + 9px);
    text-transform: capitalize;
  }

  &__xmas-start-date {
    padding-right: 10px;
  }

  &__xmas-end-date {
    padding-left: 10px;
  }

  &__non-seasonal-date {
    padding: 0px;
  }
}

button.xmas-date {
  min-width: $btn-width + 20px;
}
