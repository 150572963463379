.error-page {
  &__header {
    @include ln-font("display-4");

    margin-bottom: 24px;
  }

  &__description {
    margin: auto;
    max-width: 702px;
    margin-bottom: 56px;
  }

  &__image {
    margin-bottom: 32px;
  }

  &__button {
    &--primary {
      min-width: 288px;

      @include ln-respond-to("max-sm") {
        min-width: auto;
        width: 100%;
      }
    }
  }

  &__link-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin-top: 16px;

    li {
      margin: 0 16px;
      white-space: nowrap;
    }
  }
}
