.recipe-label {
  color: $ln-color-monochrome-medium;
  border: 1px solid $ln-color-monochrome-light;
  border-radius: 2px;
  background-color: $ln-color-white;
  padding: 4px 8px;
  display: inline-block;
  margin: 1rem 0.5rem 0 0;
  font-size: 12px;
  font-weight: $ln-font-weight-bold;
  line-height: 16px;
  min-width: 48px;
  min-height: 18px;
}
