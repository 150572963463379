.page-layout {
  display: flex;
  flex-direction: column;

  @include ln-respond-to("sm") {
    align-items: center;
  }

  @include ln-respond-to("lg") {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  &-header {
    margin: 0 auto;
    max-width: 720px;

    @include ln-respond-to("lg") {
      margin-bottom: 0.7rem;
      max-width: 1139px;
    }
  }

  &-header-BYG-AB-test {
    margin: 0 auto;

    @include ln-respond-to("lg") {
      margin-bottom: 0.7rem;
      max-width: 1139px;
    }

    @include ln-respond-to("xl") {
      max-width: 1339px;
    }
  }

  &-left {
    min-width: 720px;
    padding-right: calc(#{$ln-space} * 3);
  }

  &-left-BYG-AB-test {
    min-width: 720px;
    padding-right: calc(#{$ln-space} * 3);

    @include ln-respond-to("xl") {
      min-width: 920px;
    }
  }

  &-right {
    --offset: 247px;

    align-self: start;
    position: sticky;
    position: -webkit-sticky;
    top: var(--offset);
    width: 395px;

    &--small-offset {
      --offset: var(--page-layout-small-offset, 24px);
    }
  }

  &__card-desktop-left {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      margin-bottom: calc(#{$ln-space} * 3);
      width: 720px;
    }
  }

  &__card-desktop-left-BYG-AB-test {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      margin-bottom: calc(#{$ln-space} * 3);
    }

    @include ln-respond-to("lg") {
      width: 720px;
    }

    @include ln-respond-to("xl") {
      width: 920px;
    }
  }

  &__card-desktop-right {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      margin-bottom: calc(#{$ln-space} * 3);
      width: 720px;
    }

    @include ln-respond-to("lg") {
      width: 395px;
    }
  }

  &__card-desktop-right-BYG-AB-test {
    margin-bottom: calc(#{$ln-space} * 2);

    @include ln-respond-to("sm") {
      margin-bottom: calc(#{$ln-space} * 3);
    }

    @include ln-respond-to("lg") {
      width: 395px;
    }
  }
}

.margin-bottom {
  margin-bottom: $ln-space;

  @include ln-respond-to("xs") {
    margin-bottom: calc(#{$ln-space} * 2);
  }
}

.bold-text {
  font-family: $ln-font-family-brand;
  font-weight: $ln-font-weight-bold;
  font-size: 18px;
}

.sticky-button {
  position: sticky;
  background: #ffffff;
  bottom: 0;
  box-shadow: -2px -4px 4px rgba(0, 0, 0, 0.25);
  padding: 14px;
  margin: 0 -1rem 0 -1rem;

  @include ln-respond-to("sm") {
    margin: 0 calc(((100vw - 720px) / 2) * -1);
  }
}

.grey-line-divider {
  border-top: solid 1px $ln-color-grey-v-light;
}
