.postcodeCheckForm {
  &__form {
    margin-bottom: 0px;
  }

  &__input {
    border-width: 2px;
    border-radius: 2px;
  }

  &__loadingIcon {
    justify-content: center;
  }
}
