.account-disabled {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
  background: none;
  text-align: center;

  .extra-visible-error-message {
    border: 1px solid #262626;
    background-color: $ln-color-white;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    text-align: center;

    .alert-title {
      font-weight: bold;
    }

    &__inner-container {
      margin-left: auto;
      margin-right: auto;
      display: inherit;
    }

    &__retries-remaining {
      font-weight: 700;
    }

    &__icon-wrapper {
      margin-right: 0.5rem;
    }

    &__icon {
      color: $ln-color-yellow;
    }

    &__link-wrapper {
      margin-top: 1.5rem;
    }
  }
}
