$mobile-break-at: "xs";
$mobile-break-at-max: "max-xs";

// Styles which have custom values that need tweaking for swap modal
.recipes-swap-modal.ln-c-modal__body {
  #recipes-swap-inner-wrap {
    @include ln-respond-to($mobile-break-at) {
      max-height: min(1050px, calc(100vh - 2 * $ln-space-x2));
      max-width: min(650px, calc(100vw - 2 * $ln-space-x2));
    }
  }

  #recipes-swap-content {
    padding: 0 $ln-space-x2;
  }
}
