.diet {
  &__inner {
    display: flex;
    padding-right: 5px;
    width: 168px;
  }

  &__text {
    @include ln-font("caption");
    color: $ln-color-plum;
    font-size: 13px;
    font-weight: bold;
    margin: 2px 0 2px auto;
    white-space: nowrap;
  }
}
