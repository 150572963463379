.switch-to-delivery {
  &__link {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__icon {
    margin-right: 0.5rem;
    font-size: 1.1rem;
  }

  &__text {
    font-size: 1.1rem;
  }
}
