.order-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  nav {
    display: none;

    @include ln-respond-to("xs") {
      display: block;
      width: 100%;
    }
  }

  &__card {
    width: 100%;
    margin-bottom: calc(#{$ln-space} * 2);
    padding-bottom: 0;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    @include ln-respond-to("sm") {
      width: 720px;
      margin-bottom: calc(#{$ln-space} * 3);
    }
  }

  &__sub-heading {
    font-size: 26px;
    margin-bottom: calc(#{$ln-space} * 2);
  }

  &__image {
    width: 90px;
    height: 90px;
    margin: 16px 80px 0 0;
    cursor: pointer;

    @include ln-respond-to("sm") {
      margin-top: 0;
      margin-right: calc(#{$ln-space} * 2);
    }
  }

  &__order-summary {
    &-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include ln-respond-to("sm") {
        flex-direction: row;
      }
    }

    &-item {
      display: flex;
      width: 100%;
      justify-content: space-between;

      &:nth-last-child(1) {
        border-top: solid 1px $ln-color-grey-v-light;
      }

      @include ln-respond-to("sm") {
        margin-top: 0;
        width: 324px;
      }
    }
  }

  &__receipt {
    &-item {
      display: flex;
      flex-direction: row;
      margin-top: calc(#{$ln-space} * 2);
      width: 100%;

      @include ln-respond-to("xs") {
        margin: calc(#{$ln-space} * 2) 0;
      }
    }

    &-button-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: calc(#{$ln-space} * 2);
      width: 100%;

      @include ln-respond-to("xs") {
        margin: calc(#{$ln-space} * 2) 0 calc(#{$ln-space} * 2) 0;
        flex-direction: row;
      }
    }

    &-button {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: calc(#{$ln-space} * 2);

      @include ln-respond-to("xs") {
        margin-bottom: 0;
      }
      @include ln-respond-to("lg") {
        width: 50%;
      }
    }

    &-payment-logo-wrapper {
      display: flex;
      flex-direction: row;
      margin-left: $ln-space;
      max-height: 24px;
      max-width: 40px;
    }
  }

  &__trolley-summary {
    &-mobile-wrapper {
      display: flex;

      &:not(:last-child) {
        border-bottom: solid 1px $ln-color-grey-v-light;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      padding: 16px 0;
      width: 100%;

      @include ln-respond-to("sm") {
        flex-direction: row;

        &:not(:last-child) {
          border-bottom: solid 1px $ln-color-grey-v-light;
        }
      }
    }

    &-quantity {
      @include ln-respond-to("sm") {
        display: flex;
        flex-grow: 1;
      }
    }

    &-product-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-product {
      @include ln-respond-to("sm") {
        width: 226px;
        flex-grow: 1;
        margin: 0 62px 0 16px;
      }
    }

    &-icon {
      margin-left: -3px;
    }
    &-product-link {
      text-decoration: none;
    }
    &-product-link:hover {
      text-decoration: underline;
    }
    &-image {
      line-height: 0;
    }
  }

  &__order-information {
    &-item {
      margin-bottom: calc(#{$ln-space} * 2);

      &:nth-last-child(1) {
        margin-bottom: $ln-space;
      }
    }

    &-sub-heading {
      display: block;
      font-family: $ln-font-family-brand;
      font-weight: $ln-font-weight-bold;
      font-size: 18px;
    }
  }

  &__slot-details {
    line-height: 2;

    .green-delivery-slot {
      &__container {
        color: $ln-color-success;
      }

      &__link {
        color: $ln-color-success;
        line-height: 1.5;

        &:hover {
          color: $ln-color-orange;
        }
      }
    }
  }
  &__occasions {
    &-banner {
      height: 24px;
      width: 90px;
      background-color: #3f384c;
      margin-bottom: 8px;

      font-size: 14px;
      line-height: 24px;
      color: white;
      text-align: center;
      font-weight: bold;
      font-family: $ln-font-family-base;
    }
  }
}
.error-banner {
  background-color: #e8f1fb;
  border: 1px solid #135ca3;
  padding: 12px 18px 12px 18px;
  margin-bottom: calc(#{$ln-space} * 2);
  width: 100%;
  @include ln-respond-to("xs") {
    margin-bottom: 0;
  }

  &:before {
    left: 0;
  }

  svg {
    margin-right: $ln-space-x2;
    float: left;
    font-size: 18px;
  }

  &__text {
    display: table-cell;
  }
}
