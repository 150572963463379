.seasonal-favourites {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  padding-bottom: 0rem;

  &__carousel-header {
    margin-bottom: $ln-space;
  }
}
