$chevron_height: 10px;
$chevron_width: 20px;
$tooltip_color: $ln-color-grey-dark;
$tooltip_max_width: 320px;
$large_tooltip_max_width: 410px;
$mobile_tooltip_width: 360px;

.fav-tooltip {
  &_wrapper {
    display: flex;
    flex-direction: column;
    row-gap: $ln-space-x2;
    background-color: $tooltip_color;
    color: $ln-color-white;
    border-radius: $ln-border-radius;
    text-align: start;
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: $ln-space;

    &-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: $ln-space;
    }
  }

  &_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &_cta {
      padding: 0;
      min-height: unset;
      background-color: unset;
      &:hover {
        background-color: unset !important;
      }
      margin-left: auto;
    }
  }

  &.is-open .ln-c-tooltip__message {
    z-index: 1 !important;
  }

  &_close {
    line-height: 1.25em;
    padding: 0;
    background-color: unset;
    color: $ln-color-white;
  }

  .ln-c-tooltip__message {
    padding: $ln-space-x2;
    width: max-content;
    max-width: $tooltip_max_width;

    @include ln-respond-to("max-sm") {
      position: absolute !important;
      left: unset !important;
      top: unset !important;
      transform: initial;
      overflow: visible;
      margin: 0px;
      max-width: min(calc(100vw - $ln-space-x4), $mobile_tooltip_width) !important;
    }
  }

  &-large .ln-c-tooltip__message {
    max-width: $large_tooltip_max_width;
    @include ln-respond-to("max-sm") {
      max-width: min(calc(100vw - $ln-space-x4), $mobile_tooltip_width) !important;
    }
  }

  @include ln-respond-to("max-sm") {
    &[class*="-left"] .ln-c-tooltip__message,
    &[class*="-right"] .ln-c-tooltip__message {
      max-width: calc(100vw - $ln-space-x4 - 100% - $chevron_height);
    }
  }

  @include ln-respond-to("max-sm") {
    &.is-open {
      & > :first-child::after {
        content: " ";
        border: solid;
        position: absolute;
        z-index: 1;
        border-width: $chevron_height $chevron_height;
      }

      &[class*="-top"],
      &[class*="-bottom"] {
        & > :first-child::after {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &[class*="-left"],
      &[class*="-right"] {
        & > :first-child::after {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &[class*="-left"] > :first-child::after {
        border-color: transparent transparent transparent $tooltip_color;
        left: -$chevron_height;
      }

      &[class*="-bottom"] > :first-child::after {
        border-color: transparent transparent $tooltip_color transparent;
        bottom: -$chevron_height;
      }

      &[class*="-right"] > :first-child::after {
        border-color: transparent $tooltip_color transparent transparent;
        right: -$chevron_height;
      }

      &[class*="-top"] > :first-child::after {
        border-color: $tooltip_color transparent transparent transparent;
        top: -$chevron_height;
      }
    }

    &[class*="-top"] .ln-c-tooltip__message {
      bottom: 100% !important;
      margin-bottom: $chevron_height;
    }

    &[class*="-bottom"] .ln-c-tooltip__message {
      top: 100% !important;
      margin-top: $chevron_height;
    }

    &[class*="-left"] .ln-c-tooltip__message {
      right: 100% !important;
      margin-right: $chevron_height;
    }

    &[class*="-right"] .ln-c-tooltip__message {
      left: 100% !important;
      margin-left: $chevron_height;
    }
  }

  &[class*="-left"],
  &[class*="-right"] {
    &[class*="-start"] {
      .ln-c-tooltip__message {
        margin-top: -$chevron_height;
      }
    }
    &[class*="-end"] {
      .ln-c-tooltip__message {
        margin-bottom: -$chevron_height;
      }
    }

    @include ln-respond-to("max-sm") {
      .ln-c-tooltip__message {
        transform: translateY(-50%) !important;
        top: 50% !important;
      }

      &[class*="-start"] {
        .ln-c-tooltip__message {
          margin-top: -$chevron_width;
          transform: none !important;
          top: 50% !important;
        }
      }

      &[class*="-end"] {
        .ln-c-tooltip__message {
          margin-bottom: -$chevron_width;
          transform: none !important;
          bottom: 50% !important;
          top: unset !important;
        }
      }
    }
  }

  &[class*="-top"],
  &[class*="-bottom"] {
    @include ln-respond-to("min-md") {
      &[class*="start"] {
        .ln-c-tooltip__message {
          margin-left: -$chevron_height;
        }
      }
      &[class*="end"] {
        .ln-c-tooltip__message {
          margin-right: -$chevron_height;
        }
      }
    }

    @include ln-respond-to("max-sm") {
      .ln-c-tooltip__message {
        transform: translateX(-50%) !important;
        left: 50% !important;
      }

      &[class*="start"] {
        .ln-c-tooltip__message {
          margin-left: -$chevron_width;
          transform: none !important;
          left: 50% !important;
        }
      }

      &[class*="end"] {
        .ln-c-tooltip__message {
          margin-right: -$chevron_width;
          transform: none !important;
          right: 50% !important;
          left: unset !important;
        }
      }
    }
  }
  &-showLink {
    &[class*="-top"] {
      @include ln-respond-to("max-sm") {
        .ln-c-tooltip__message {
          left: 12px !important;
        }

        &[class*="start"] {
          .ln-c-tooltip__message {
            left: 12px !important;
          }
        }
        & > :first-child::after {
          left: 12px !important;
        }
      }
    }
  }
}
