@mixin carousel-wrapper-font {
  $carousel-font-size: 20px;
  $carousel-line-height: calc(#{$carousel-font-size} * 1.5);

  font-size: $carousel-font-size;
  line-height: $carousel-line-height;

  @include ln-respond-to("min-sm") {
    $carousel-font-size: 26px;
    $carousel-line-height: calc(#{$carousel-font-size} * 1.5);

    font-size: $carousel-font-size;
    line-height: $carousel-line-height;
  }
}

.carousel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $ln-space-x2;

  &__with-see-all-padding {
    margin-bottom: $ln-space;
  }

  &__title {
    margin: 0px;
    @include carousel-wrapper-font;

    @include ln-respond-to("max-sm") {
      text-align: left;
    }

    &__new_line {
      @include ln-respond-to("min-sm") {
        display: none;
      }
    }
  }
}
