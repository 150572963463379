.cncbg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include ln-respond-to("max-sm") {
    flex-direction: column;
  }
  @include ln-respond-to("sm") {
    flex-direction: row;
  }
  @include ln-respond-to("md") {
    flex-direction: row;
  }
  @include ln-respond-to("xl") {
    flex-direction: row;
  }

  &__modal-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 17%;
  }

  &__mobile-menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }

  &__continue {
    width: 18rem;
  }

  &__continue-btn {
    width: inherit;
  }
}

.faq {
  padding-bottom: 1rem;
  cursor: pointer;

  &__icon {
    width: 1.5rem;
    margin-right: 0.25rem;
  }
}

.faq-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @include ln-respond-to("sm") {
    width: 5rem;
  }
}

.faq-info__content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  svg {
    width: 32px;
    height: 32px;
  }
}

.buyingAlcohol {
  padding-bottom: 1rem;
  cursor: pointer;

  &__icon {
    width: 1.5rem;
    margin-right: 0.25rem;
  }
}

.buyingAlcohol-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @include ln-respond-to("sm") {
    width: 10rem;
  }
}

.buyingAlcohol-info__content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  svg {
    width: 32px;
    height: 32px;
  }
}

.mobile-info-accordion {
  position: relative;
  z-index: 5;
  margin-bottom: $ln-space-x2;
  text-align: left;

  @include ln-respond-to("max-sm") {
    margin-left: -1rem;
    width: calc(100% + 2rem);
  }

  &__link {
    display: flex;
    justify-content: space-between;
    text-decoration: none;

    &--text {
      text-decoration: none;
      margin-left: 0;
      font-size: 16px;
      font-weight: 700;
    }

    &--icon {
      height: 20px;
    }
  }
}
