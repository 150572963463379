.FallbackCard {
  $cardPadding: 71px;

  @include ln-respond-to("sm") {
    &:has(.FallbackCard__img) {
      padding: 32px;
    }
    padding: $cardPadding;
  }

  @include full-width-on-mobile;
  @include ln-respond-to("sm") {
    width: 100%;

    &__img {
      margin-bottom: 16px;
    }
  }

  width: unset;
  margin-bottom: 32px;
  padding: 16px;
  display: flex;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    text-align: center;
  }

  &__heading {
    $headingBottomMargin: 8px;
    margin: 0px 0px $headingBottomMargin 0px;
    line-height: 32px;

    @include ln-respond-to("min-md") {
      margin: 0px 0px calc($headingBottomMargin * 2) 0px;
      line-height: 40px;
    }
  }

  &__img {
    object-fit: contain;
    margin-bottom: 16px;

    @include ln-respond-to("sm") {
      margin-bottom: 32px;
    }
  }

  &__reloadButton {
    width: 100%;
    max-width: 288px;
  }
}
