.product-details_tabs {
  padding: 16px 0;
}

.pd__details {
  @import "../LegacyWarningContainer/LegacyWarningContainer";

  @include ln-respond-to("max-md") {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
  }

  .productText {
    border-bottom: 1px solid #d7d7d7;
    margin: 0 0 15px;
    width: 100%;
  }

  .nutritionalContentSummary {
    display: table;
    margin-bottom: 10px;

    .reference {
      font-family: $ln-font-family-base;
      font-size: 0.875rem;
    }

    .lozengeBlock {
      display: flex;
      flex-direction: row;
      margin-left: 0;

      @include ln-respond-to("max-sm") {
        flex-wrap: wrap;
      }

      .lozenge {
        border: none;
        border-radius: 40px;
        color: black;
        display: flex;
        flex-direction: column;
        height: 122px;
        margin-left: 1px;
        width: 80px;

        @include ln-respond-to("max-sm") {
          margin-bottom: 8px;
        }

        .lozengeHeaderSection {
          font-weight: 700;
          margin-top: 24px;
          text-align: center;

          .lozengeTitle {
            font-size: 12px;
            margin-bottom: 0;
          }
        }

        .percentage {
          background-color: #fff;
          border-radius: 14px;
          font-weight: 700;
          text-align: center;
          margin: auto auto 14px auto;
          width: 53px;
          height: 25px;

          @include ln-respond-to("min-sm") {
            margin-bottom: 24px;
          }
        }

        .access {
          display: none;
        }
      }

      .energy {
        box-sizing: border-box;
        border: 2px solid black;

        .lozengeTitle {
          margin-top: -3px;
        }

        p {
          margin-bottom: 0;
        }

        @include ln-respond-to("max-sm") {
          p {
            margin-bottom: -1px;
          }
        }

        .percentage {
          box-sizing: border-box;
          border: 2px solid black;
        }
      }

      .high {
        background-color: #db3e34;
      }

      .medium {
        background-color: #f39000;
      }

      .low {
        background-color: #76b729;
      }
    }
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .nutritionTable {
    border-collapse: collapse;
    font-size: 0.875rem;
    width: 100%;

    td,
    th {
      border-bottom: 1px solid #ccc;
      border-collapse: collapse;
      padding: 6px 14px;
      color: #000000;
    }

    tr:nth-child(even) th,
    tr:nth-child(even) td {
      background-color: #f6f6f6;
    }

    tr td.nutritionLevel1 {
      background: #76b729;
      color: #000000;
      opacity: 100%;
    }

    tr td.nutritionLevel2 {
      background-color: #f39000;
      color: #000000;
      opacity: 100%;
    }

    tr td.nutritionLevel3 {
      background-color: #db3e34;
      color: #000000;
      opacity: 100%;
    }
  }

  .productIngredients {
    list-style: none;
    margin-left: 0;

    li {
      display: inline-block;
    }
  }

  .mhra-icon {
    width: 200px;
  }
}
