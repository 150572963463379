h1.disclaimer-title {
  font-size: 1.8rem;
}

h2.disclaimer-subtitle {
  font-size: 1.5rem;
}

h3.disclaimer-subheading {
  font-size: 1.3rem;
}

.disclaimer-wrapper {
  padding: 48px 32px;
}
