.my-addresses {
  &__add-address {
    min-width: 300px;

    @include ln-respond-to("xs") {
      width: 385px;
    }
  }

  &__addresses-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(344px, max-content));
    grid-gap: 8px;
    justify-content: center;
    padding: initial;

    @include ln-respond-to("xs") {
      grid-template-columns: repeat(auto-fit, minmax(348px, max-content));
    }

    @include ln-respond-to("md") {
      grid-template-columns: repeat(auto-fit, minmax(385px, max-content));
    }
  }
  &__address-details {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    width: 168px;
  }

  &__nickname {
    min-height: 70px;
    margin-bottom: 8px;
  }

  &__preferred-address {
    color: #246127;
    background: #eaf2eb;
    padding: 10px;
    display: flex;
    min-height: 3rem;
    align-items: center;
    svg {
      margin-right: 0.5rem;
    }
  }
  &__address {
    display: flex;
    flex-direction: column;
    max-width: 385px;
  }
  &__icon {
    height: 24px;
    width: 24px;
  }
  &__link {
    color: $ln-color-monochrome-dark;
    text-align: left;
  }
}
