.LegacyProductDetailsDetails {
  &__tabs {
    & .ds-c-tabs--list-wrapper {
      &::before {
        display: none;
      }
    }

    & [role="tablist"] {
      margin: var(--ds-size-spacing-lg, 16px);
    }
  }
}
