.carouselLink {
  @extend .ln-c-button;
  @extend .ln-c-button--text;

  @include ln-respond-to("max-sm") {
    margin-right: calc($ln-space-x2 * -1);
  }

  &__text {
    @include carousel-wrapper-font;
    margin: 0px;

    @include ln-respond-to("max-sm") {
      white-space: nowrap;
    }
  }
}
