.YnpSlotChangeModalBanner {
  &__alert-banner {
    text-align: center;
    justify-content: center;
    color: $dark-grey-hex;
    background-color: $banner-blue-hex;
    margin-top: $ln-space;

    @include ln-respond-to("min-sm") {
      $banner-margin: 16px;
      width: calc(100% - $banner-margin * 2);
    }
  }
}
