.list-loading-indicator {
  &__wrapper {
    max-width: 767px;
    width: 100%;
    display: block;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(344px, max-content));
    grid-gap: 8px;
    justify-content: center;
    padding: initial;

    @include ln-respond-to("xs") {
      grid-template-columns: repeat(auto-fit, minmax(348px, max-content));
    }

    @include ln-respond-to("md") {
      grid-template-columns: repeat(auto-fit, minmax(385px, max-content));
    }
  }

  &__list {
    flex-flow: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 266px;
    margin-bottom: $ln-space-x2;
    background-color: $ln-color-white;

    @include ln-respond-to("sm") {
      margin-bottom: $ln-space-x3;
    }

    &__content {
      height: 100%;
      padding: $ln-space-x4;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }
  &__card {
    max-width: 385px;
    height: 360px;
  }
}
