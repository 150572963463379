.amend-order-banner {
  padding-left: 1rem;
  padding-right: 1rem;

  @include ln-respond-to("sm") {
    max-width: 93rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__margin-top {
    &--standard {
      margin-top: 16px;

      @include ln-respond-to("sm") {
        margin-top: 24px;
      }

      @include ln-respond-to("md") {
        margin-top: 24px;
      }
    }

    &--favourites {
      margin-top: $favourites-subheader-height + 16px;

      @include ln-respond-to("sm") {
        margin-top: $favourites-subheader-height + 36px;
        margin-bottom: -($favourites-subheader-height);
      }

      @include ln-respond-to("md") {
        margin-top: $favourites-subheader-height + 36px;
        margin-bottom: -($favourites-subheader-height);
      }
    }

    &--recipes {
      margin-top: 25px;

      @include ln-respond-to("sm") {
        margin-top: 85px;
      }

      @include ln-respond-to("md") {
        margin-top: 75px;
      }
    }

    &--BYG-AB-test {
      margin-top: 112px + 16px;
      margin-bottom: -112px;

      @include ln-respond-to("sm") {
        margin-top: 73px + 24px;
        margin-bottom: -73px;
      }
    }
  }

  &__wrapper {
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 14px;
  }

  &__content {
    display: flex;
    align-items: center;
    margin-top: -1rem;
    flex-wrap: wrap;

    @include ln-respond-to("sm") {
      flex-wrap: nowrap;
    }
  }

  &__details {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    flex-shrink: 2;
    text-align: left;
    display: inline;
    margin: 1rem 1rem 0 0;
  }

  &__cta-wrapper {
    display: flex;
    flex-shrink: 0;
    -webkit-flex-shink: 0;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    flex-direction: row;
    -webkit-flex-direction: row;

    @include ln-respond-to("max-xs") {
      flex-grow: 1;
    }

    @include ln-respond-to("max-ss") {
      width: 100%;
    }
  }

  &--button-bg-mod:hover {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }

  &__button {
    margin-top: 1rem;
    width: 178px;

    &--outlined {
      border: 2px solid $ln-color-grey-dark;
      height: 1.5rem;
      padding: 0;
      flex: 2 1 110px;

      @include ln-respond-to("ss") {
        width: 182px;
        margin-right: 1rem;
      }
    }

    &--text {
      font-weight: 400;
      text-decoration: underline;
      width: 100%;

      @include ln-respond-to("ss") {
        width: 178px;
      }
    }
  }
}
