$nectar-colour: #8223fa;

@mixin nectar-card-input {
  .prefix-badge {
    width: 100px;
    left: 2px;
    background-color: $ln-color-grey-v-light;
    height: $ln-size-def - 4;
    line-height: 2.7rem;
    font-size: 1rem;
    border-right: 2px solid $ln-color-grey-light;
    color: $ln-color-grey-v-dark;
  }

  .ln-c-input-group__control {
    padding-left: 110px;
  }

  &.has-error .prefix-badge {
    color: $ln-color-red;
  }
}

.ln-u-color-nectar {
  color: $nectar-colour;
}

.my-nectar-main {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  @include ln-respond-to("sm") {
    width: 75%;
  }

  @include ln-respond-to("lg") {
    width: 90%;
  }
}

.simplified-view .my-nectar-breadcrumbs {
  display: none;
}

.my-nectar-add {
  background-color: $ln-color-white;
  color: $ln-color-grey-dark;
  display: flex;
  height: 100%;

  @include ln-soft;

  @include ln-respond-to("sm") {
    padding-left: $ln-space-x6;
    padding-right: $ln-space-x6;
  }

  @include ln-respond-to("lg") {
    padding-left: $ln-space-x3;
    padding-right: $ln-space-x3;
  }

  &--error {
    border: 2px solid $ln-color-red;

    .inline-error-no-background {
      padding: 0;
    }
  }

  &__logo {
    min-width: 70px;
    margin-bottom: $ln-space-x2;

    @include ln-respond-to("sm") {
      min-width: 81px;

      margin-left: $ln-space-x4;
    }
  }

  &__input {
    margin-bottom: 20px;

    .prefix-badge {
      width: 100px;
      left: 2px;
      background-color: $ln-color-grey-v-light;
      height: $ln-size-def - 4;
      line-height: 2.7rem;
      font-size: 1rem;
      border-right: 2px solid $ln-color-grey-light;
      color: $ln-color-grey-v-dark;
    }

    &.has-error {
      .prefix-badge {
        color: $ln-color-red;
      }
    }

    .ln-c-input-group__control {
      padding-left: 110px;
    }
  }

  &__data {
    @include ln-respond-to("sm") {
      display: flex;
      height: 100%;
    }
  }
}

.my-nectar-link,
.my-nectar-signup {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    width: 100%;
  }

  &__error {
    .inline-error-no-background {
      padding: 0;
    }
  }

  &__component {
    max-width: 448px;
    background-color: $ln-color-white;
    color: $ln-color-grey-dark;

    .link-nectar-form {
      &__button-resend,
      &__button-email,
      &__button-phone {
        font-size: 18px;
        text-decoration: underline;
        min-height: 0;
        padding: 0;
        min-width: 0;
        color: var(--ds-mode-default-base);
        border: none;
        background-color: white;

        &:hover,
        &:hover:not(:disabled, .ds-c-button--disabled),
        &:focus,
        &:focus:not(:disabled, .ds-c-button--disabled),
        &.active,
        &.active:not(:disabled, .ds-c-button--disabled) {
          color: var(--ds-mode-default-base);
          background-color: white;
        }
      }
      .ln-c-label {
        margin-bottom: 0;
      }
      .ln-c-form-group {
        margin-bottom: var(--ds-space-spacing-x1);
      }
    }

    .link-nectar-text {
      font-size: 18px;
    }

    &--error {
      border: 2px solid $ln-color-red;

      .inline-error-no-background {
        padding: 0;
      }
    }

    @include ln-soft;

    @include ln-respond-to("sm") {
      padding-left: $ln-space-x6;
      padding-right: $ln-space-x6;
    }

    @include ln-respond-to("lg") {
      padding-left: $ln-space-x3;
      padding-right: $ln-space-x3;
    }
  }

  &__button {
    width: "100%";
  }

  &__header {
    display: flex;
    height: 100%;
  }

  &__logo {
    min-width: 70px;
    margin-bottom: $ln-space-x2;

    @include ln-respond-to("sm") {
      min-width: 81px;
    }
  }
}
.my-nectar-edit {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    max-width: 595px;

    @include ln-respond-to("lg") {
      max-width: 684px;
    }
  }

  &__component {
    background-color: $ln-color-white;
    color: $ln-color-grey-dark;

    &--error {
      border: 2px solid $ln-color-red;

      .inline-error-no-background {
        padding: 0;
      }
    }

    @include ln-soft;

    @include ln-respond-to("sm") {
      padding-left: $ln-space-x6;
      padding-right: $ln-space-x6;
    }

    @include ln-respond-to("lg") {
      padding-left: $ln-space-x3;
      padding-right: $ln-space-x3;
    }
  }

  &__header {
    display: flex;
    height: 100%;
  }

  &__logo {
    min-width: 70px;
    margin-bottom: $ln-space-x2;

    @include ln-respond-to("sm") {
      min-width: 81px;

      margin-left: $ln-space-x3;
    }
  }

  &__delete-button {
    float: right;

    font-size: 1.125rem;
    font-weight: $ln-font-weight-bold;
    color: $ln-color-orange;
    font-family: $ln-font-family-brand;

    margin-top: $ln-space-x4;
  }
}

.my-nectar-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__name {
    line-height: 0.8;
    margin-bottom: $ln-space-x2;
  }

  &__number {
    line-height: 0.8;
    margin-bottom: $ln-space-x2;
  }

  &__button {
    width: 100%;
    margin-top: $ln-space-x3;

    @include ln-respond-to("lg") {
      margin-top: auto;
    }

    .my-nectar-wrapper-linked & {
      margin-bottom: 12px;
    }
  }
}

.my-nectar-not-signed-up,
.my-nectar-link-card {
  background-color: $ln-color-white;
  color: $ln-color-grey-dark;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include ln-soft;

  @include ln-respond-to("sm") {
    padding-left: $ln-space-x6;
    padding-right: $ln-space-x6;
  }

  @include ln-respond-to("lg") {
    padding-left: $ln-space-x3;
    padding-right: $ln-space-x3;
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include ln-respond-to("sm") {
      flex-direction: row;
    }

    &__column {
      flex-direction: column;
    }
  }
}

.my-nectar-button {
  flex-basis: 100%;

  &--short {
    @include ln-respond-to("sm") {
      width: calc(50% + 93px / 2);
    }
  }

  &--orange {
    color: $ln-color-orange;
  }

  &--cancel {
    border: none;
  }

  &--align-right {
    @include ln-respond-to("sm") {
      align-self: flex-end;
    }
  }

  a {
    text-decoration: none;
  }
}

.my-nectar-png-logo {
  min-width: 81px;
  margin-left: $ln-space-x2;
}

.my-nectar-svg-logo {
  max-width: 100px;

  @include ln-respond-to("sm") {
    max-width: 150px;
  }
}

.my-nectar-loading {
  background-color: $ln-color-white;
}

.my-nectar-details {
  background-color: $ln-color-white;
  color: $ln-color-grey-dark;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  @include ln-soft;

  @include ln-respond-to("sm") {
    padding-left: $ln-space-x6;
    padding-right: $ln-space-x6;
  }

  @include ln-respond-to("lg") {
    padding-left: $ln-space-x3;
    padding-right: $ln-space-x3;
  }

  &__data {
    @include ln-respond-to("sm") {
      display: flex;
      height: 100%;
    }

    .my-nectar-wrapper-not-linked & {
      @include ln-respond-to("lg") {
        margin-top: $ln-space-x3;
      }
    }

    .my-nectar-wrapper-linked & {
      @include ln-respond-to("lg") {
        margin-top: $ln-space;
      }
    }
  }

  &__logo {
    min-width: 70px;
    margin-bottom: $ln-space-x2;

    @include ln-respond-to("sm") {
      min-width: 86px;

      margin-right: $ln-space-x4;
    }

    @include ln-respond-to("lg") {
      margin-left: $ln-space-x4;
    }
  }
}

.add-nectar__input {
  max-width: 348px;
  margin-bottom: 0;

  @include ln-respond-to("lg") {
    max-width: 438px;
  }

  @include nectar-card-input;
}

.add-nectar__error {
  margin-top: 8px;
  margin-bottom: -8px;
}

.modal-nectar {
  .ln-c-modal__heading {
    margin-bottom: $ln-space-x2;
    font-size: 22px;
  }

  .ln-c-button-group {
    margin-bottom: 0;
  }
}

.my-nectar-balance {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: $ln-space-x3;

  @include ln-respond-to("lg") {
    margin-bottom: $ln-space-x4;
  }

  &__column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: $ln-space;

    &:first-child {
      border-right: 1px solid #d8d8d8;
    }
  }

  &__points {
    font-size: 40px;
    font-family: $ln-font-family-brand;
    font-weight: 800;
    line-height: 1.3;

    @include ln-respond-to("lg") {
      font-size: 50px;
    }
  }
}
