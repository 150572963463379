@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "@sainsburys-tech/design-tokens/dist/sainsburys/css/tokens.css";
@import "@sainsburys-tech/design-tokens/dist/nectar/css/local/tokens.css";

@import "./luna";
@import "common/variables";
@import "common/mixins";
@import "App";
@import "components/Logo/Logo.scss";
@import "components/ContentWrap/ContentWrap.scss";
@import "common/zIndex";
@import "common/ProductListingPage";
@import "common/PageLayout";
@import "views/Favourites/components/SubHeader/SubHeader.scss";
@import "views/Favourites/components/FavouritesContainer/Favourites.scss";
@import "views/Favourites/components/FirstFavourites/FirstFavourites.scss";
@import "views/Favourites/components/FavouriteError/FavouriteError.scss";
@import "views/Favourites/components/SeasonalFavourites/SeasonalFavourites.scss";
@import "views/Favourites/components/FeatureTour/FeatureTourTooltip.scss";
@import "views/Favourites/components/FeatureTour/FeatureTourBanner.scss";
@import "views/MyAccount/MyAccount";
@import "views/MyNectarCard/MyNectarCard";
@import "views/Browse/Browse.scss";
@import "components/Error/errorPage.scss";
@import "components/Footer/Footer.scss";
@import "views/ProgressiveRegistration/Success";
@import "views/ProgressiveRegistration/ProgressiveRegistration";
@import "views/ProgressiveRegistrationV2/ProgressiveRegistrationV2";
@import "components/Modal/Modal";
@import "components/Modal/AlternativesModal/alternativesModal.scss";
@import "components/Modal/NewModal/Modal";
@import "components/Header/DesktopMenu/DesktopMenu";
@import "components/Header/Header";
@import "components/Header/LoadingIndicator";
@import "components/BookSlot/SlotGridControls/SlotGridControls.scss";
@import "components/BookSlot/PendingSlotGrid";
@import "components/BookSlot/Slot";
@import "components/BookSlot/DatePicker/DatePicker.scss";
@import "components/BookSlot/GridMobileNavigation/GridMobileNavigation.scss";
@import "components/GreenVanModal/greenVanInfo";
@import "src/views/HomeDelivery/homeDelivery";
@import "src/views/HomeDelivery/nextDeliveryBanner";
@import "src/views/HomeDelivery/deliverySlotsFeedback";
@import "components/BookSlot/Grid";
@import "components/BookSlot/SlotTable/SlotTable.scss";
@import "components/BookSlot/XmasSlotsBanner/XmasSlotsBanner.scss";
@import "components/BookSlot/EasterSlotsBanner";
@import "components/BookSlot/XmasBookingConfirmationSteps";
@import "components/Header/MiniTrolley/MiniTrolley";
@import "components/Header/SearchBar/SearchBar";
@import "components/ProductTile/ProductTile";
@import "components/ProductFilters/ProductFilters";
@import "components/FoodMaestroControls/FoodMaestroControls";
@import "components/ProductControls/ProductControls";
@import "components/SecondaryRating/SecondaryRating";
@import "components/Header/BookDelivery/BookDelivery";
@import "components/ProductPagination/ProductPagination";
@import "components/ProductImageModal/ClickableProductImageModal";
@import "components/ProductImageModal/SlidableProductImageModal";
@import "components/Skeleton/Skeleton";
@import "components/SlotDetails/SlotDetails.scss";
@import "components/DietaryProfileWarningBanner/DietaryProfileWarningBanner";
@import "components/Nectar/Nectar";
@import "components/Espots/espot.scss";
@import "components/Notification/Notification.scss";
@import "views/PreviousOrders/PreviousOrders";
@import "views/SearchResults/SearchResults";
@import "views/BrandPage/BrandPage";
@import "views/SearchResultsFindability/SearchResultsFindability";
@import "views/ProductDetails/ProductDetails";
@import "views/ProductDetails/ProductDetailsDetails/PDPAccordions/PDPAccordions";
@import "views/ProductDetails/Reviews/Review/Review";
@import "views/MyOrders/MyOrders";
@import "views/OAuthLogin/LoginError";
@import "components/Header/accessibleSkipTo";
@import "components/DietaryProfile/DietaryWarning/DietaryWarning";
@import "components/DietaryProfile/dietaryProfile.scss";
@import "components/ProductImageModal/SlickCarousel";
@import "~slick-carousel/slick/slick-theme";
@import "components/ProductLabel/ProductLabel";
@import "components/ProductBadge/ProductBadge";
@import "components/Error/inlineError.scss";
@import "components/SearchFeedback/SearchFeedback";
@import "components/SearchIndicator/SearchIndicator";
@import "views/OrderDetails/OrderDetails";
@import "views/RecurringSlot/RecurringSlot";
@import "components/AmendOrderBanner/AmendOrderBanner";
@import "views/Trolley/Trolley";
@import "components/OrderSummary/OrderSummary";
@import "views/Trolley/TrolleySummary";
@import "views/Trolley/TrolleyItem";
@import "views/Trolley/FreeSlotQualifier";
@import "components/Citrus/index.scss";
@import "views/CheckPostcode/CheckPostcodeForm";
@import "views/OAuthRegistration/OAuthRegistrationConfirmation";
@import "views/ProgressiveRegistrationV2/CPM/CustomerPreferenceManagement";
@import "views/HomeDelivery/slotControls";
@import "common/grid";
@import "views/HomeDelivery/mobileInfoAccordion.scss";
@import "views/HomeDelivery/Modal/modal";
@import "views/BookingConfirmation/bookingConfirmation";
@import "views/ClickAndCollect/BookingConfirmation";
@import "views/MyCoupons/MyCoupons.scss";
@import "views/ClickAndCollect/StoreCard.scss";
@import "views/ClickAndCollect/StoreSearch.scss";
@import "views/ClickAndCollect/ClickAndCollect.scss";
@import "views/ClickAndCollect/ReserveSlotModal.scss";
@import "views/AboutClickAndCollect/AboutClickAndCollect.scss";
@import "views/ClickAndCollect/BelowGridItems.scss";
@import "views/CheckoutInterstitials/CheckoutInterstitialPage";
@import "views/CheckoutInterstitials/ErrorComponent.scss";
@import "views/DeliveryPassRenewalOptions/DeliveryPassRenewalOptions.scss";
@import "views/DeliveryPassRenewalOptions/DeliveryPassRenewalOptionsConfirmation.scss";
@import "views/Recipes/Utils/RecipePill.scss";
@import "views/RecipeHomepage/RecipeHomepage.component.scss";
@import "views/Recipes/Recipes.scss";
@import "views/Checkout/Checkout.scss";
@import "views/Checkout/CheckoutHeader.scss";
@import "components/Modal/DeliveryInstructionsModal/DeliveryInstructionsModal";
@import "views/Articles/Articles.scss";
@import "views/Articles/RecipeTile.scss";
@import "views/Offers/pages/MainOffersPage/Offers.scss";
@import "views/Offers/components/OffersPills/OfferPills.scss";
@import "views/Offers/components/OffersBanners/OffersBanners.scss";
@import "components/RecipesBrowse/RecipesBrowse.scss";
@import "views/RecipesSearchResults/RecipesSearchResults.scss";
@import "views/RecipesBrowsePage/RecipesBrowsePage.scss";
@import "components/RecipesSearch/WithRecipeSearch.scss";
@import "views/MultiSearch/MultiSearch.scss";
@import "views/OrderConfirmation/OrderConfirmation.scss";
@import "views/OAuthLogin/Disclaimer.component.scss";
@import "views/SearchResultsFindability/ArgosSearchResults/ArgosSearchResults.scss";
@import "views/ChangesToTrolley/ChangesToTrolley.scss";
@import "views/ProductDetails/ProductDetailsTile/ProductDetailsTitle/ProductDetailsTitle.component.scss";
@import "views/Recipes/Utils/RecipeTag/RecipeTag.scss";
@import "views/ProductDetails/ProductDetailsTile/ProductDetailsImagesAndVideos/ProductDetailsImagesAndVideos.scss";
@import "views/ProductDetails/Breadcrumbs/Breadcrumbs.scss";
@import "views/ProductDetails/ProductDetailsPromoBanners/ProductDetailsPromoBanners.scss";
@import "views/NectarDestinationPage/GetStartedWithNectarCard/GetStartedWithNectarCard.scss";
@import "views/NectarDestinationPage/NectarDestinationPage.scss";
@import "views/Browse/BrowseHeaderPills/BrowseHeaderPills.scss";
@import "views/EventsFeatures/EventsFeatures.scss";
@import "views/Browse/BrowseMagnoliaContent/BrowseMagnoliaContent.scss";
@import "views/NectarDestinationPage/NectarMagnoliaWrapper/NectarMagnoliaWrapper.scss";
@import "views/NectarDestinationPage/Carousels/BonusPointsCarousel/BonusPointsCarousel.scss";
@import "components/Carousel/CarouselLoadingSkeleton/CarouselLoadingSkeleton.scss";
@import "components/Carousel/CarouselWrapper/CarouselWrapper.scss";
@import "components/Carousel/CarouselLink/CarouselLink.scss";
@import "views/NectarDestinationPage/Carousels/NectarDestinationCarousel/NectarDestinationCarousel.scss";
@import "views/Recipes/Description/RecipesShareModal.scss";
@import "components/BookSlot/GridStickyHeader/GridStickyHeader.scss";
@import "components/Header/HeaderWrapper/HeaderWrapper.scss";
@import "views/NectarDestinationPage/HowToAccessBonusPointsCard/HowToAccessBonusPointsCard.scss";
@import "components/PageHeader/PageHeader.scss";
@import "views/MealDeal/MealDealLoadingSpinner/MealDealLoadingSpinner.scss";
@import "views/MealDealBuilder/MealDealBuilder.scss";
@import "views/MealDealBuilder/MealDealJoinNectarWidget/MealDealJoinNectarWidget.scss";
@import "components/RecipesAddToBasketModal/ATBModal.scss";
@import "components/RecipesIngredientTile/RecipesIngredient.scss";
@import "components/RecipesSwapModal/RecipesSwapModal.scss";
@import "components/RecipesAddToBasketModal/RecipesModal.common.scss";
@import "views/ClickAndCollect/PageTitle/PageTitle.scss";
@import "views/HomeDelivery/PageTitle/PageTitle.scss";
@import "components/BookSlot/PageTitle/PageTitleSkeleton.scss";
@import "components/BookSlot/LoadingBanner.scss";
@import "views/ClickAndCollect/SwitchToDelivery/SwitchToDelivery.scss";
@import "views/RecipeMealPlanner/MealPlanner.scss";
@import "views/RecipeMealPlanner/MealPlannerHeader/MealPlannerHeader.scss";
@import "views/RecipeMealPlanner/RecipeCarousel/RecipeCarousel.scss";
@import "views/BrandedPage/Branded.scss";
@import "components/YnpSlotChangeModalBanner/YnpSlotChangeModalBanner.scss";
@import "components/RecipesAddToBasketModal/ATBModalViewDownloadPrintRecipes.scss";
@import "views/CYSM/CYSMAddressDetails/CYSMAddressDetails.scss";
@import "views/CYSM/CYSMHeading/CYSMHeading.scss";
@import "views/CYSM/CYSMAddressDetailsSkeleton/CYSMAddressDetailsSkeleton.scss";
@import "views/CYSM/CYSM.scss";
@import "views/CYSM/CYSMCard/CYSMCard.scss";
@import "views/CYSM/PostcodeCheck/PostcodeCheck/PostcodeCheck.scss";
@import "views/CYSM/PostcodeCheck/PostcodeCheckForm/PostcodeCheckForm.scss";
@import "views/PaymentCards/PaymentCards.scss";
@import "views/PaymentCards/AddPaymentCard.scss";
@import "views/MealDealBuilder/MealDealBundle/MealDealBundle.scss";
@import "components/FallbackCard/FallbackCard.scss";
@import "views/ProductDetails/ProductDetailsDetails/CustomerRatingAccordionItem/CustomerRatingAccordionItem.scss";
@import "views/Homepage/Homepage.scss";
@import "views/Homepage/HomepageSpeedyBasket/HomepageSpeedyBasket.scss";
@import "views/Homepage/HomepageNectar/HomepageNectar.scss";
@import "components/Magnolia/MagnoliaPageHeader/MagnoliaPageHeader.scss";
@import "views/ProductDetails/ProductDetailsDetails/MhraImage/MhraImage.scss";
@import "views/ProductDetails/Reviews/ReviewsTabError/ReviewsTabError.scss";
@import "views/ProductDetails/LegacyProductDetailsDetails/LegacyProductDetailsDetails.scss";
@import "views/ProductDetails/FrequentlyBoughtTogether/FrequentlyBoughtTogether.scss";
@import "components/Header/MegaNav/MegaNav.scss";
@import "components/BookSlot/DateLabel/DateLabel.scss";
@import "components/AmendOrderModalContainer/AmendOrderModalContainer.scss";
@import "views/Offers/pages/OffersZonalPage/NoZonalProducts/NoZonalProducts.scss";
@import "components/GroupedMealDealPromotion/GroupedMealDealPromotion.scss";
@import "components/Collapsable/collapsable.scss";
@import "components/FilterAndSorting/FiltersAndSortOptions.scss";
@import "components/FilterAndSorting/FilterDrawer.scss";
@import "components/Modal/GroupedMealDealsModal/GroupedMealDealsModal.scss";
@import "views/PromoLister/PromoLister.scss";
@import "views/Checkout/CheckoutPaymentAuthorisation.scss";
@import "components/FullPageLoadingSpinner/FullPageLoadingSpinner.scss";
@import "components/Header/OccasionsMenu/OccasionsMenu.scss";
@import "components/Header/MegaNav/Mobile/MobileMegaNav.scss";
@import "views/XmasSlotGuide/FallbackMessage/FallbackMessage.scss";
@import "components/BookSlot/FetchSlotFallback/FetchSlotFallback.scss";
@import "components/Carousel/CarouselDismissButon/CarouselDismissButon.scss";
@import "components/PageHeaderLeftAligned/PageHeaderLeftAligned.scss";
@import "views/MyAddresses/MyAddresses.scss";
@import "components/Occasions/OccasionsLoadingSkeleton.scss";
@import "views/ProductDetails/FrequentlyBoughtTogetherV2/FrequentlyBoughtTogether.scss";
@import "components/ProductTile/SRFProductTileList.scss";
@import "views/DeliveryPass/DeliveryPassCheckout.scss";
@import "views/NectarDestinationPage/YourNectarPriceSlotPrompt/NoSlotBooked/NoSlotBooked.scss";
@import "views/NectarDestinationPage/YourNectarPriceSlotPrompt/SlotBooked/SlotBooked.scss";
@import "views/NectarDestinationPage/YourNectarPriceSlotPrompt/YourNectarPriceSlotPrompt.scss";
@import "views/ProductDetails/SimilarProducts/SimilarProductsDrawer.styles.scss";
@import "views/NectarDestinationPage/YourNectarPriceSlotPrompt/PromptCard/PromptCard.scss";
@import "views/NectarDestinationPage/YourNectarPriceSlotPrompt/StartShopping/StartShopping.scss";
@import "views/NectarDestinationPage/Carousels/AddAllButton/AddAllButton.scss";

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}
