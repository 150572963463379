.payment-cards {
  &__alert-success {
    color: #246127;
    border: 1px solid #2e7d32;
  }

  &__add-card {
    min-width: 300px;

    @include ln-respond-to("xs") {
      width: 385px;
    }
  }

  &__cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(344px, max-content));
    grid-gap: 8px;
    justify-content: center;
    padding: initial;

    @include ln-respond-to("xs") {
      grid-template-columns: repeat(auto-fit, minmax(348px, max-content));
    }

    @include ln-respond-to("md") {
      grid-template-columns: repeat(auto-fit, minmax(385px, max-content));
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    max-width: 385px;
  }

  &__logo {
    display: flex;
    max-width: 75px;
    height: 55px;
    align-items: center;
  }

  &__card-number {
    min-height: 70px;
    margin-bottom: 8px;
  }

  &__card-details {
    display: flex;
    flex-direction: column;
    font-family: "Mary Ann", sans-serif;
    width: 168px;
  }

  &__preferred-card {
    color: #246127;
    background: #eaf2eb;
    padding: 10px;
    display: flex;
    svg {
      margin-right: 0.5rem;
    }
  }

  &__make-preferred {
    max-width: 270px;
    padding: 0;
  }

  &__expired-delete {
    margin-top: 44px;
  }

  &__loading {
    &-card {
      height: 360px;
      margin-bottom: $ln-space-x2;
      background-color: $ln-color-white;

      @include ln-respond-to("sm") {
        margin-bottom: $ln-space-x3;
      }
    }

    &-content {
      height: 100%;
      padding: $ln-space-x6;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
