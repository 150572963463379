.my-account {
  &__name {
    text-transform: capitalize;
  }

  .panels-hero {
    @include ln-soft-bottom;
  }
}

$arrow-size: map-get($ln-icon-sizes-fixed, "def");

.link-panel {
  background-color: $ln-color-white;
  color: $ln-color-grey-dark;
  cursor: pointer;
  display: block;
  flex: 1;
  text-decoration: none;

  @include ln-soft;

  @include ln-respond-to("md") {
    min-height: 8rem;
  }

  &__arrow {
    display: block;
    height: $arrow-size;
    margin-top: 0.25rem;
    width: $arrow-size;

    svg {
      path {
        fill: #f00;
        stroke: #f00;
      }
    }
  }

  &:hover,
  &:focus {
    color: $ln-color-grey-dark;
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: $ln-space-x2;
  }

  &__description {
    padding-right: 1rem;
  }
}

.link-hero-panel {
  &__wrapper {
    background-color: $ln-color-white;
    //border-top: $ln-status-bar-width solid $ln-color-alpha;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include ln-soft-ends("xl");
    @include ln-soft-sides;
  }

  &__content {
    width: 100%;

    @include ln-respond-to("ss") {
      width: auto;
    }

    @include ln-respond-to("sm") {
      width: 100%;
    }

    @include ln-respond-to("md") {
      width: auto;
    }
  }

  &__title {
    margin-bottom: $ln-space-x2;
  }

  &__description {
    margin-bottom: $ln-space-x3;
  }

  &__cta-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -$ln-space-x2;

    @include ln-respond-to("md") {
      flex-direction: row;
      margin-right: -$ln-space-x2;
    }

    .ln-c-button {
      margin-top: $ln-space-x2;
      width: 100%;

      @include ln-respond-to("ss") {
        width: 16em;
      }

      @include ln-respond-to("sm") {
        width: 100%;
      }

      @include ln-respond-to("md") {
        width: 11em;
        margin-right: $ln-space-x2;
      }

      @include ln-respond-to("lg") {
        width: 14em;
      }
    }
  }

  &__link-wrapper {
    max-width: 472px;
    width: 100%;
  }
}

.my-orders-panel {
  &__next-order {
    display: block;
    justify-content: center;
    margin: 0.5rem 0 1rem 0;
    text-align: left;

    &-type {
      margin-bottom: 0.25rem;
    }
  }
}
