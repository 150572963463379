.xmas-slots {
  @include ln-respond-to("sm") {
    margin-top: 40px;
  }

  @include ln-respond-to("max-sm") {
    margin-top: 15px;
  }

  &__link:hover {
    color: $ln-color-orange;
  }

  &__banner {
    color: $ln-color-white;
    background-color: #005e36;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 9px;
    align-items: center;
    margin: 10px 0px;

    &-icon {
      width: 25px;

      @include ln-respond-to("min-sm") {
        width: 32px;
      }
    }

    &-info {
      text-align: center;
      @include ln-respond-to("min-sm") {
        margin: 0px 20px;
      }

      margin: 0px 5px;
    }

    &-promo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-tag-line {
      font-size: 14px;

      @include ln-respond-to("min-md") {
        font-size: 16px;
      }
    }

    &-text-bold {
      margin-top: 4px;
      font-weight: bold;
    }

    &-terms {
      font-size: 12px;
      margin-top: 4px;
      font-weight: 400;
    }

    &-main-link {
      color: $ln-color-white;
      cursor: pointer;
      font-weight: 700;
      text-decoration: underline;
      background-color: transparent;
      padding: 0px 5px;

      &:hover {
        background-color: $ln-color-orange;
        transition: background-color 0.5s ease-out;
      }
    }

    &-cnc-promo-link {
      font-size: 16px;
      cursor: pointer;
      color: $ln-color-links;
      text-decoration: underline;
      background-color: transparent;

      &:hover {
        color: $ln-color-text-alpha-active;
        transition: color 0.5s ease-out;
      }
    }

    &-link {
      font-size: 16px;
      cursor: pointer;
      color: $ln-color-links;
      text-decoration: underline;
      background-color: transparent;

      &:hover {
        color: $ln-color-text-alpha-active;
        transition: color 0.5s ease-out;
      }
    }
  }
}
