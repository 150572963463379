.no-scheduled-orders {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include ln-respond-to("xs") {
    flex-direction: row;
    justify-content: space-between;
  }

  &__text {
    margin-bottom: calc(#{$ln-space} * 2);
    text-align: center;
    font-size: 18px;

    @include ln-respond-to("xs") {
      margin-bottom: 0;
      text-align: left;
    }
  }

  &__button {
    @include ln-respond-to("xs") {
      max-width: 180px;
    }
  }
}
