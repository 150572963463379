.slot-controls {
  display: flex;
  margin: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;

  @include ln-respond-to("md") {
    justify-content: space-between;
    min-height: 48px;
  }

  .ln-c-tabs--pill {
    display: flex;
    justify-content: center;
    margin-bottom: $ln-space-x2;
    width: 100%;

    @include ln-respond-to("sm") {
      margin-bottom: 0;
    }

    @include ln-respond-to("md") {
      margin-left: $ln-space;
      width: auto;
    }

    .ln-c-tabs__link {
      min-height: $ln-space-x6 !important;
    }
  }

  &__banner {
    margin-bottom: var(--ds-size-space-6);

    @include ln-respond-to("max-sm") {
      margin: var(--ds-size-space-6) 0 var(--ds-size-space-6);
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: $ln-space-x2;

    .ln-c-tabs__link {
      margin: 0 4px;
      background-color: $ln-color-white;
      z-index: 0;
      min-height: $ln-space-x6 !important;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      @include ln-respond-to("sm") {
        margin: 0 8px;
      }
    }

    &--button {
      max-width: 180px;

      @include ln-respond-to("md") {
        min-width: 180px;
      }
    }
  }

  &__loading-button {
    background-color: #dadada;
    border-radius: 24px;
    height: 48px;
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;

    @include ln-respond-to("sm") {
      margin: 0 8px;
      min-width: 180px;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__loading-tabs {
    display: flex;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.25%;

    @include ln-respond-to("sm") {
      justify-content: space-between;
    }
  }

  &__links-section {
    display: flex;
    justify-content: center;

    @include ln-respond-to("sm") {
      margin-bottom: $ln-space-x4;
      justify-content: space-between;
    }
  }

  &__delivery-links {
    width: 100%;
  }

  @mixin delivery-details {
    margin: 0;
    display: flex;
  }

  &__delivery-details-slot-locked {
    @include delivery-details;
  }

  &__delivery-details {
    @include delivery-details;

    @include ln-respond-to("max-sm") {
      align-items: center;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  &__subheading-label {
    margin-bottom: $ln-space;
    margin-left: 4px;
    font-weight: 700;
    font-size: 1rem;
  }

  &__item {
    display: flex;
    transition: color 0.2s;

    &--address {
      @include ln-respond-to("sm") {
        min-width: 2rem;
      }
    }

    &--collection {
      display: flex;
      justify-content: flex-end;
    }

    &--faq-button {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $ln-color-dark;
      background: transparent;
      text-decoration: underline;

      @include ln-respond-to("sm") {
        margin-left: $ln-space-x2;
        padding-left: 12px;
        border-left: 1px solid #d7d7d7;
      }

      &:hover,
      &:focus {
        color: $ln-color-orange-dark;
      }
    }

    &--calendar {
      cursor: pointer;
      padding-left: 0.8rem;
      margin-right: $ln-space-x4;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $ln-color-dark;
      background: transparent;

      @include ln-respond-to("sm") {
        padding-left: 0;

        &:hover {
          color: $ln-color-orange-dark;
        }
      }
    }
  }

  &__text {
    font-size: 1rem;
    font-weight: 800;
    line-height: 25px;
    margin-bottom: 0;
    margin-left: 0.25rem;

    @include ln-respond-to("sm") {
      font-size: 1.125rem;
    }

    &--address {
      margin-left: 0.2rem;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--calendar {
      font-size: 1.125rem;
      font-weight: 800;
      line-height: 24px;
      margin-bottom: 0;
      margin-left: 0.25rem;
      border-bottom: none;
    }
  }

  &__icon {
    font-size: 20px;
  }
}

.scotland-alcohol-banner {
  margin: 24px 0;
  padding: 16px;
  border: 1px solid black;
  display: flex;

  &--header {
    font-family: $ln-font-family-brand;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: $ln-space;
  }

  &--text {
    margin-bottom: 0;
  }

  .ln-c-icon {
    position: relative;
    top: 2px;
    min-height: 22px;
    min-width: 22px;
    margin-right: $ln-space;
  }
}
