$modal-bg: #f2f2f2;
$mobile-break-at: "xs";
$mobile-break-at-max: "max-xs";

@mixin flex-responsive-col {
  display: flex;
  flex-direction: row;

  @include ln-respond-to($mobile-break-at) {
    flex-direction: column;
  }
}

@mixin flex-responsive-row {
  display: flex;
  flex-direction: column;

  @include ln-respond-to($mobile-break-at) {
    flex-direction: row;
  }
}

// Styles which should correspond to all recipes modals
.recipes-modal.ln-c-modal__body {
  background-color: $modal-bg;
  padding: 0px;
  overflow-y: hidden;
  max-width: 920px;

  @include ln-respond-to($mobile-break-at) {
    border-radius: 8px;

    .mobile-only {
      display: none;
    }
  }

  @include ln-respond-to($mobile-break-at-max) {
    .desktop-only {
      display: none;
    }
  }

  // Wrapper for all elements we put in the modal
  .recipes-modal-inner-wrap {
    // Mobile should be always full screen
    // Desktop breakpoints should be set using the id of the inner wrap
    // #recipes-swap-inner-wrap { min-height; max-height; height; max-width; }
    height: 100vh;
    max-height: 100dvh;
    width: 100vw;
    @include ln-respond-to($mobile-break-at) {
      height: auto;
      max-width: min(920px, calc(100vw - 2 * $ln-space-x2));
    }

    // Maintains some spacing at the xs->sm size range
    // the modal is 100% width when < sm breakpoint
    // recipes modal should not go to mobile view until width < xs
    @include ln-respond-to($mobile-break-at) {
      width: calc(100vw - 16px);
    }

    // Reset margins for all child text elements by default
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    b,
    span {
      margin: 0px;
      padding: 0px;
    }

    // Apply padding to the header, contents and footer child divs
    & > * {
      padding: $ln-space-x2;
      width: 100%;
    }

    // Modal contents is a columnar flex, with the middle
    // content element that grows and shrinks
    display: flex;
    flex-direction: column;

    // Utility class for a horizontal line with the modal background
    .line-break {
      position: relative;
      left: calc(-1 * $ln-space-x2);

      width: calc(100% + 2 * $ln-space-x2);
      height: $ln-space;
      background-color: $modal-bg;
    }

    // Modal title
    .recipes-modal-header {
      background-color: #ffffff;

      h6 {
        // Leave space for dismiss button
        max-width: calc(100% - 50px);
      }
    }

    .recipes-modal-tagline {
      @include flex-responsive-row;
      padding-top: $ln-space;
      align-items: baseline;
      justify-content: space-between;
    }

    .recipes-modal-content {
      flex-grow: 1;
      flex-shrink: 1;
      height: auto;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px;

      .recipes-modal-content-divider {
        position: sticky;
        top: 0px;
        left: 0px;
        z-index: 1;
        background-color: white;
        padding: $ln-space-x3 $ln-space-x3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 90px;
        text-align: left;
        h5,
        b {
          height: 60px;
          display: flex;
          align-items: left;
          justify-content: center;
        }
      }
    }

    .recipes-modal-footer {
      background-color: #ffffff;
      z-index: 1;
      filter: drop-shadow(0px 10px 22px rgba(0, 0, 0, 0.25));
    }
  }

  .recipes-modal-error {
    text-align: center;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
