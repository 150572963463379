.FBT-title {
  font-size: 20px;
  margin-bottom: 16px;

  @include ln-respond-to("min-sm") {
    font-size: 26px;
  }
}

.FBT {
  display: flex;
  column-gap: 20px;
  min-width: max-content;

  @media only screen and (max-width: 839px) {
    row-gap: 8px;
    flex-direction: column;
  }

  &__icon {
    align-self: center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    @media only screen and (max-width: 839px) {
      display: none;
    }
  }

  &__skeleton {
    &__heading {
      margin-bottom: 24px;
    }

    &__productCard {
      height: 510px;
      width: 210px;
      @media only screen and (max-width: 839px) {
        width: 100%;
        height: 250px;
      }
    }

    &__controls {
      align-self: center;
      margin-top: 20px;
      @media only screen and (min-width: 840px) {
        margin-left: 56px;
      }
    }
  }

  &__productCard {
    @media only screen and (min-width: 840px) {
      max-width: 200px;
      display: flex;
    }
    @media only screen and (max-width: 839px) {
      padding-top: 13px;
      display: flex;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 840px) {
      align-self: center;
      max-width: 200px;
    }

    &__text {
      margin-bottom: 4px;
      font-size: 14px;
      font-family: Arial;
      color: #0c0c0c;

      &--black {
        color: #0c0c0c;
        font-size: 14px;
        font-weight: 400;
        font-family: Arial;
      }

      &__price {
        font-family: var(--ds-typography-brand-font-family);
        font-size: 22px;
        font-weight: 800;

        &__crossedOut {
          text-decoration: line-through;
        }
      }
    }

    &__price {
      font-family: var(--ds-typography-brand-font-family);
      font-size: 32px;
      font-weight: 800;
      margin-bottom: 16px;
      line-height: 32px;

      &__promo {
        color: #d70000;
      }

      &__nectar {
        color: #8223fa;
      }
    }

    &__addAllBtn {
      @media only screen and (min-width: 840px) {
        width: 200px;
      }
    }
  }
}

.FBT-carousel {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.7s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
