.review {
  &__title {
    @include ln-font("display-2");
    padding-bottom: $ln-space;
  }

  &__secondaryRatings {
    display: flex;
    flex-wrap: wrap;
    margin: $ln-space 0px;

    &__rating {
      max-width: 150px;
    }
  }

  &__caption_container {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  &__caption {
    text-align: center;
    margin-top: $ln-space;
    max-width: 500px;
  }

  &__photo-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__img {
      max-height: 300px;
      margin: auto;
    }
  }

  &__photo_additional_container {
    display: flex;
    flex-wrap: wrap;

    @include ln-respond-to("max-sm") {
      margin: 0 auto;
    }

    &__mobile {
      padding-top: $ln-space;
    }
  }

  &__additional__image {
    width: 105px;
    height: 105px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    object-fit: cover;
    border: 1px #8e8e8e solid;

    &__active {
      border: 1px solid $ln-color-orange;
    }
  }

  &__additional__imageMobile {
    width: 50px;
    height: 50px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    object-fit: cover;
    border: 1px #8e8e8e solid;

    &__active {
      border: 1px solid $ln-color-orange;
    }
  }

  .star-rating {
    margin-right: 5px;
  }

  &__star-rating {
    display: flex;
    padding-bottom: $ln-space;
    font-size: 14px;
  }

  &__content {
    padding-bottom: $ln-space;
    word-wrap: break-word;
  }

  &__merit {
    text-transform: uppercase;
    font-weight: bold;
  }

  &__date {
    padding-bottom: $ln-space;
  }

  &__recommended {
    font-weight: bold;
  }

  &__report {
    background: transparent;

    &-link {
      color: $dark-grey-hex;
      text-decoration: underline;
      font-size: 14px;

      &:hover {
        color: $ln-color-text-alpha-active;
        transition: color 0.5s ease-out;
      }
    }
  }
  &__info-box {
    display: flex;
    flex-direction: column;

    padding: $ln-space;
    margin-top: $ln-space;
    margin-bottom: $ln-space;

    background-color: $ln-color-light;
    border-radius: 2px;

    @include ln-respond-to("sm") {
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &__info-text {
    margin: auto 0;
  }
}

.pd-reviews {
  background: #ffffff;

  &__report-review-textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 60vh;
    min-height: 100%;
    min-width: 100%;
  }

  &__summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $ln-space-x3;

    @include ln-respond-to("max-sm") {
      &__writeareview {
        width: 100%;
        margin-top: $ln-space-x2;
      }
    }

    &__heading {
      flex: 0 0 100%;
      @include ln-font("display-3");
    }

    &__rating-container {
      align-items: flex-start;

      @include ln-respond-to("max-sm") {
        width: 100%;
      }

      .ds-c-rating {
        padding-left: 0;
      }

      &__text {
        font-weight: bold;
      }
    }

    &__title {
      align-self: center;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #d7d7d7;
    }
  }

  &__review-container {
    border-bottom: 1px solid #d7d7d7;
    padding: $ln-space-x3;
  }

  &__pagination-container {
    display: flex;
    justify-content: center;
    padding: $ln-space-x3;
  }

  .error-page {
    margin-top: 0;
    padding: 30px;
  }
}
