.trolley {
  &-item__catchweight-dropdown {
    .ln-o-inline-list {
      text-wrap: nowrap;
    }

    .ln-c-form-option__label {
      padding-left: calc(24px + 1rem);
      margin-right: calc($ln-space * 2);

      &::before {
        left: 0;
      }

      &::after {
        left: 5px;
      }
    }
  }

  &__cta-button-container {
    display: flex;
    justify-content: center;
  }

  &__cta-button {
    max-width: 472px;
    width: 100%;
  }

  &__subs-promise-text {
    margin-bottom: 0;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-image {
      width: 180px;
      height: 210px;
      margin: 30px 0 40px 0;
    }

    &-text {
      margin-bottom: 50px;
    }

    &-button {
      width: 100%;

      @include ln-respond-to("ss") {
        width: 270px;
      }

      &--primary {
        margin-bottom: 16px;

        @include ln-respond-to("ss") {
          margin-bottom: 0;
          margin-right: 24px;
        }
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include ln-respond-to("ss") {
          flex-direction: row;
          width: auto;
        }
      }
    }
  }

  &__undo-remove-item {
    width: 100%;
    min-height: 72px;
    border-bottom: solid 1px #f2f2f2;
    position: relative;
    padding: 24px 0;

    &-details {
      width: 90%;
      display: revert;
      span {
        margin-right: 5px;
      }
    }

    &-button {
      text-decoration: underline;
      padding: 0px;
      min-height: 15px;
    }

    &-close {
      position: absolute;
      padding: 0px;
      right: 0px;
      top: 23px;
      min-width: 0px;
      min-height: 0px;
      &:not(:disabled):not(.is-disabled):hover {
        background-color: transparent;
      }
      &:not(:disabled):not(.is-disabled):focus {
        background-color: transparent;
      }
    }
  }

  &__add-nectar {
    .ln-c-accordion {
      &.is-open .ln-c-accordion__body {
        margin-top: -8px;
        padding-top: 0;
      }
    }
  }

  &__add-nectar-form-wrapper {
    @include ln-respond-to("sm") {
      max-width: 324px;
    }
    @include ln-respond-to("lg") {
      max-width: 100%;
    }
  }

  &__add-nectar-logo {
    max-width: 48px;
  }
}

.ln-c-button__icon,
.ln-c-icon.ln-c-button__icon {
  height: 24px;
  padding: 0px;
  width: 24px;
}

.empty-trolley-button-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  max-width: 720px;
}

.empty-trolley-button {
  color: $ln-color-black;
  margin-bottom: calc(#{$ln-space} * 2);

  @include ln-respond-to("sm") {
    margin-bottom: calc(#{$ln-space} * 3);
  }
}

.list-loading-indicator {
  &__wrapper {
    @include ln-respond-to("sm") {
      max-width: 100%;
    }

    @include ln-respond-to("lg") {
      width: 720px;
    }
  }
}
.alert-promo-applied {
  background-color: #eaf2eb;
  color: #246127;
  height: 30px;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .ln-c-alert__wrapper {
    width: 100%;
  }

  .ln-c-alert__content {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
