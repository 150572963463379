.easter-slots {
  @include ln-respond-to("sm") {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @include ln-respond-to("max-sm") {
    margin-top: 15px;
  }

  &__banner {
    color: #404040;
    background-color: #fcc98a;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    align-items: center;
    margin: 40px 0px;
    font-size: 14px;
    column-gap: 10px;

    @include ln-respond-to("xs") {
      column-gap: 20px;
      font-size: 16px;
    }

    @include ln-respond-to("sm") {
      font-size: 20px;
      margin: 40px 0px 60px 0px;
    }

    &-text-bold {
      font-weight: bold;
    }

    &-main-link {
      cursor: pointer;
      text-decoration: underline;
      width: fit-content;
    }

    &-egg-icon {
      width: 20px;
    }

    &-text-gap {
      display: flex;
      column-gap: 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include ln-respond-to("sm") {
        flex-direction: row;
      }
    }
  }
}
