.CYSMAddressDetailsSkeleton {
  width: 250px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__text {
    margin: 5px;
    bottom: 5px;

    &:last-child {
      margin-top: 0px;
    }
  }

  @include ln-respond-to("min-sm") {
    width: 400px;

    &__text {
      margin: 10px;
    }
  }
}
